import React from "react"
import moment from "moment"
import ServiceCardBannerOverlay from "../services/ServiceCardBannerOverlay"
import useLoading from "../../hooks/useLoading"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { getOrdinal } from "../../utils/get-ordinal"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { getPrice } from "../../utils/services/get-price"
import { getTextColor } from "../../utils/getTextColor"
import { getUnits } from "../../utils/services/get-units/get-units"
import { pluralize } from "../../utils/pluralize"
import { SvgCheckCircle, SvgEdit, SvgLearn } from "../icons/"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface Props {
  service?: any
  getServices?: any
}

export default function PrivateProfileServiceCard({ service }: Props) {
  const serviceDetails = service?.service_details
  const navigate = useNavigate()
  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )
  const coachColor = coachPublicProfileData?.profile_information?.profileColor
  const textColor = getLightTextColor(coachColor)
  const { startLoading } = useLoading()
  const { user } = React.useContext(UserCtx)
  const { service_details, is_group_service } = service

  const getProfileColorClass = () => {
    return `text-[#${coachPublicProfileData?.profile_information?.profileColor}]`
  }

  const getSessionCount = () => {
    if (["consultation", "individual"].includes(serviceDetails?.type)) {
      return `${serviceDetails?.session.count} session`
    } else if (["bundle"].includes(serviceDetails?.type)) {
      return pluralize(serviceDetails?.session.count, "session")
    } else if (["recurring"].includes(serviceDetails?.type)) {
      return (
        <div>
          {pluralize(serviceDetails?.session?.count, "session")}
          <span> / {`${serviceDetails?.billing?.unit}`}</span>
        </div>
      )
    } else {
      return "Not Found"
    }
  }

  const goToEditService = () => {
    startLoading()
    navigate(`/coach/services`, {
      state: { service: service },
    })
  }

  const groupView = () => {
    startLoading()
    navigate(
      `/find-a-coach/${user.coachProfile.endpoint_slug}/services/${service.service_id}`
    )
  }

  const getStartDate = (): {
    startMessage: string
  } => {
    const meetingDates = serviceDetails?.group?.meetingDates || []
    const upcomingDates = meetingDates
      .map((date: string, index: number) => {
        const startDate = new Date(date)
        const sessionIndex = index + 1
        return {
          startDate,
          sessionIndex,
          formattedDate: moment(startDate).format("MMM DD [at] h:mm A"),
        }
      })
      .filter((session: any) => session.startDate >= new Date())
      .sort((a: any, b: any) => a.startDate - b.startDate)

    if (upcomingDates.length === 0) {
      return { startMessage: "No upcoming sessions" }
    } else {
      const nextSession = upcomingDates[0]

      const secondSession = upcomingDates
        .slice(1)
        .find((session: any) => session.startDate >= new Date())

      let startMessage = `${getOrdinal(
        nextSession.sessionIndex
      )} Session Scheduled For ${nextSession.formattedDate}`

      if (secondSession) {
        startMessage = `${getOrdinal(
          secondSession.sessionIndex
        )} Session Scheduled For ${secondSession.formattedDate}`
      }

      return { startMessage }
    }
  }

  return (
    <div className="w-full md:w-[385px] bg-white rounded-[16px] main-shadow min-h-[410px] relative shadow-custom last:mb-[80px] md:last:mb-0 flex flex-col">
      <div
        className={`w-full h-[110px] border border-white border-[12px] rounded-[20px] relative ${serviceDetails?.image?.color}`}
      >
        <ServiceCardBannerOverlay service={service} />
        {serviceDetails.image.exists && (
          <img
            src={`https://clientservice-images-${
              process.env.REACT_APP_USER_ENVIRONMENT
            }.s3.amazonaws.com/${
              service.stripe_product_id
            }?t=${new Date().getTime()}`}
            alt="service"
            className="w-full h-full object-cover rounded-[8px]"
          />
        )}
      </div>
      <div className="w-full flex flex-col px-[16px] py-[12px] flex-1">
        <div className="w-full flex flex-col items-left justify-between">
          <h4 className="font-bold text-[18px] max-w-[260px]">
            {serviceDetails?.title}
          </h4>
          <h3 className={`font-bold text-[16px] ${getProfileColorClass()}`}>
            {getPrice(
              parseInt(serviceDetails?.billing?.amount),
              serviceDetails?.symbol
            )}
            <span className="text-graySlate text-[12px] text-base font-normal">
              {getUnits(
                serviceDetails.billing.unit,
                parseInt(serviceDetails?.billing?.amount),
                serviceDetails?.type
              )}
            </span>
            {is_group_service && getStartDate().startMessage && (
              <>
                &nbsp; &nbsp;
                <Badge variant="success">{getStartDate().startMessage}</Badge>
              </>
            )}
          </h3>
        </div>
        <div className="w-full flex items-center justify-start">
          <div className="flex gap-[16px] items-center mt-2">
            <div
              className="py-[4px] px-[8px] rounded-[8px] flex items-center justify-center font-bold text-base text-black"
              style={{ background: textColor }}
            >
              {getSessionCount()}
            </div>
            <div
              className="py-[4px] px-[8px] rounded-[8px] flex items-center justify-center font-bold text-base text-black"
              style={{ background: textColor }}
            >
              <div>
                {parseInt(serviceDetails?.session?.duration) / 60} minutes
              </div>
            </div>
          </div>
        </div>
        <div className="text-sm mt-4 flex-1">
          <div className="flex items-center">
            {serviceDetails?.details?.features?.feature1 ? (
              <>
                <span>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                </span>
                <p className="ml-3 line-clamp-1 mt-1">
                  {serviceDetails.details.features.feature1}
                </p>
              </>
            ) : null}
          </div>
          <div className="flex items-center">
            {serviceDetails?.details?.features?.feature2 ? (
              <>
                <span>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                </span>
                <p className="ml-3 line-clamp-1 mt-1">
                  {serviceDetails.details.features.feature2}
                </p>
              </>
            ) : null}
          </div>
          <div className="flex items-center">
            {serviceDetails?.details?.features?.feature3 ? (
              <>
                <span>
                  <SvgCheckCircle
                    fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                  />
                </span>
                <p className="ml-3 line-clamp-1 mt-1">
                  {serviceDetails.details.features.feature3}
                </p>
              </>
            ) : null}
          </div>
        </div>
        <Button
          className={`bg-${coachColor} text-${getTextColor(coachColor)} w-full`}
          style={{
            backgroundColor: coachColor,
            color: getTextColor(coachColor),
          }}
          onClick={goToEditService}
        >
          <SvgEdit className="mr-2" />
          Edit
        </Button>
      </div>
    </div>
  )
}
