import * as React from "react"
import { cva, type VariantProps } from "class-variance-authority"

import { cn } from "../../utils/cn"

const badgeVariants = cva(
  "inline-flex items-center rounded-full border px-2.5 py-0.5 text-xs font-semibold transition-colors focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2 whitespace-nowrap",
  {
    variants: {
      variant: {
        default: "border-transparent bg-blurple text-primary-foreground",
        alert: "border-transparent bg-orange text-foreground",
        secondary: "border-transparent bg-secondary text-secondary-foreground",
        success: "border-transparent bg-green text-foreground",
        destructive:
          "border-transparent bg-destructive text-destructive-foreground",
        outline: "text-foreground",
        notDefault:
          "border-transparent bg-paleCyanBlue bg-opacity-[.24] text-blurple rounded-[8px]",
        neutral: "border-transparent bg-transparent text-foreground",
        blurpleGradient:
          "bg-[linear-gradient(to_bottom,_#3886F7,_#892FF4)] text-white",
      },
      dot: { true: "h-2 w-2 p-0" },
    },
    defaultVariants: {
      variant: "default",
    },
  }
)

export interface BadgeProps
  extends React.HTMLAttributes<HTMLDivElement>,
    VariantProps<typeof badgeVariants> {}

function Badge({ className, variant, dot, ...props }: BadgeProps) {
  return (
    <div
      className={cn(badgeVariants({ variant, dot }), className)}
      {...props}
    />
  )
}

export { Badge, badgeVariants }
