import React from "react"

interface SessionInterface {
  viewType: string
  setViewType: any
  mobileView: boolean
  setMobileView: any
  currentSpeaker: any
  setCurrentSpeaker: any
  fullScreen: boolean
  setFullScreen: any
  inWaitingRoom: boolean
  setInWaitingRoom: any
  roomId: string
  setRoomId: any
  msUserToken: string
  setMsUserToken: any
  sessionListLoading: boolean
  setSessionListLoading: any
  hostRoomCode: string
  guestRoomCode: string
  setHostRoomCode: any
  setGuestRoomCode: any
  sendReview: boolean
  setSendReview: any
}

export const SessionContext = React.createContext<SessionInterface>({
  viewType: "speaker",
  setViewType: {},
  mobileView: false,
  setMobileView: {},
  currentSpeaker: {},
  setCurrentSpeaker: {},
  fullScreen: false,
  setFullScreen: {},
  inWaitingRoom: false,
  setInWaitingRoom: {},
  roomId: "",
  setRoomId: {},
  msUserToken: "",
  setMsUserToken: {},
  sessionListLoading: false,
  setSessionListLoading: {},
  hostRoomCode: "",
  guestRoomCode: "",
  setHostRoomCode: {},
  setGuestRoomCode: {},
  sendReview: false,
  setSendReview: {},
})
