import React, { type FC, type ReactNode } from "react"

export interface NotificationContext {
  setNotificationAlert: React.Dispatch<
    React.SetStateAction<{
      message: string
      show: boolean
      link: string
      profile_type: "member" | "coach" | null
    }>
  >
  notificationAlert: {
    message: string
    show: boolean
    link: string
    profile_type: "member" | "coach" | null
  }
  hideNotificationAlert: () => void
  lastWebsocketMessage: any
  setLastWebsocketMessage: any
  contactsNotifications: any
  setContactsNotifications: any
  inboxNotifications: any
  setInboxNotifications: any
  notifications: any[]
  setNotifications: React.Dispatch<React.SetStateAction<any[]>>
}

export const NotificationCtx = React.createContext<NotificationContext>({
  setNotificationAlert: () => {},
  notificationAlert: { message: "", show: false, link: "", profile_type: null },
  hideNotificationAlert: () => {},
  lastWebsocketMessage: {},
  setLastWebsocketMessage: {},
  contactsNotifications: {},
  setContactsNotifications: {},
  inboxNotifications: {},
  setInboxNotifications: {},
  notifications: [],
  setNotifications: () => {},
})

interface Props {
  children: ReactNode
}

export const NotificationCtxProvider: FC<Props> = ({ children }) => {
  const [notificationAlert, setNotificationAlert] = React.useState<{
    message: string
    show: boolean
    link: string
    profile_type: "coach" | "member" | null
  }>({
    message: "",
    show: false,
    link: "",
    profile_type: null,
  })

  const hideNotificationAlert = () => {
    setNotificationAlert({
      message: "",
      show: false,
      link: "",
      profile_type: null,
    })
  }

  const [lastWebsocketMessage, setLastWebsocketMessage] =
    React.useState<any>(null)

  const [contactsNotifications, setContactsNotifications] = React.useState([])

  const [inboxNotifications, setInboxNotifications] = React.useState([])

  const [notifications, setNotifications] = React.useState<any[]>([])

  React.useEffect(() => {
    setNotifications((prev) => {
      const newNotifications = [...inboxNotifications, ...prev]
      return newNotifications.filter(
        (notif, index, self) =>
          index === self.findIndex((n) => n.id === notif.id)
      )
    })
  }, [inboxNotifications])

  return (
    <NotificationCtx.Provider
      value={{
        setNotificationAlert,
        hideNotificationAlert,
        notificationAlert,
        lastWebsocketMessage,
        setLastWebsocketMessage,
        contactsNotifications,
        setContactsNotifications,
        inboxNotifications,
        setInboxNotifications,
        notifications,
        setNotifications,
      }}
    >
      {children}
    </NotificationCtx.Provider>
  )
}
