import React, { useEffect } from "react"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import {
  selectIsConnectedToRoom,
  selectIsLocalScreenShared,
  selectIsSomeoneScreenSharing,
  selectPeerScreenSharing,
  selectPermissions,
  useAVToggle,
  useHMSActions,
  useHMSStore,
  selectRecordingState,
} from "@100mslive/react-sdk"
import { SessionContext } from "../../pages/sessions/context/sessionContext"
import { SessionsRequests } from "../../api/app.service"
import { Button } from "../ui/button"
import { UserCtx } from "../../context/userContext"
import { NotificationCtx } from "../../context/notificationContext"
import {
  SvgCheckbox,
  SvgMaximize,
  SvgMetrics,
  SvgMic,
  SvgMicOff,
  SvgMinimize,
  SvgRecord,
  SvgShareScreen,
  SvgUsersSolid,
  SvgVideoOffSolid,
  SvgVideoSolid,
} from "../icons/"

interface Props {
  sendReview: boolean
  setSendReview: any
}

const VideoControlsBottom: React.FC<Props> = ({
  sendReview,
  setSendReview,
}) => {
  const { mobileView, setFullScreen, fullScreen, roomId } =
    React.useContext(SessionContext)
  const { user } = React.useContext(UserCtx)
  const { lastWebsocketMessage } = React.useContext(NotificationCtx)
  const { renderError, setPopupNotification, setShowMobileNavBottom } =
    React.useContext(CommonFunctionCtx)
  const { isLocalAudioEnabled, isLocalVideoEnabled, toggleAudio, toggleVideo } =
    useAVToggle()
  const isConnected = useHMSStore(selectIsConnectedToRoom)
  const amIScreenSharing = useHMSStore(selectIsLocalScreenShared)
  const screenshareOn = useHMSStore(selectIsSomeoneScreenSharing)
  const presenter = useHMSStore(selectPeerScreenSharing)
  const hmsActions = useHMSActions()
  const permissions = useHMSStore(selectPermissions)
  const [showReviewButton, setShowReviewButton] = React.useState<boolean>(false)
  const recordingState = useHMSStore(selectRecordingState)

  const [loadingRecording, setLoadingRecording] = React.useState<boolean>(false)

  const closePopup = () => {
    setPopupNotification({
      show: false,
      callback: null,
      title: "",
      message: "",
    })
  }

  const updateSendReview = () => {
    SessionsRequests.updateSendReview({
      send_review: sendReview,
      room_id: roomId,
    })
      .then(() => {
        // renderSuccess("");
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  const leaveMeeting = async () => {
    if (isConnected) {
      if (permissions?.endRoom) {
        setShowMobileNavBottom(true)
        const lock = true
        const reason = "Host ended the session"
        await hmsActions
          .endRoom(lock, reason)
          .then(() => {
            setPopupNotification({
              show: true,
              callback: closePopup,
              title: "You ended the session",
              message:
                "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
            })
          })
          .then(() => {
            updateSendReview()
            SessionsRequests.sessionRemoveParticipant({
              room_id: roomId,
            })
              .then(() => {})
              .catch((ex) => {
                console.log(ex)
                renderError(ex.response.data.message)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      } else {
        await hmsActions
          .leave()
          .then(() => {
            setPopupNotification({
              show: true,
              callback: closePopup,
              title: "You left the session",
              message:
                "To see the details for this completed session, check the 'Past Sessions' section after closing this message.",
            })
          })
          .then(() => {
            SessionsRequests.sessionRemoveParticipant({
              room_id: roomId,
            })
              .then(() => {
              })
              .catch((ex) => {
                console.log(ex)
                renderError(ex.response.data.message)
              })
          })
          .catch((error) => {
            console.log(error)
          })
      }
    }
  }

  const toggleScreenShare = async () => {
    if (amIScreenSharing) {
      await hmsActions
        .setScreenShareEnabled(false, {
          videoOnly: false,
        })
        .then(() => {
          console.log("disabled screenshare")
        })
        .catch((err) => {
          console.log(err)
        })
    } else {
      if (screenshareOn) {
        renderError(`${presenter?.name || "Someone"} is already sharing.`)
      } else {
        await hmsActions
          .setScreenShareEnabled(true, {
            videoOnly: false,
          })
          .then(() => {
            console.log("enabled screenshare")
          })
          .catch((err) => {
            console.log(err)
          })
      }
    }
  }

  const startRecording = async () => {
    setLoadingRecording(true)
    const params = {
      meetingURL: "",
      record: true,
    }

    try {
      await hmsActions.startRTMPOrRecording(params).then(() => {
        setLoadingRecording(false)
      })
    } catch (err) {
      console.error("Failed to stop recording")
    }
  }

  const stopRecording = async () => {
    setLoadingRecording(true)
    try {
      await hmsActions.stopRTMPAndRecording().then(() => {
        setLoadingRecording(false)
      })
    } catch (err) {
      console.error("Failed to stop recording")
    }
  }

  //   if(lastWebsocketMessage?.type === "session"){
  //     if(
  //       lastWebsocketMessage?.data?.event_type === "participant_left"
  //       && lastWebsocketMessage?.data?.participant_count <= 1
  //       && lastWebsocketMessage?.data?.room_id === currentRoomId
  //     ){
  //       setShowEmptyRoomPrompt(true)
  //     }
  //   }
  // }, [lastWebsocketMessage])

  const sendReviewButton = () => {
    let showButton = false
    if (lastWebsocketMessage?.type === "session") {
      if (lastWebsocketMessage?.data?.participant_count <= 2) {
        showButton = true
      } else {
        showButton = false
      }
    }
    if (permissions?.endRoom && user?.isCoach && showButton === true) {
      setShowReviewButton(true)
    }
  }

  useEffect(() => {
    sendReviewButton()
  }, [])

  return (
    <div
      className={`${
        mobileView ? "fixed" : "absolute"
      } bottom-0 left-0 w-full z-50
      flex items-end h-[140px] session-controls-gradient pb-[80px] sm:pb-[40px] md:pb-[24px]`}
    >
      <div className="flex flex-col md:flex-row items-center md:px-[48px] justify-between w-full">
        {/* buttons left */}
        <div className="flex items-center sm:gap-[24px]">
          {showReviewButton && (
            <>
              <div className="flex flex-row m-2 gap-2">
                <div
                  className="min-w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent flex items-center justify-center text-white"
                  onClick={() => setSendReview(!sendReview)}
                >
                  {sendReview && <SvgCheckbox />}
                </div>
                <div className="ml-1">
                  <p className="text-white">Ask client for a testimonial</p>
                </div>
              </div>
            </>
          )}
          <button className="hidden md:block invisible" disabled>
            <SvgMetrics />
          </button>
          <button className="invisible hidden md:block" disabled>
            <SvgUsersSolid />
          </button>
        </div>
        {/* buttons center */}
        <div
          className={`flex items-center justify-center gap-[24px] ${
            showReviewButton ? "md:-ml-48" : "md:ml-16"
          }`}
        >
          <Button
            onClick={toggleAudio}
            className="sessions-icon text-white"
            data-tooltip="Microphone"
            variant="ghost"
            size="icon"
          >
            {isLocalAudioEnabled ? <SvgMic /> : <SvgMicOff />}
          </Button>
          <Button
            onClick={leaveMeeting}
            variant="destructive"
            className={`${mobileView ? "w-[200px]" : ""}`}
          >
            {permissions?.endRoom ? "End Session" : "Leave Session"}
          </Button>
          <Button
            onClick={toggleVideo}
            className="sessions-icon text-white"
            data-tooltip="Camera"
            variant="ghost"
            size="icon"
          >
            {isLocalVideoEnabled ? <SvgVideoSolid /> : <SvgVideoOffSolid />}
          </Button>
        </div>
        {/* buttons right */}
        <div className="hidden sm:flex items-center gap-[24px] text-white justify-end pr-6 -translate-x-8">
          {/* {Boolean(roomCode.length) && (
            <Button
              className="sessions-icon"
              onClick={() => copy(`${process.env.REACT_APP_BASE_URL}/sessions/${roomCode}`)} variant="ghost"
              data-tooltip="Copy Session Link"
              size="icon"
            >
              <SvgShare />
            </Button>
          )} */}
          {user.activeProfile === "coach" && (
            <Button
              disabled={loadingRecording}
              variant="ghost"
              size="icon"
              onClick={
                recordingState.browser.running ? stopRecording : startRecording
              }
              className="sessions-icon"
              data-tooltip={
                recordingState.browser.running
                  ? "Stop recording"
                  : "Start recording"
              }
            >
              <SvgRecord
                className={
                  recordingState.browser.running ? "text-vividRed" : ""
                }
              />
            </Button>
          )}
          <Button
            onClick={toggleScreenShare}
            className="sessions-icon hidden md:flex"
            data-tooltip="Share Screen"
            variant="ghost"
            size="icon"
          >
            <SvgShareScreen />
          </Button>
          <Button
            className="sessions-icon hidden md:flex"
            data-tooltip={fullScreen ? "Minimize" : "Maximize"}
            onClick={() => setFullScreen(!fullScreen)}
            variant="ghost"
            size="icon"
          >
            {fullScreen ? <SvgMinimize /> : <SvgMaximize />}
          </Button>
        </div>
      </div>
    </div>
  )
}

export default VideoControlsBottom
