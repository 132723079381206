import React, { FC, FormEvent, useEffect, useState } from "react"
import PasswordRequirements from "../../components/account-settings/PasswordRequirements"
import SecurePassword from "../../components/authentication/SecurePassword"
import useLoading from "../../hooks/useLoading"
import { Button } from "../../components/ui/button"
import { EmailLogin } from "../../api/auth.service"
import { Input } from "../../components/ui/input"
import { Link, useNavigate } from "react-router-dom"
import { SvgChevronLeft, SvgZoeeLogo } from "../../components/icons/"

interface Props {}

const ResetPassword: FC<Props> = (props: any) => {
  const { startLoading, stopLoading } = useLoading()
  const [newPassword, setNewPassword] = useState<string>("")
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [formErrors, setFormErrors] = useState<any>({})
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [code, setCode] = useState<string>("")
  const [validPasswordChars, setValidPasswordChars] = useState<boolean>(false)
  const [showPassReq, setShowPassReq] = useState(false)
  const [validEntree, setValidEntree] = useState<string>("")
  const [redirectPath, setRedirectPath] = useState<string>("")
  const navigate = useNavigate()

  const validateForm = () => {
    const errors: any = {}
    ;(!newPassword || !validPasswordChars) &&
      (errors.password = "Valid password is required")
    ;(!confirmPassword || confirmPassword !== newPassword) &&
      (errors.confirmPassword = "Password must match")
    return errors
  }

  const validateCharacters = (pswd: string) => {
    var pattern = /^(?=.*\d)(?=.*[^a-zA-Z0-9])(?=.*[A-Z])(?=.*[a-z]).{8,}$/
    setValidEntree(pswd)
    if (pswd.match(pattern)) {
      setValidPasswordChars(true)
    } else {
      setValidPasswordChars(false)
    }
  }

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault()
    setFormErrors(validateForm())
    setIsSubmit(true)
  }

  const createNewPassword = () => {
    // call 'verify new password' lambda, sending the code and the new password
    if (code && newPassword) {
      EmailLogin.resetPasswordChangePassword({
        verification_code: code,
        password: newPassword,
      })
        .then((data) => {
          if (data.message === "complete") {
            // show modal telling user to check their email
            navigate("/?reset=complete")
            setTimeout(() => navigate(redirectPath), 0)
          }
        })
        .catch((ex) => {
          console.log(ex)
          props.setError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
    // if success, redirect to login and show success message
    // if failure, redirect to login and show failure message
  }

  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      startLoading()
      setIsSubmit(false)
      createNewPassword()
    } else {
      setIsSubmit(false)
      stopLoading()
    }
  }, [formErrors])

  useEffect(() => {
    const params = new URLSearchParams(window.location.search)
    const code = params.get("t")
    setRedirectPath(params.get("redirectPath") as string)
    if (code) {
      setCode(code)
    } else {
      navigate(redirectPath)
    }
  }, [])

  return (
    <div className="flex items-center flex-col justify-start mt-[40px]">
      <SvgZoeeLogo className="mb-40" />
      <div className="w-[360px] md:w-[480px] flex flex-col items-center">
        <div className="font-semibold text-[32px] mb-[40px]">
          Set New Password
        </div>
        <form
          onSubmit={handleSubmit}
          className="flex flex-col w-full mb-12 gap-6"
        >
          {showPassReq && (
            <PasswordRequirements validEntree={validEntree} bottomMargin="0" />
          )}
          <Input
            label="New Password"
            placeholder="Enter New Password"
            type="password"
            error={formErrors.password}
            id="createPassword"
            name="createPassword"
            onChange={({ target: { value } }) => {
              validateCharacters(value)
              setNewPassword(value)
            }}
            value={newPassword}
            onFocus={() => setShowPassReq(true)}
            onBlur={() => setShowPassReq(false)}
          />
          <SecurePassword password={newPassword} />
          <Input
            label="Confirm Password"
            placeholder="Confirm your Password"
            type="password"
            error={formErrors.confirmPassword}
            id="confirmPassword"
            name="confirmPassword"
            onChange={({ target: { value } }) => setConfirmPassword(value)}
            value={confirmPassword}
          />
          <Button type="submit">Continue</Button>
        </form>
        <Link to="/" className="flex items-center gap-2">
          <SvgChevronLeft />
          Home
        </Link>
      </div>
    </div>
  )
}

export default ResetPassword
