import React, { useContext } from "react"
import CardSelector from "./CardSelector"
import Loader from "../ui/loader"
import { SubscriptionContext } from "./SubscriptionContext"

interface Props {
  paymentMethods: any[]
}

const CurrentPaymentMethod: React.FC<Props> = ({ paymentMethods }) => {
  const {
    selectedPaymentMethod,
    setSelectedPaymentMethod,
    createSetupIntent,
    setPaymentMethodModal,
  } = useContext(SubscriptionContext)

  return (
    <>
      <div className="main-shadow mb-[80px] w-full rounded-[16px] p-[16px] sm:mb-0 sm:min-w-[360px] md:h-[178px] md:p-[32px]">
        <div className="mb-[32px] flex w-full items-center justify-between">
          <h2 className="text-[22px] font-bold">Payment Method</h2>
        </div>
        {paymentMethods !== null ? (
          <>
            {paymentMethods?.length > 0 ? (
              <div className="mb-[24px] flex items-center">
                <CardSelector
                  paymentMethods={paymentMethods}
                  selectedPaymentMethod={selectedPaymentMethod}
                  setSelectedPaymentMethod={setSelectedPaymentMethod}
                  callback={() => {
                    createSetupIntent().then(() => {
                      setPaymentMethodModal(true)
                    })
                  }}
                />
              </div>
            ) : (
              <></>
            )}
          </>
        ) : (
          <div className="flex w-full items-center justify-center">
            <Loader />
          </div>
        )}
      </div>
    </>
  )
}

export default CurrentPaymentMethod
