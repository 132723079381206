import React, { FC } from "react"
import ContactAvatar from "../../../cards/ContactAvatar"
import { Meeting } from "../../../../types/meetingTypes"
import MeetingStatusIcon from "../../MeetingStatusIcon"
import { MeetingRequests } from "../../../../api/app.service"
import { UserCtx } from "../../../../context/userContext"
import { CommonFunctionCtx } from "../../../../context/commonFunctionContext"
import ServiceCardPreviewWide from "../../../services/ServiceCardPreviewWide"
import useDate from "../../../../hooks/useDate"
import useScrollToTop from "../../../../hooks/useScrollToTop"
import useLoading from "../../../../hooks/useLoading"
import { Button } from "../../../ui/button"
import { SvgSend, SvgVideoSolid } from "../../../icons"

type Props = {
  newMeetingData: Meeting
  setNewMeetingData: any
  setStep: any
  newMeetingType: any
  isEditing?: boolean
  setShowNewMeeting: any
  setShowCalendar: any
}

const ConfirmSend: FC<Props> = ({
  newMeetingData,
  setNewMeetingData,
  setStep,
  newMeetingType,
  isEditing,
  setShowNewMeeting,
  setShowCalendar,
}) => {
  const { user } = React.useContext(UserCtx)
  const { renderError, renderSuccess } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { fmtTime, mdDate } = useDate()

  const createMeeting = () => {
    if (
      !(
        newMeetingData.meetingName &&
        newMeetingData.startTime &&
        newMeetingData.endTime &&
        newMeetingData.medium &&
        newMeetingData.status
      )
    ) {
      console.error("missing meeting property")
    } else {
      startLoading()
      // Add user to participants array
      const participants = newMeetingData.contacts
      const userParticipantObj = {
        contact_name: `${user.firstName} ${user.lastName}`,
        email: user.email,
        has_avatar: user.hasAvatar,
        profile_id: user.activeProfileId,
        user_color: user.userColor,
        user_id: parseInt(user.user_id),
      }
      participants.push(userParticipantObj)

      // convert JS Date object in newMeetingData state into string consumable by database
      const isoStartTime = newMeetingData.startTime.toISOString()
      const parsedStartTime = isoStartTime.includes(".")
        ? isoStartTime.split(".")[0] + "Z"
        : isoStartTime

      const isoEndTime = newMeetingData.endTime.toISOString()
      const parsedEndTime = isoEndTime.includes(".")
        ? isoEndTime.split(".")[0] + "Z"
        : isoEndTime
      // Make request
      MeetingRequests.createMeeting({
        user_id: parseInt(user?.user_id),
        coach_user_id: parseInt(newMeetingData.coach_user_id),
        profile_id: user.activeProfileId,
        meeting_details: {
          name: newMeetingData.meetingName || "",
          meeting_type: newMeetingData.meetingType,
          start_time: parsedStartTime,
          end_time: parsedEndTime,
          medium: newMeetingData.medium || "",
          status: newMeetingData.status || "",
          reminders: [],
          participants: participants,
          service_usage_id: newMeetingData?.service_usage_id || null,
        },
      })
        .then(() => {
          localStorage.removeItem("selectedDate")
          localStorage.removeItem("selectedStartTime")

          stopLoading()
          setShowNewMeeting(false)
          setShowCalendar(true)
          const myName = user?.firstName + " " + user?.lastName
          const participantNames = participants
            .map((participant) => participant?.contact_name)
            .filter((name) => name !== myName)
            .join(", ")
          renderSuccess(`Session Invite has been sent to ${participantNames}!`)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const updateMeeting = () => {
    // TODO handle validations better, show to user
    if (
      !(
        newMeetingData.meetingName &&
        newMeetingData.startTime &&
        newMeetingData.endTime &&
        newMeetingData.medium &&
        newMeetingData.status &&
        newMeetingData.id
      )
    ) {
      console.error("missing meeting property")
    } else {
      startLoading()
      // Filter out duplicates based on user_id and profile_id
      const participants = newMeetingData.contacts.filter(
        (participant) =>
          participant.user_id !== user.user_id &&
          participant.profile_id !== parseInt(user.activeProfileId)
      )

      const userParticipantObj = {
        id: null,
        user_id: parseInt(user.user_id),
        profile_id: parseInt(user.activeProfileId),
        profile_type: user.activeProfile === "coach" ? "coach" : "member",
        meeting_id: newMeetingData.id || 9999999999,
        is_creator: 0,
        email: user.email,
        first_name: user.firstName,
        last_name: user.lastName,
        has_avatar: user.hasAvatar ? 1 : 0,
        user_color: user.userColor,
      }
      participants.push(userParticipantObj)
      const coach = participants.filter(
        (participant) => participant.profile_type === "coach"
      )[0]
      if (coach) {
        coach.is_creator = 1
      }

      const meetingData = {
        user_id: parseInt(user.user_id),
        coach_user_id: parseInt(newMeetingData.coach_user_id),
        profile_id: user.activeProfileId,
        meeting_id: newMeetingData.id || 9999999999,
        meeting_details: {
          name: newMeetingData.meetingName || "",
          meeting_type: newMeetingData.meetingType,
          start_time: newMeetingData.startTime.toISOString(),
          end_time: newMeetingData.endTime.toISOString(),
          medium: newMeetingData.medium || "",
          status: newMeetingData.status || "",
          reminders: [],
          participants: participants,
          service_usage_id: newMeetingData?.service_usage_id || null,
        },
      }

      MeetingRequests.updateMeeting(meetingData)
        .then(() => {
          window.location.reload()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const getParticipantName = (participant: any) => {
    const participantFirstName =
      participant?.firstName || participant?.first_name || ""
    const participantLastName =
      participant?.lastName || participant?.last_name || ""
    const participantName =
      participant?.contact_name ||
      `${participantFirstName} ${participantLastName}`
    return participantName
  }

  const generateMeetingName = () => {
    const { contacts, service_details } = newMeetingData
    const isMemberActive = user.activeProfile === "member"
    const coach = isMemberActive
      ? contacts?.find((contact) => contact.profile_type === "coach")
      : user
    const member = isMemberActive
      ? user
      : contacts?.find((contact) => contact.profile_type === "member")
    const meetingName = `${getParticipantName(coach)} <> ${getParticipantName(
      member
    )}${service_details ? ` // ${service_details.title}` : ""}`
    setNewMeetingData({ ...newMeetingData, meetingName: meetingName })
  }

  const handleEditClick = (stepName: string) => {
    if (newMeetingType === "individual") {
      switch (stepName) {
        case "attendees":
          setStep(1)
          break
        case "services":
          setStep(2)
          break
        case "availability":
          setStep(3)
          break
        case "details":
          setStep(4)
          break
        default:
          break
      }
    } else if (newMeetingType === "group") {
      switch (stepName) {
        case "services":
          setStep(1)
          break
        case "attendees":
          setStep(2)
          break
        case "availability":
          setStep(3)
          break
        case "details":
          setStep(4)
          break
        default:
          break
      }
    } else {
      switch (stepName) {
        case "attendees":
          setStep(1)
          break
        case "availability":
          setStep(2)
          break
        case "details":
          setStep(3)
          break
        default:
          break
      }
    }
  }

  const readyToSubmit = () => {
    const ready =
      newMeetingData.meetingName &&
      newMeetingData.startTime &&
      newMeetingData.endTime &&
      newMeetingData.medium &&
      newMeetingData.status
    return ready
  }

  React.useEffect(() => {
    generateMeetingName()
  }, [])

  useScrollToTop()

  return (
    <div className="pb-[160px] md:pb-[80px] w-full min-w-[320px]">
      <div className="flex items-center w-full justify-between mb-[24px]">
        <h3 className="text-[18px] md:text-[28px] font-bold md:font-semibold">
          Confirm & Send
        </h3>
        <div className="">
          {readyToSubmit() ? (
            <>
              {!isEditing ? (
                <Button
                  onClick={createMeeting}
                  className="flex items-center gap-[8px] h-[72px] md:h-[48px] max-w-[140px] md:max-w-none text-md"
                >
                  Confirm
                  <SvgSend stroke="white" />
                </Button>
              ) : (
                <Button onClick={updateMeeting}>
                  Update Meeting
                  <SvgSend stroke="white" />
                </Button>
              )}
            </>
          ) : (
            <>
              {!isEditing ? (
                <button
                  disabled
                  onClick={createMeeting}
                  className="btn-primary btn-blue flex items-center gap-[8px] h-[72px] md:h-[48px] max-w-[140px] md:max-w-none"
                >
                  Confirm
                  <SvgSend stroke="white" />
                </button>
              ) : (
                <button
                  disabled
                  onClick={updateMeeting}
                  className="btn-primary btn-blue flex items-center gap-[8px] h-[72px] md:h-[48px] max-w-[140px] md:max-w-none"
                >
                  Update Meeting
                  <SvgSend stroke="white" />
                </button>
              )}
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col gap-[16px]">
        <div className="relative promotion-shadow bg-white px-[24px] md:px-[40px] pb-[24px] pt-[28px] md:py-[32px] rounded-[16px]">
          <h5 className="text-base font-bold text-graySlate mb-[28px] md:mb-[24px]">
            EVENT DETAILS
          </h5>

          {newMeetingData.meetingName ? (
            <h3 className="font-bold text-[18px] md:text-[24px] mb-[8px] md:mb-[16px]">
              {newMeetingData.meetingName}
            </h3>
          ) : (
            <p className="text-vividOrange text-base mb-[8px]">
              No meeting name entered
            </p>
          )}

          <div className="flex items-center">
            {newMeetingData.status ? (
              <MeetingStatusIcon status={newMeetingData.status} />
            ) : (
              <p className="text-vividOrange text-base mr-[4px]">
                No status selected
              </p>
            )}

            {newMeetingData.status && newMeetingData.medium && (
              <div className="w-[1px] h-[24px] mx-[16px] bg-grayMist"></div>
            )}

            {newMeetingData.medium && (
              <>
                {newMeetingData.medium === "zoee_video" && (
                  <button className=" flex items-center justify-center py-[4px] px-[8px] rounded-[8px] text-blurple font-bold text-base bg-paleCyanBlue bg-opacity-[.24]">
                    <SvgVideoSolid
                      fill="#3395FF"
                      className="mr-[12px]"
                    />
                    Zoee Video
                  </button>
                )}
                {newMeetingData.medium === "other" && (
                  <div className=" flex items-center justify-center py-[4px] px-[10px] rounded-[8px] font-bold text-base bg-hoverDark">
                    Other
                  </div>
                )}
              </>
            )}
          </div>
        </div>
        <div className="relative promotion-shadow bg-white px-[24px] md:px-[40px] pb-[24px] pt-[28px] md:py-[32px] rounded-[16px]">
          <button
            onClick={() => handleEditClick("availability")}
            className="absolute top-[24px] md:top-[32px] right-[24px] md:right-[40px] btn-primary btn-secondary h-[32px]"
          >
            Edit Date & Time
          </button>
          <h5 className="mb-[16px] text-base font-bold text-graySlate">WHEN</h5>
          <div>
            {newMeetingData.startTime && newMeetingData.endTime ? (
              <>
                <p className="font-bold text-[16px] mb-[8px]">
                  {mdDate(newMeetingData.startTime)}
                </p>
                <div className="flex items-center text-graySlate text-base">
                  <p>Starts at</p>
                  <p className="text-black font-bold">
                    &nbsp;
                    {fmtTime(newMeetingData.startTime)}
                  </p>
                  <p className="mx-[12px]">-</p>
                  <p>Ends at</p>
                  <p className="text-black font-bold">
                    &nbsp;
                    {fmtTime(newMeetingData.endTime)}
                  </p>
                </div>
              </>
            ) : (
              <p className="text-vividOrange text-base">No date selected</p>
            )}
          </div>
        </div>
        <div className="relative promotion-shadow bg-white px-[24px] md:px-[40px] pb-[24px] pt-[28px] md:py-[32px] rounded-[16px]">
          <button
            onClick={() => handleEditClick("attendees")}
            className="absolute top-[24px] md:top-[32px] right-[24px] md:right-[40px] btn-primary btn-secondary h-[32px]"
          >
            Edit Attendees
          </button>
          <h5 className="text-base font-bold text-graySlate mb-[20px]">
            ATTENDEES
          </h5>
          <div className="flex flex-col gap-[12px]">
            {newMeetingData.contacts.length > 0 ? (
              <>
                {newMeetingData.contacts.map((c, idx) => {
                  // if (!isEditing) {
                  return (
                    <div key={idx} className="flex items-center gap-[16px]">
                      <ContactAvatar
                        contact={c}
                        height={32}
                        width={32}
                        bold={false}
                        fontSize={"text-base"}
                      />
                      <h6 className="text-base font-bold">
                        {c.first_name
                          ? `${c.first_name} ${c.last_name}`
                          : c.contact_name}
                      </h6>
                    </div>
                  )
                })}
              </>
            ) : (
              <p className="text-vividOrange text-base">
                No attendees selected
              </p>
            )}
          </div>
        </div>
        {newMeetingType !== "custom" && (
          <div className="relative promotion-shadow bg-white px-[24px] md:px-[40px] pb-[24px] pt-[28px] md:py-[32px] rounded-[16px]">
            <h5 className="text-base font-bold text-graySlate mb-[20px]">
              SERVICES
            </h5>
            {newMeetingData.service_details ? (
              <>
                {isEditing ? (
                  <ServiceCardPreviewWide service={newMeetingData} />
                ) : (
                  <ServiceCardPreviewWide service={newMeetingData} />
                )}
              </>
            ) : (
              <p className="text-vividOrange text-base">No service attached</p>
            )}
          </div>
        )}
      </div>
    </div>
  )
}

export default ConfirmSend
