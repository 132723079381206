import React, { useState } from "react"
import Loader from "../ui/loader"
import { Button } from "../ui/button"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { ProfileRequests } from "../../api/app.service"

interface Props {
  selectedTestimonialId: number | undefined
  setActiveTab: any
}

const PublicOverViewTestimonials: React.FC<Props> = ({
  selectedTestimonialId,
  setActiveTab,
}: Props) => {
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { coachPublicProfileData } = React.useContext(CoachPublicProfileContext)
  const [isLoading, setIsLoading] = useState(false)
  const [testimonial, setTestimonial] = useState<any>()
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor
  )

  const getCoachTestimonials = async () => {
    setIsLoading(true)
    ProfileRequests.getTestimonials({
      coach_public_profile_id: coachPublicProfileData?.coach_public_profile_id,
    })
      .then((data: any) => {
        const allTestimonials = data.testimonials
        let firstTestimonial = allTestimonials[0]
        const indexOfSelectedTestimonial = allTestimonials.findIndex(
          (testimonial: any) => testimonial.id === selectedTestimonialId
        )
        if (indexOfSelectedTestimonial !== -1) {
          firstTestimonial = allTestimonials[indexOfSelectedTestimonial]
        }
        setTestimonial(firstTestimonial)
      })
      .catch((ex: any) => {
        console.log(ex)
        renderError("something went wrong")
      })
      .finally(() => {
        setIsLoading(false)
      })
  }
  React.useEffect(() => {
    getCoachTestimonials()
  }, [coachPublicProfileData])
  return (
    <>
      {isLoading ? (
        <Loader />
      ) : (
        <div className="h-full w-full">
          {testimonial ? (
            <div
              className="flex h-full w-full flex-col justify-between rounded-[20px] p-4"
              style={{
                /*backgroundImage: `url(${background})`,*/ backgroundColor: `${textColor}`,
              }}
            >
              <Button
                onClick={() => setActiveTab("testimonials")}
                variant={"ghost"}
                size={"sm"}
                className="absolute right-4 top-[10px] text-sm"
              >
                See All
              </Button>
              <h1 className="font-bold">Testimonials</h1>
              <p className="line-clamp-[8] max-w-[250px] self-center italic">
                {testimonial?.testimonial}
              </p>
              <p className="pl-4 text-xs font-medium">
                {testimonial?.client_name
                  ? testimonial.client_name
                  : "Anonymous Member"}
              </p>
            </div>
          ) : (
            <></>
          )}
        </div>
      )}
    </>
  )
}

export default PublicOverViewTestimonials
