import { useState, useRef, useEffect, memo, useContext } from "react"
import Loader from "../ui/loader"
import { Button } from "../ui/button"
import { SvgCheckbox, SvgCheckboxOff } from "../icons"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"

interface CardFieldsProps {
  isCheckedRef: React.MutableRefObject<boolean>
  createOrder: (data: any, actions: any) => Promise<any>
  onApprove: (data: any, actions: any) => Promise<any>
  isChecked: boolean
  setIsChecked: (checked: boolean) => void
  isTermsAgreed: boolean
  setIsTermsAgreed: React.Dispatch<React.SetStateAction<boolean>>
  redirectUrl: string
  selectedPlanFromChild: any
  isSubx: boolean
  activeService: any
}

interface CardFieldInstance {
  submit: () => Promise<void>
  getState: () => Promise<void>
  isEligible: () => boolean
  NameField: (options: any) => any
  NumberField: (options: any) => any
  CVVField: (options: any) => any
  ExpiryField: (options: any) => any
}

const PayPalCardFields: React.FC<CardFieldsProps> = ({
  isCheckedRef,
  onApprove,
  createOrder,
  isChecked,
  setIsChecked,
  isSubx,
  activeService,
}) => {
  const [nameError, setNameError] = useState("");
  const {renderError} =  useContext(CommonFunctionCtx);
  const [loading, setLoading] = useState(false)
  const checkboxRef = useRef<HTMLInputElement | null>(null)
  const [cardFieldInstance, setCardFieldInstance] =
    useState<CardFieldInstance | null>(null)

  const handleChange = (event: any) => {
    if (
      activeService?.service_details?.type !== "recurring" &&
      activeService?.service_details?.billing?.unit !== "installment"
    ) {
      setIsChecked(event.target.checked);
      isCheckedRef.current = event.target.checked;
    }
  };
  
  useEffect(() => {
    if (
      activeService?.service_details?.type === "recurring" ||
      activeService?.service_details?.billing?.unit === "installment"
    ) {
      setIsChecked(true);
      isCheckedRef.current = true;
    }
  }, [activeService?.service_details?.type, activeService?.service_details?.billing?.unit]);

  useEffect(() => {
    isCheckedRef.current = isChecked
  }, [isChecked])

  useEffect(() => {
    if (window.paypal) {
      console.log("SDK loaded")
      const cardField = window.paypal.CardFields({
        createOrder: (data: any) => createOrder(data, "card"),
        onApprove: (data: any) => onApprove(data, "card"),
      })

      if (cardField.isEligible()) {
        const nameField = cardField.NameField()
        nameField.setAttribute("placeholder", "Enter your full name");
        nameField.render("#card-name-field-container");

        const numberField = cardField.NumberField();
        numberField.render("#card-number-field-container");

        const cvvField = cardField.CVVField();
        cvvField.render("#card-cvv-field-container");

        const expiryField = cardField.ExpiryField();
        expiryField.render("#card-expiry-field-container");

        setCardFieldInstance(cardField);
      }
    }
  }, []);

  const handleSubmit = () => {
    if (cardFieldInstance) {
      cardFieldInstance.getState().then((data: any) => {
        if (data.fields.cardNameField.isEmpty) {
          console.error("Cardholder name is required!");
          setLoading(false);
          return renderError("Cardholder name is required!")
        } else {
          setLoading(true);
          cardFieldInstance
            .submit()
            .then(() => {
              setLoading(false);
            })
            .catch((error: any) => {
              setLoading(false);
            })
        }
      })
    }
  }

  return (
    <>
      <div id="checkout-form">
        <div id="card-name-field-container"></div>
        <div id="card-number-field-container"></div>
        <div id="card-expiry-field-container"></div>
        <div id="card-cvv-field-container"></div>
      </div>

      {/* Show the error message below the name field */}
      {nameError && <div className="text-red-500 mt-2">{nameError}</div>} 

      <div className="flex flex-col items-center justify-center w-full space-y-2">
        <div className="flex w-[95%] mb-2 mt-2">
          <input
            id="checkbox-input"
            ref={checkboxRef}
            onChange={handleChange}
            checked={isChecked}
            type="checkbox"
            className="hidden"
          />
          <label
            htmlFor="checkbox-input"
            className="self-start mr-4 cursor-pointer flex items-center"
          >
            {isChecked ? (
              <SvgCheckbox className="cursor-pointer" />
            ) : (
              <SvgCheckboxOff className="cursor-pointer" fill="#CCCCCC" />
            )}
          </label>
          <div className="text-graySlate text-base">
            {!isSubx ? (
              <p>Save card for future payments</p>
            ) : (
              <p>Card will be saved for subscription renewal</p>
            )}
          </div>
        </div>
      </div>

      <div>
        <Button
          id="card-field-submit-button"
          type="button"
          className="w-[95%] text-md ml-2"
          onClick={handleSubmit}
        >
          {loading ? <Loader /> : "Pay Now"}
        </Button>
      </div>
    </>
  )
}

export default memo(PayPalCardFields);
