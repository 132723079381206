import React, { useState } from "react"
import ServiceCard from "./ServiceCard"
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd"
import { ServiceRequests } from "../../api/app.service"
import { SvgDraggable, SvgPlus } from "../icons/"

interface Props {
  services: any
  setUpdateService: any
  getServices: any
  showComponent: any
  setShowArchivedServices: any
  setIsGroupService: any
  showGroupVsIndividualSelector: any
}

const ActiveServices: React.FC<Props> = ({
  services,
  setUpdateService,
  getServices,
  showComponent,
  setShowArchivedServices,
  setIsGroupService,
  showGroupVsIndividualSelector,
}) => {
  const activeServices = services.filter(
    (service: any) => service.service_details.status === "active"
  )

  const [orderedServices, setOrderedServices] = useState(activeServices)

  const reorderClientServices = (ids: number[]) => {
    ServiceRequests.reorderClientServices({
      service_ids: ids,
    })
      .then(() => {
      })
      .catch((ex) => {
        console.log(ex)
      })
  }

  const onDragEnd = (result: any) => {
    const { destination, source } = result

    if (!destination) return

    // if order does not change
    if (
      destination.droppableId === source.droppableId &&
      destination.index === source.index
    ) {
      return
    }

    const items = Array.from(orderedServices)
    const [reorderedItem] = items.splice(source.index, 1)
    items.splice(destination.index, 0, reorderedItem)

    setOrderedServices(items)

    items.forEach((item: any, index) => {})

    reorderClientServices(items.map((item: any) => item.service_id))
  }

  return (
    <>
      {activeServices.length > 0 ? (
        <div className="flex flex-wrap justify-center md:justify-start w-auto max-w-fit gap-[24px]">
          <DragDropContext onDragEnd={onDragEnd}>
            <Droppable droppableId="services">
              {(provided) => (
                <div
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                  className="grid lg:grid-cols-2 gap-6 last:mb-[80px]"
                >
                  {orderedServices.map((service: any, index: number) => (
                    <Draggable
                      draggableId={String(service.service_id)}
                      index={index}
                      key={service.service_id}
                    >
                      {(provided, snapshot) => (
                        <div
                          ref={provided.innerRef}
                          {...provided.draggableProps}
                          className={`${
                            snapshot.isDragging ? "opacity-50" : ""
                          }`}
                        >
                          <div
                            {...provided.dragHandleProps}
                            className="px-2 py-4 opacity-50 hover:opacity-100 transition-all inline-flex"
                          >
                            <SvgDraggable />
                          </div>

                          <ServiceCard
                            service={service}
                            setUpdateService={setUpdateService}
                            getServices={getServices}
                            setShowArchivedServices={setShowArchivedServices}
                            showComponent={showComponent}
                            setIsGroupService={setIsGroupService}
                          />
                        </div>
                      )}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </div>
      ) : (
        <div
          className="flex px-[8%] items-center justify-center h-[399px] border border-1
                    border-grayMist rounded-[16px] bg-[#f8f8f8]"
        >
          <div className="flex-col items-center justify-center gap-[10px]">
            <h2 className="font-bold text-center text-[21px]">
              Create, share, and sell personalized coaching programs quickly and
              easily. Client onboarding is automated, right from the moment they
              sign up. From scheduling sessions to handling payments, everything
              is streamlined for a hassle-free experience.
            </h2>
            <button
              className="btn-primary btn-blue bg-blurple mx-auto mt-[20px] md:mt-[40px]"
              onClick={showGroupVsIndividualSelector}
            >
              Create a Service
              <SvgPlus />
            </button>
          </div>
        </div>
      )}
    </>
  )
}

export default ActiveServices
