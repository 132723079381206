import React, { useState } from "react"
import CharLimit from "../ui/charLimit"
import CloseModal from "../CloseModal"
import EditSocialMedia from "./EditSocialMedia"
import Loader from "../ui/loader"
import Select from "react-select"
import TagsModal from "./TagsModal"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { ProfileRequests } from "../../api/app.service"
import { regions } from "../../objects/regionObjects"
import { SocialMediaLink } from "../../models/app.interface"
import {
  SvgCheck,
  SvgCheckCircle,
  SvgChevronRight,
  SvgEdit,
  SvgWarning,
} from "../icons"

export default function EditProfileInformation() {
  const [loadingSlugVerification, setLoadingSlugVerification] =
    React.useState<boolean>(false)

  const {
    setEditCredentials,
    editSocialMedia,
    setEditSocialMedia,
    coachPublicProfileData,
    getCoachPrivateProfile,
    setEditProfileInformation,
    selectedTags,
  } = React.useContext(CoachPrivateProfileContext)
  const [customColor, setCustomColor] = useState<string>("")
  const allowedColors = [
    "#4750F5",
    "#7291FF",
    "#1DB685",
    "#FFAF43",
    "#9057BD",
    "#63696F",
  ]
  const [endpointSlugTaken, setEndpointSlugTaken] =
    React.useState<boolean>(false)
  const { renderSuccess, renderError } = React.useContext(CommonFunctionCtx)
  const [socialLinks, setSocialLinks] = useState<SocialMediaLink[]>([])
  const [tagModal, setTagModal] = useState<boolean>(false)
  const [loading, setLoading] = useState<boolean>(false)
  const [tagsUpdated, setTagsUpdated] = useState<boolean>(false)
  const [tempEndpointSlug, setTempEndpointSlug] = React.useState<string>(
    coachPublicProfileData?.endpoint_slug
  )
  // POPULATE FORM VALUES ON COMPONENT LOAD WITH PROFILE DATA
  const [formValues, setFormValues] = React.useState<any>({
    profileColor: "",
    endpointSlug: "",
    title: "",
    description: "",
    about: "",
    yearsExperience: 0,
  })
  // FORM ERRORS
  const [formErrors, setFormErrors] = React.useState<any>({})
  const [isSubmit, setIsSubmit] = React.useState<boolean>(false)
  const [urlChecked, setUrlChecked] = React.useState<boolean>(true)

  const getSocialMedia = async () => {
    try {
      const data = await ProfileRequests.getSocialMedia({
        coach_public_profile_id: coachPublicProfileData.coach_public_profile_id,
      })
      setSocialLinks(data.links)
    } catch (ex: any) {
      console.error(ex)
      renderError(ex.response?.data?.message || "An error occurred.")
    }
  }

  const validateForm = () => {
    const hexPattern = /^#([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/
    const errors: any = {}

    if (!formValues.profileColor || !hexPattern.test(formValues.profileColor)) {
      errors.profileColor = "Valid hex code required"
    }

    !formValues.title && (errors.title = "Title is required")
    !formValues.description && (errors.description = "Description is required")

    if (!formValues.endpointSlug || endpointSlugTaken) {
      errors.endpointSlug = "A unique profile URL endpoint is required."
    }

    return errors
  }

  const handleColorSelection = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault()
    const target = e.target as HTMLButtonElement
    setFormValues({ ...formValues, profileColor: target.dataset.color })
  }
  const handleCustomColorSelection = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setFormValues({ ...formValues, profileColor: e.target.value })
    setCustomColor(e.target.value)
  }
  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const handleChangeSlug = (e: React.ChangeEvent<HTMLInputElement>) => {
    setTempEndpointSlug(e.target.value)
    setUrlChecked(false)
  }

  const handleChangeTextArea = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    setFormValues({ ...formValues, [e.target.name]: e.target.value })
  }

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    setLoading(true)
    setFormErrors(validateForm())
    setIsSubmit(true)
  }

  const updateProfileInformation = () => {
    if (formValues !== coachPublicProfileData?.profile_information) {
      ProfileRequests.editProfileInformation({
        coach_public_profile_id: parseInt(
          coachPublicProfileData.coach_public_profile_id
        ),
        profile_information: formValues,
        tags: selectedTags,
        endpoint_slug: formValues.endpointSlug.toLowerCase(),
      })
        .then(() => {
          setLoading(false)
          renderSuccess("We've saved your progress!")
          setEditProfileInformation(false)
          getCoachPrivateProfile()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    } else {
      renderError("No changes made!")
    }
  }

  const checkUrlAvailability = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    if (tempEndpointSlug.match(/^[0-9a-zA-Z_-]{6,30}$/)) {
      if (tempEndpointSlug !== formValues?.endpointSlug) {
        const lowercaseSlug = tempEndpointSlug?.toLowerCase()
        setLoadingSlugVerification(true)
        ProfileRequests.checkUrlAvailability({
          coach_public_profile_id: parseInt(
            coachPublicProfileData.coach_public_profile_id
          ),
          endpoint_slug: lowercaseSlug,
        })
          .then((data: any) => {
            if (data.endpoint_available) {
              setEndpointSlugTaken(false)
              setFormValues({ ...formValues, endpointSlug: tempEndpointSlug })
              setUrlChecked(true)
              renderSuccess("This profile URL is available!")
            } else {
              setEndpointSlugTaken(true)
              setUrlChecked(false)
              setTempEndpointSlug(formValues?.endpointSlug)
              renderError("This profile URL is in use!")
            }
            setLoadingSlugVerification(false)
          })
          .catch((ex) => {
            setLoadingSlugVerification(false)
            console.log(ex)
            renderError(ex.response.data.message)
          })
      }
    } else {
      setTempEndpointSlug(formValues?.endpointSlug)
      renderError(
        "Profile URL should be between six and 30 characters, and can contain numbers, letters, dashes and underscores"
      )
    }
  }

  const setInitialProfileColor = () => {
    const profileColor =
      coachPublicProfileData?.profile_information?.profileColor
    return profileColor || allowedColors[0]
  }

  React.useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      updateProfileInformation()
      setIsSubmit(false)
    } else {
      setIsSubmit(false)
    }
  }, [isSubmit])

  React.useEffect(() => {
    if (tagsUpdated) {
      getCoachPrivateProfile()
      setTagsUpdated(false)
    }
  }, [tagsUpdated])

  React.useEffect(() => {
    if (coachPublicProfileData) {
      setFormValues({
        ...coachPublicProfileData?.profile_information,
        endpointSlug: coachPublicProfileData?.endpoint_slug,
        profileColor: setInitialProfileColor(),
      })
    }
  }, [])

  console.log("coach tags", coachPublicProfileData.tags)
  return (
    <div className="fixed top-0 left-0 w-screen h-full flex items-center justify-center zoee-backdrop-filter md:z-[801] z-[1009]">
      <div className="w-[800px] max-w-[90%] main-shadow rounded-[16px] pt-[103px] pb-[40px] max-h-[85vh] bg-white fixed left-[50%] -translate-x-[50%] top-[60px] flex flex-col items-start">
        <CloseModal
          callback={() => setEditProfileInformation(false)}
          styling="absolute top-[24px] right-[32px] flex items-center justify-center w-[32px] h-[32px] rounded-full bg-grayFlash"
        />
        <div className="flex flex-col justify-center items-center w-full -mt-6 mb-16">
          <h2 className="text-[18px]">Edit</h2>
          <h2 className="font-bold text-[20px]">Intro & About Info</h2>
        </div>

        {/* FORM */}
        <form
          onSubmit={handleSubmit}
          className="overflow-y-auto w-full px-[12px] md:px-[122px] pb-[80px]"
        >
          <div className="flex flex-col gap-[24px] w-[98%] mx-auto">
            {/* BRAND COLOR */}
            <div className="flex flex-col sm:flex-row gap-[16px] items-start w-full justify-between">
              <div className="w-full pr-[16px]">
                <h2 className="text-[16px] font-bold">Profile Brand Color</h2>

                <div className="flex justify-center mt-4">
                  {allowedColors.map((color) => (
                    <div
                      className={`w-16 h-10 m-2 flex flex-row rounded-[12px] gap-0.5 ${
                        color === formValues.profileColor
                          ? `border-2 border-blurple rounded-[14px]`
                          : ``
                      } hover:border hover:border-grayCloud hover:border-2 shadow-md hover:scale-110 transform transition-transform`}
                      key={color}
                    >
                      <button
                        onClick={handleColorSelection}
                        data-color={color}
                        className={`w-1/2 h-full rounded-l-[12px]`}
                        style={{ backgroundColor: color }}
                      ></button>
                      <button
                        onClick={handleColorSelection}
                        data-color={color}
                        className={`w-1/2 h-full rounded-r-[12px]`}
                        style={{ backgroundColor: getLightTextColor(color) }}
                      ></button>
                    </div>
                  ))}
                  <div className="w-16 h-10 m-2 rounded-[12px] relative shadow-md hover:scale-110 transform transition-transform">
                    <label
                      htmlFor="customColorPicker"
                      className="w-full h-full block cursor-pointer rounded-[12px] relative"
                      style={{
                        background:
                          customColor ||
                          "linear-gradient(90deg, red, orange, yellow, green, blue, indigo, violet)",
                      }}
                    >
                      <span className="sr-only">
                        Select custom profile color
                      </span>
                      <input
                        type="color"
                        id="customColorPicker"
                        value={formValues.profileColor}
                        onChange={handleCustomColorSelection}
                        className="opacity-0 absolute inset-0 w-full h-full cursor-pointer"
                        aria-label="Custom profile color picker"
                      />
                    </label>
                  </div>
                </div>
              </div>
            </div>
            {/* ENDPOINT SLUG */}
            <div className="flex flex-col gap-[10px] w-full">
              <p className="text-[16px] font-bold">Profile URL</p>
              <div
                className={`default-input w-full group ${
                  formErrors.endpointSlug && "input-error"
                }`}
              >
                <input
                  type="text"
                  name="endpointSlug"
                  placeholder="Profile URL"
                  className="w-full indent-[182px] sm:indent-[190px] focus:p-0 text-[15px] sm:text-[16px]"
                  onChange={handleChangeSlug}
                  value={tempEndpointSlug}
                  maxLength={20}
                />
                <div className="absolute left-[12px] sm:left-[20px] top-[50%] -translate-y-[50%] font-bold text-base">
                  zoee.com/find-a-coach/
                </div>
                {loadingSlugVerification ? (
                  <div className="absolute right-[20px] top-[50%] -translate-y-[50%]">
                    <Loader />
                  </div>
                ) : (
                  <></>
                )}
              </div>
              {urlChecked ? (
                <div className="flex items-center cursor-pointer">
                  <SvgCheckCircle />
                  <p className="text-base text-vividCyanGreen ml-[8px]">
                    Url Available
                  </p>
                </div>
              ) : (
                <button
                  className="flex items-center cursor-pointer"
                  onClick={checkUrlAvailability}
                >
                  <p className="text-base text-blurple ml-[8px]">
                    Click to check URL Availability
                  </p>
                </button>
              )}
              {formErrors.endpointSlug && (
                <div className="field-error">
                  <SvgWarning />
                  {formErrors.endpointSlug}
                </div>
              )}
              <div className="flex justify-center items-center mb-8">
                {loading ? (
                  <Loader />
                ) : (
                  <Button
                    size={"lg"}
                    type="submit"
                    value={"Save"}
                    disabled={!urlChecked}
                  >
                    Save
                  </Button>
                )}
              </div>
              <div className="w-full border border-grayCloud"></div>
            </div>
            {/* TITLE */}
            <div className="flex flex-col gap-[10px] w-full">
              <div className="flex justify-between items-center">
                <p className="text-[16px] font-bold">
                  Title{" "}
                  <span className="font-normal text-sm text-graySlate">
                    Optional
                  </span>
                </p>
                <CharLimit charNumber={120} inputText={formValues.title} />
              </div>
              <div
                className={`default-input w-full group ${
                  formErrors.title && "input-error"
                }`}
              >
                <input
                  type="text"
                  name="title"
                  placeholder="Title"
                  className="w-full"
                  maxLength={120}
                  onChange={handleChange}
                  value={formValues.title}
                />
                <div className="overlay">Title</div>
              </div>
              {formErrors.title && (
                <div className="field-error">
                  <SvgWarning />
                  {formErrors.title}
                </div>
              )}
            </div>
            {/* DESCRIPTION */}
            <div className="flex flex-col gap-[10px] w-full">
              <div className="flex justify-between items-center">
                <p className="text-[16px] font-bold">
                  Intro{" "}
                  <span className="font-normal text-sm text-graySlate">
                    Optional
                  </span>
                </p>
                <CharLimit
                  charNumber={150}
                  inputText={formValues.description}
                />
              </div>
              <div
                className={`w-full ${formErrors.description && "input-error"}`}
              >
                <textarea
                  name="description"
                  onChange={handleChangeTextArea}
                  cols={30}
                  rows={2}
                  className={`resize-none border-[1px] border-solid text-graySlate text-[16px] bg-white w-full py-[16px] px-[20px] rounded-[10px] focus:outline-0 focus:border focus:border-solid focus:border-royalBlue focus:text-black border-grayCloud`}
                  maxLength={150}
                  placeholder="This is where you’ll put a brief bio. We don’t need to know what hospital you were born at, just what makes you a 100% rockstar coach!"
                  value={formValues.description}
                ></textarea>
              </div>
              {formErrors.description && (
                <div className="field-error">
                  <SvgWarning />
                  {formErrors.description}
                </div>
              )}
            </div>
            {/* ABOUT */}
            <div className="flex flex-col gap-[10px] w-full">
              <div className="flex justify-between items-center">
                <p className="text-[16px] font-bold">
                  About{" "}
                  <span className="font-normal text-sm text-graySlate">
                    Optional
                  </span>
                </p>
                <CharLimit charNumber={1500} inputText={formValues.about} />
              </div>
              <div>
                <textarea
                  name="about"
                  onChange={handleChangeTextArea}
                  maxLength={1500}
                  cols={30}
                  rows={8}
                  className="whitespace-pre-line resize-none border-[1px] border-solid text-graySlate text-[16px] bg-white w-full py-[16px] px-[20px] rounded-[10px] focus:outline-0 focus:border focus:border-solid focus:border-royalBlue focus:text-black border-grayCloud"
                  placeholder=""
                  value={formValues.about}
                ></textarea>
              </div>
              {formErrors.about && (
                <div className="field-error">
                  <SvgWarning />
                  {formErrors.about}
                </div>
              )}
            </div>
            {/* Years of Experience */}
            <div className="flex gap-4 w-full">
              <div className="flex flex-col w-[27%]">
                <p className="text-16 font-bold">Years Experience</p>
                <span className="font-normal text-sm text-graySlate mb-2">
                  Optional
                </span>
                <div className="default-input w-full group">
                  <input
                    type="number"
                    name="yearsExperience"
                    className="w-16"
                    onChange={handleChange}
                    value={
                      coachPublicProfileData?.profile_information
                        ?.yearsExperience === "0"
                        ? null
                        : formValues.yearsExperience
                    }
                    min={0}
                  />
                  <div className="overlay">Years</div>
                </div>
              </div>

              {/* Expertise tags */}
              <div className="w-[73%] flex flex-col items-end">
                <p className="text-16 font-bold mb-2">Areas of Expertise</p>
                <div className="flex flex-wrap h-full justify-end">
                  <Button
                    type="button"
                    onClick={() => {
                      setTagModal(true)
                    }}
                    className="px-2 border hover:border-grayCloud border-2 rounded-lg mr-1 mt-1 text-sm flex items-center font-bold text-[11px] sm:text-[14px] h-[30px] bg-grayFlash hover-bg-white text-black cursor-pointer"
                    size="sm"
                  >
                    <SvgEdit />
                    Edit
                  </Button>
                  {coachPublicProfileData?.tags?.map(
                    (tag: any, index: number) => (
                      <div
                        className="px-2 border border-2 border-grassGreen rounded-lg mr-1 mt-1 text-sm flex items-center font-bold text-[11px] sm:text-[14px] h-[30px] bg-grassGreen line-clamp-1 text-white"
                        key={index}
                      >
                        <span>
                          <SvgCheck />
                        </span>
                        <p className="ml-1">{tag}</p>
                      </div>
                    )
                  )}
                </div>
              </div>
            </div>

            {/* LOCATION */}
            <div className="flex flex-col gap-[10px] w-full">
              <p className="text-[16px] font-bold">
                Location{" "}
                <span className="font-normal text-sm text-graySlate">
                  Optional
                </span>
              </p>
              <Select
                className={`w-full basic-single`}
                classNamePrefix="select"
                value={formValues?.region}
                options={regions}
                menuPlacement="top"
                name="region"
                onChange={(e: any) =>
                  setFormValues({ ...formValues, region: e })
                }
              />
            </div>
          </div>
          <div className="w-full border border-grayCloud mt-6"></div>
          <div className="flex justify-start items-center mt-4">
            <h2 className="font-bold">Credentials + Education</h2>
            <div className="ml-auto">
              <Button
                variant="link"
                className="transform transition-transform text-black hover:scale-110 hover:text-blurple"
                onClick={() => {
                  setEditCredentials(true)
                }}
              >
                <SvgChevronRight />
              </Button>
            </div>
          </div>

          <p className="text-sm">
            This is where you share your education, certifications, and
            credentials
          </p>
          <div className="w-full border border-grayCloud mt-4"></div>
          <div className="flex justify-start items-center mt-4">
            <h2 className="font-bold">External Links</h2>
            <div className="ml-auto">
              <Button
                variant="link"
                className="transform transition-transform text-black hover:scale-110 hover:text-blurple"
                onClick={() => {
                  setEditSocialMedia(true)
                }}
              >
                <SvgChevronRight />
              </Button>
            </div>
          </div>

          <p className="text-sm">
            This is where your social media and website URLs go
          </p>
          <div className="w-full border border-grayCloud mt-4"></div>
          <div className="flex justify-center items-center mt-8 gap-[16px]">
            <Button
              size={"lg"}
              variant={"utility"}
              onClick={() => {
                setEditProfileInformation(false)
              }}
            >
              Cancel
            </Button>
            {loading ? (
              <>
                <Loader />
              </>
            ) : (
              <Button
                size={"lg"}
                type="submit"
                value={"Save"}
                disabled={!urlChecked}
              >
                Save
              </Button>
            )}
          </div>
        </form>
      </div>
      {editSocialMedia && (
        <EditSocialMedia links={socialLinks} getSocialMedia={getSocialMedia} />
      )}
      {tagModal && (
        <TagsModal
          setTagsUpdated={setTagsUpdated}
          setTagsModalOpen={setTagModal}
        />
      )}
    </div>
  )
}
