import React, { useState, useEffect } from "react"
import servicePreviewImage from "../../../assets/images/service-preview-image.webp"
import { Label } from "../../ui/label"
import { Switch } from "../../ui/switch"

interface Props {
  setServiceValues: (newServiceValues: any) => void
  serviceValues: any
  connectAccountData: any
  canEnable: boolean
  setSyncPrompt: any
  enrolledCount?: any
}

const ManageServiceOptions: React.FC<Props> = ({
  setServiceValues,
  serviceValues,
  connectAccountData,
  canEnable,
  setSyncPrompt,
  enrolledCount,
}) => {
  const [isSwitchChecked, setIsSwitchChecked] = useState<boolean>(
    serviceValues?.enabled || false
  )

  useEffect(() => {
    setIsSwitchChecked(serviceValues?.enabled || false)
  }, [serviceValues?.enabled])

  const handleSwitchChange = () => {
    const newState = !isSwitchChecked
    setIsSwitchChecked(newState)

    setServiceValues({
      ...serviceValues,
      enabled: newState,
    })
  }

  return (
    <>
      <div className="flex flex-col w-full gap-[24px] mb-[32px]">
        <div className="flex flex-col w-full gap-[15px]">
          <span className="text-base text-graySlate">General</span>
          <div className="flex items-center justify-between w-full">
            <Label htmlFor="enable-service">Enable Service</Label>

            <Switch
              id="enable-service"
              disabled={enrolledCount && enrolledCount > 0}
              checked={isSwitchChecked}
              onCheckedChange={handleSwitchChange}
            />
          </div>
        </div>
      </div>
      <div className="w-full h-[1px] bg-grayMist mb-[40px]"></div>
      <img
        src={servicePreviewImage}
        alt="preview"
        className="mb-[24px] mx-auto "
      />
    </>
  )
}

export default ManageServiceOptions
