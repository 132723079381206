import * as React from "react"
import { Navigate, Route, Routes, useNavigate } from "react-router-dom"
import { useEffect, useContext, useState } from "react"

// context and hooks imports
import { UserCtx } from "./context/userContext"
import { CommonFunctionCtx } from "./context/commonFunctionContext"
import { NotificationCtx } from "./context/notificationContext"
import { SubscriptionCtx } from "./context/subscriptionContext"

// 3rd party imports
import { selectIsConnectedToRoom, useHMSStore } from "@100mslive/react-sdk"
import useWebSocket, { ReadyState } from "react-use-websocket"

// marketing site component imports
import BlogPage from "./pages/(marketing)/blog/page"
import ContactPage from "./pages/(marketing)/contact/page"
import CookiePolicyPage from "./pages/(marketing)/cookie-policy/page"
import FeaturesPage from "./pages/(marketing)/features/page"
import HomePage from "./pages/(marketing)/page"
import PostPage from "./pages/(marketing)/blog/[slug]/page"
import PricingPage from "./components/PricingPage/page"
import PrivacyPolicyPage from "./pages/(marketing)/privacy-policy/page"
import TermsAndConditionsPage from "./pages/(marketing)/terms-and-conditions/page"
import WhyZoeePage from "./pages/(marketing)/why-zoee/page"
import MarketingPageLayout from "./pages/(marketing)/layout"
import MoneyflowPage from "./pages/(marketing)/moneyflow/page"

import AccountSettingsPage from "./pages/(app)/account-settings/page"
import CalendarSettingsPage from "./pages/(app)/account-settings/calendars/page"
import LoginSecurityPage from "./pages/(app)/account-settings/login-security/page"
import NotificationsPage from "./pages/(app)/account-settings/notifications/page"
import PaymentMethodsPage from "./pages/(app)/account-settings/payment-methods/page"
import PersonalInfoPage from "./pages/(app)/account-settings/personal-info/page"

// List of index, will reorganize when frontend refactoring is complete.
import * as ICF from "./components/Icf"

import * as SubscriptionComponents from "./components/subscription"

import * as Radiant from "./components/Radiant"

import * as Coach from "./components/coach"

import * as Blog from "./components/Blog"

import * as Client from "./components/client"

import * as Affiliate from "./components/affiliate"

import * as IAPRC from "./components/iaprc"

import * as IIN from "./components/IIN"

// private route imports
import DirectLinkRouter from "./components/DirectLinkRouter"
import PrivateRouteCoach from "./components/privateRouteCoach"
import PrivateRouteContact from "./components/privateRouteContact"
import PrivateRouteEmailVerified from "./components/privateRouteEmailVerified"
import PrivateRouteStorageAvailable from "./components/privateRouteStorageAvailable"
import PrivateRouteUser from "./components/privateRouteUser"

import ClientPayments from "./pages/manage-business/ClientPayments"
import CoachAuthedNav from "./components/navigation/CoachAuthedNav"
import { CoachCard } from "./models/public.interface"
import CoachContacts from "./pages/contacts/CoachContacts"
import CoachDashboard from "./pages/dashboard/CoachDashboard/index"
import CoachInbox from "./pages/inbox/CoachInbox"
import CoachPrivateProfile from "./pages/manage-business/profile/CoachPrivateProfile"
import CoachPublicProfile from "./pages/public-profile/CoachPublicProfile"
import CoachScheduling from "./pages/scheduling/CoachScheduling"
import ConfirmEmail from "./pages/confirm-email/ConfirmEmail"
import ContactServicesFlow from "./components/contacts/contact-profile/ContactServicesFlow"
import DynamicContactProfile from "./components/contacts/contact-profile/DynamicContactProfile"
import ManageBusiness from "./pages/manage-business/ManageBusiness"
import MarketplaceNav from "./components/navigation/MarketplaceNav"
import MemberContacts from "./pages/contacts/MemberContacts"

import EnrollmentFinalStep from "./components/IIN/registration/components/EnrollmentFinalStep"
import MigrateToPayPalBanner from "./components/app-navigation/MigrateToPayPalBanner"
import MobileNav from "./components/app-navigation/MobileNav"
import NoMatch from "./components/NoMatch"
import NotesPDFPreview from "./components/notes/NotesPDFPreview"
import NotificationAlert from "./components/alerts/NotificationAlert"
import Notifications from "./pages/notifications/Notifications"
import PopupNotification from "./components/alerts/PopupNotification"
import PrivateRouteSubscription from "./components/privateRouteSubscription"
import ProgramCheckout from "./components/services/ProgramCheckout"
import PublicCredentials from "./components/coach/PublicCredentials"
import PublicProfileServices from "./components/coach/PublicProfileServices"
import PublicServiceDetails from "./components/coach/PublicServiceDetails"
import ResetPassword from "./pages/reset-password/ResetPassword"
import Services from "./pages/manage-business/Services"
import SessionDraggable from "./components/sessions/SessionDraggable"
import Sessions from "./pages/sessions/Sessions"
import SessionsRouter from "./pages/sessions/SessionsRouter"
import SubscriptionCtaBanner from "./components/app-navigation/SubscriptionCtaBanner"
import SubscriptionEndingBanner from "./components/app-navigation/SubscriptionEndingBanner"
import { FindACoachPage } from "./pages/find-a-coach/page"
import { QuickSession } from "./pages/sessions/QuickSession"
import { TestingPage } from "./pages/testing"
import { useCurrentWidth } from "./hooks/useWidth"
import WrappedIINRegistrationPage from "./components/IIN/registration"
import ConnectPayPalAlert from "./components/subscription/ConnectPayPalAlert"
import useDate from "./hooks/useDate"

export default function App() {
  const { user } = useContext(UserCtx)
  const {
    renderError,
    updateNextStepsData,
    popupNotification,
    endActiveSession,
  } = useContext(CommonFunctionCtx)
  const { setNotificationAlert, notificationAlert, setLastWebsocketMessage } =
    useContext(NotificationCtx)
  const {
    showSubscriptionCheckout,
    setShowSubscriptionCheckout,
    activeSubscriptionType,
  } = useContext(SubscriptionCtx)

  const coach: CoachCard = {
    coach_public_profile_id: 0,
    coach_profile_id: 0,
    user_id: 0,
    updated_at: "",
    endpoint_slug: "",
    first_name: "",
    last_name: "",
    description: "",
    tags: [],
    subscription_promo_codes: "",
    max_end_date: "",
    avatar_url: "",
    email: "",
  }

  const navigate = useNavigate()
  const [showMobileNav, setShowMobileNav] = useState<boolean>(false)
  const [, setShowContactProfile] = useState<boolean>(false)
  const [activeContactProfile, setActiveContactProfile] = useState<any>(null)
  const isConnected = useHMSStore(selectIsConnectedToRoom)
  const [showDraggable, setShowDraggable] = useState<boolean>(false)
  const history = useNavigate()
  const width = useCurrentWidth()
  const [, setPhoneVerified] = useState<boolean>(user?.phoneVerified)
  const [meetingDetails, setMeetingDetails] = React.useState<any>(null)
  const [connectPayPalRequest, setConnectPayPalRequest] =
    React.useState<boolean>(false)
  const { iso } = useDate()
  const [coaches, setCoaches] = useState<any[]>([])

  const handleSendCoaches = (coaches: any[]) => {
    setCoaches(coaches)
  }

  const matchesPrivatePathname = () => {
    const privatePaths = [
      "coach",
      "member",
      "notifications",
      "account-settings",
      "feature-request",
      "report-bug",
      "nav-mobile",
      "affiliate",
    ]
    const routeFirstPart = window.location.pathname.split("/")[1]
    return privatePaths.includes(routeFirstPart)
  }

  // **********
  // WEBSOCKETS
  // **********
  const wsUrl = process.env.REACT_APP_WEBSOCKET_URL || ""
  const [, wsSetMessageHistory] = useState<any[]>([])
  const { sendJsonMessage, lastMessage, readyState } = useWebSocket(wsUrl, {
    onOpen: () => wsSendUserDetails(),
    // Will attempt to reconnect on all close events, such as server shutting down
    shouldReconnect: () => true,
  })

  const connectionStatus = {
    [ReadyState.CONNECTING]: "Connecting",
    [ReadyState.OPEN]: "Open",
    [ReadyState.CLOSING]: "Closing",
    [ReadyState.CLOSED]: "Closed",
    [ReadyState.UNINSTANTIATED]: "Uninstantiated",
  }[readyState]

  const wsSendUserDetails = () => {
    if (!user) {
      console.log("websocket: no user")
    } else {
      const payload = {
        action: "websocketGetConnectionDetails",
        user_id: user.user_id,
      }
      sendJsonMessage(payload)
    }
  }

  const parseWebsocketNotificationMessage = (event: MessageEvent<any>) => {
    const parsedObj = JSON.parse(event.data)

    if (parsedObj.type === "notification") {
      setNotificationAlert({
        message: parsedObj.message,
        show: true,
        link: parsedObj.data?.link,
        profile_type: parsedObj.data?.profile_type || "coach",
      })
    }
  }

  useEffect(() => {
    if (lastMessage !== null) {
      setLastWebsocketMessage(JSON.parse(lastMessage.data))
      // THIS IS WHERE INCOMING MESSAGES FROM SERVER SHOULD BE PARSED

      parseWebsocketNotificationMessage(lastMessage)

      wsSetMessageHistory((prev) => prev.concat(lastMessage))
    }
  }, [lastMessage, wsSetMessageHistory])

  useEffect(() => {
    if (width >= 768 && window.location.pathname === "/nav-mobile") {
      navigate("/")
    }
  }, [width])

  useEffect(() => {
    const profileId = user?.isCoach ? "coach" : "client"
    window.location.pathname === `/${profileId}/sessions` ||
    window.location.pathname.includes(`/sessions/`)
      ? setShowDraggable(false)
      : setShowDraggable(true)
  }, [history])

  useEffect(() => {
    wsSendUserDetails()
  }, [user])

  useEffect(() => {
    console.log("ws connectionStatus:", connectionStatus)
  }, [readyState])

  useEffect(() => {
    window.addEventListener("unload", () => updateNextStepsData)
    window.addEventListener("unload", endActiveSession)
    return () => {
      window.removeEventListener("unload", () => updateNextStepsData)
      window.removeEventListener("unload", endActiveSession)
      const created_chat = false
      updateNextStepsData(created_chat)
      endActiveSession()
    }
  }, [])

  return (
    <>
      <ConnectPayPalAlert
        connectPayPalRequest={connectPayPalRequest}
        setConnectPayPalRequest={setConnectPayPalRequest}
      />
      <div className="app bg-background relative md:block">
        {popupNotification.show && (
          <PopupNotification popupNotification={popupNotification} />
        )}

        {notificationAlert.show && (
          <div className="fixed left-1/2 z-50 mx-auto w-full max-w-screen-xl -translate-x-1/2 -translate-y-1/2 transform p-2">
            <NotificationAlert notification={notificationAlert} />
          </div>
        )}

        {showSubscriptionCheckout && user.activeProfile === "coach" && (
          <SubscriptionComponents.QuickSubscriptionCheckout />
        )}

        {/* Draggable session video */}
        {isConnected && showDraggable && (
          <div className="hidden md:block">
            <SessionDraggable />
          </div>
        )}

        {user &&
          user.activeProfile === "coach" &&
          activeSubscriptionType === "trial" &&
          !window.location.pathname
            .toLowerCase()
            .includes("iin-registration") &&
          !window.location.pathname
            .toLowerCase()
            .includes("iinbusinessblueprint") &&
          !new URLSearchParams(window.location.search)
            .get("flow")
            ?.toUpperCase()
            .includes("IIN") && <SubscriptionCtaBanner />}
        {user &&
          user.activeProfile === "coach" &&
          activeSubscriptionType === "paid" &&
          !window.location.pathname
            .toLowerCase()
            .includes("iin-registration") &&
          !window.location.pathname
            .toLowerCase()
            .includes("iinbusinessblueprint") &&
          !new URLSearchParams(window.location.search)
            .get("flow")
            ?.toUpperCase()
            .includes("IIN") && <SubscriptionEndingBanner />}

        {user?.organization === "BBHC" && <MigrateToPayPalBanner />}

        {/* Navigation */}
        {user && matchesPrivatePathname() ? (
          <>
            {user.isCoach === true ? (
              <>
                <CoachAuthedNav />
                <div className="relative md:hidden">
                  {showMobileNav && (
                    <MobileNav setShowMobileNav={setShowMobileNav} />
                  )}
                </div>
              </>
            ) : (
              <>
                <Client.MemberAuthedNav />
                <div className="relative md:hidden">
                  {showMobileNav && (
                    <MobileNav setShowMobileNav={setShowMobileNav} />
                  )}
                </div>
              </>
            )}
          </>
        ) : (
          <>
            {!window.location.pathname.includes("/direct-routes") && (
              <>
                {window.location.pathname.includes("/find-a-coach") && (
                  <MarketplaceNav />
                )}
              </>
            )}
          </>
        )}

        {/* Routing */}
        <Routes>
          {/* Main Routes */}
          <Route path="/direct-routes" element={<DirectLinkRouter />} />

          {/* Public pages */}
          <Route element={<MarketingPageLayout />}>
            <Route path="/" element={<HomePage />} />
            <Route path="/home" element={<HomePage />} />
            <Route path="/why-zoee" element={<WhyZoeePage />} />
            <Route path="/features" element={<FeaturesPage />} />
            <Route path="/pricing" element={<PricingPage />} />
            <Route path="/blog">
              <Route index element={<BlogPage />} />
              <Route path="/blog/:slug" element={<PostPage />} />
            </Route>
            <Route path="/contact" element={<ContactPage />} />
            <Route
              path="/terms-and-conditions"
              element={<TermsAndConditionsPage />}
            />
            <Route path="/privacy-policy" element={<PrivacyPolicyPage />} />
            <Route path="/cookie-policy" element={<CookiePolicyPage />} />
            <Route
              path="/affiliate-program-terms"
              element={<Affiliate.AffiliateProgramTermsPage />}
            />
            <Route path="/moneyflow" element={<MoneyflowPage />} />
          </Route>
          <Route path="*" element={<Navigate to="/" />} />
          <Route element={<IAPRC.IAPRC />}>
            <Route path="/iaprc" element={<IAPRC.IaprcWelcome />} />
          </Route>
          <Route path="/find-a-coach">
            <Route index element={<FindACoachPage />} />
          </Route>

          {/* ICF Page */}
          <Route element={<ICF.IcfPageLayout />}>
            <Route path="/icf" element={<ICF.IcfRegistrationPage />} />
            <Route path="/icf/registration" element={<ICF.ICFSubscription />} />
            <Route
              path="/icf/subscription"
              element={<ICF.ICFSubscriptionCheckout />}
            />
            <Route
              path="/icf/checkout"
              element={<ICF.ICFUpgradeSubscription />}
            />
          </Route>

          {/* Radiant Page */}
          {/* <Route element={<Radiant.RadiantPageLayout />}>
            <Route
              path="/radiant"
              element={<Radiant.RadiantRegistrationPage />}
            />
            <Route
              path="subscription/current"
              element={<SubscriptionComponents.Subscription />}
            />
            <Route
              path="subscription/plans"
              element={<SubscriptionComponents.SubscriptionPlans />}
            />
            <Route
              path="subscription/checkout"
              element={<SubscriptionComponents.SubscriptionCheckout />}
            />
          </Route> */}

          {/* IIN */}
          <Route element={<IIN.IIN />}>
            <Route path="/IINBusinessBlueprint" element={<IIN.IIN />} />
          </Route>
          <Route
            path="/IINBusinessBlueprint/success"
            element={<EnrollmentFinalStep />}
          />
          <Route
            path="/iin-registration"
            element={<WrappedIINRegistrationPage />}
          />

          {/* Coach Public Profile */}
          <Route
            path="/find-a-coach/:endpoint_slug"
            element={<CoachPublicProfile />}
          >
            <Route
              path="/find-a-coach/:endpoint_slug/services"
              element={<PublicProfileServices />}
            />
            <Route
              path="/find-a-coach/:endpoint_slug/blog"
              element={<Blog.ListBlogCards />}
            />
            <Route
              path="/find-a-coach/:endpoint_slug/credentials"
              element={<PublicCredentials />}
            />
            <Route
              path="/find-a-coach/:endpoint_slug/about"
              element={<Coach.ProfileAboutTab />}
            />
          </Route>

          <Route
            path="/find-a-coach/:endpoint_slug/services/:service_id"
            element={<PublicServiceDetails />}
          />
          <Route
            path="/find-a-coach/:endpoint_slug/blog/:post_id"
            element={<Blog.FullBlogPost />}
          />
          {/* Public Quick Session Routes */}
          <Route path="/sessions/:room_code" element={<QuickSession />} />

          {/* Private Routes */}
          <Route element={<PrivateRouteStorageAvailable />}>
            <Route element={<PrivateRouteEmailVerified />}>
              <Route element={<PrivateRouteUser />}>
                <Route path="account-settings">
                  <Route
                    index
                    element={
                      <AccountSettingsPage
                        setShowSubscriptionCheckout={
                          setShowSubscriptionCheckout
                        }
                      />
                    }
                  />
                  <Route path="personal-info" element={<PersonalInfoPage />} />
                  <Route
                    path="login-security"
                    element={<LoginSecurityPage />}
                  />
                  <Route
                    path="payment-methods"
                    element={<PaymentMethodsPage />}
                  />
                  <Route path="calendars" element={<CalendarSettingsPage />} />
                  <Route path="notifications" element={<NotificationsPage />} />
                </Route>
                <Route path="notifications" element={<Notifications />} />
                <Route
                  path="nav-mobile"
                  element={<MobileNav setShowMobileNav={setShowMobileNav} />}
                />
                <Route path="sessions" element={<SessionsRouter />} />
                <Route path="affiliate" element={<Affiliate.ReferACoach />} />

                {/* Coach Routes */}

                <Route path="coach">
                  <Route element={<PrivateRouteCoach />}>
                    <Route index element={<ManageBusiness />} />
                    <Route path="profile" element={<CoachPrivateProfile />} />
                    <Route path="services" element={<Services />} />
                    <Route
                      path="client-payments"
                      element={<ClientPayments />}
                    />
                    <Route
                      path="client-payments/:merchantId"
                      element={<ClientPayments />}
                    />
                    <Route path="dashboard" element={<CoachDashboard />} />
                    <Route path="inbox" element={<CoachInbox />} />
                    <Route
                      path="sessions/:meeting_id?"
                      element={<Sessions />}
                    />
                    <Route path="contacts">
                      <Route
                        index
                        element={
                          <CoachContacts
                            setShowContactProfile={setShowContactProfile}
                            coach={coach}
                            onSendCoaches={handleSendCoaches}
                          />
                        }
                      />
                      <Route element={<PrivateRouteContact />}>
                        <Route
                          path="/coach/contacts/:contact_id"
                          element={
                            <DynamicContactProfile
                              setActiveContactProfile={setActiveContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/coach/contacts/:contact_id/services"
                          element={
                            <ContactServicesFlow
                              contact={activeContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/coach/contacts/:contact_id/services/:service_id"
                          element={
                            <ProgramCheckout
                              activeContactRef
                              contact
                              meetingDetails={meetingDetails}
                              selectedPlanFromChild
                            />
                          }
                        />
                        <Route
                          path="/coach/contacts/:contact_id/notes"
                          element={<NotesPDFPreview />}
                        />
                      </Route>
                    </Route>
                    <Route
                      path="scheduling"
                      element={<CoachScheduling isSchedulePage />}
                    />
                  </Route>
                  <Route element={<PrivateRouteSubscription />}>
                    <Route
                      path="subscription/current"
                      element={<SubscriptionComponents.Subscription />}
                    />
                    <Route
                      path="subscription/plans"
                      element={<SubscriptionComponents.SubscriptionPlans />}
                    />
                    <Route
                      path="subscription/checkout"
                      element={<SubscriptionComponents.SubscriptionCheckout />}
                    />
                    <Route
                      path="subscription/mysubscription"
                      element={<SubscriptionComponents.Subscription />}
                    />
                  </Route>
                </Route>

                <Route path="member">
                  <Route element={<Client.PrivateRouteMember />}>
                    <Route index element={<Client.MemberDashboard />} />
                    <Route
                      path="inbox"
                      element={
                        <Client.MemberInbox
                          phone={user?.phone}
                          setPhoneVerified={setPhoneVerified}
                        />
                      }
                    />
                    <Route
                      path="sessions/:meeting_id?"
                      element={<Sessions />}
                    />
                    {/* <Route path="/member/sessions/:session_id" element={<ConsultationSessionLanding />} /> */}
                    <Route path="contacts">
                      <Route
                        index
                        element={
                          <MemberContacts
                            setShowContactProfile={setShowContactProfile}
                            coach={coach}
                          />
                        }
                      />
                      <Route element={<PrivateRouteContact />}>
                        <Route
                          path="/member/contacts/:contact_id"
                          element={
                            <DynamicContactProfile
                              setActiveContactProfile={setActiveContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/member/contacts/:contact_id/services"
                          element={
                            <ContactServicesFlow
                              contact={activeContactProfile}
                            />
                          }
                        />
                        <Route
                          path="/member/contacts/:contact_id/services/:service_id"
                          element={
                            <ProgramCheckout
                              activeContactRef
                              contact
                              meetingDetails={meetingDetails}
                              selectedPlanFromChild
                            />
                          }
                        />
                      </Route>
                    </Route>
                    <Route
                      path="scheduling"
                      element={<Client.MemberScheduling isSchedulePage />}
                    />
                  </Route>
                </Route>
              </Route>
            </Route>
          </Route>

          {/* Public Routes */}

          <Route path="/confirm-email" element={<ConfirmEmail />} />
          <Route path="/reset-password" element={<ResetPassword />} />
          <Route path="/testing/*" element={<TestingPage />} />
          <Route path="*" element={<NoMatch />} />
          <Route path="/:promocode" element={<Affiliate.AffiliateHomePage />} />
        </Routes>
      </div>
    </>
  )
}
