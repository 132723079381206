import React, { FC, useState } from "react"
import { SvgInformationCircle, SvgPlus, SvgTrash } from "../../icons"
import { Button } from "../../ui/button"
import ChooseFormsToAttachModal from "./ChooseFormsToAttachModal"
import { FormLink } from "../../../models/app.interface"

interface Props {
  setServiceValues: (object: any) => void
  serviceValues: any
  setCreateFormModal: any
  createFormModal: boolean
}

const ServiceLinkedForms: FC<Props> = ({
  setServiceValues,
  serviceValues,
  setCreateFormModal,
  createFormModal,
}) => {
  const [showInformation, setShowInformation] = useState<boolean>(false)
  const [showChooseFormsToAttachModal, setChooseFormsToAttachModal] =
    useState<boolean>(false)
  const [linkedForms, setLinkedForms] = useState<FormLink[]>(
    serviceValues?.serviceLinkedForms || []
  )

  const handleAttachForms = (newForms: FormLink[]) => {
    const updatedLinkedForms = [
      ...linkedForms,
      ...newForms.filter(
        (newForm) =>
          !linkedForms.some(
            (existingForm) => existingForm.form_link_id === newForm.form_link_id
          )
      ),
    ]

    if (updatedLinkedForms.length > 5) {
      alert("You can only attach up to 5 forms.")
      return
    }

    setLinkedForms(updatedLinkedForms)
    setServiceValues({
      ...serviceValues,
      serviceLinkedForms: updatedLinkedForms,
    })
  }

  const deleteAttachedForm = (formId: number) => {
    const updatedLinkedForms = linkedForms.filter(
      (form) => form.form_link_id !== formId
    )
    setLinkedForms(updatedLinkedForms)
    setServiceValues({
      ...serviceValues,
      serviceLinkedForms: updatedLinkedForms,
    })
  }

  return (
    <div className="w-[100%] main-shadow flex flex-col gap-4 rounded-[16px] bg-white p-[32px] md:p-[40px] mx-auto relative">
      <h2 className="flex font-bold text-[22px]">
        <span>Forms</span>
        <SvgInformationCircle
          className="group cursor-pointer"
          onMouseOver={() => setShowInformation(true)}
          onMouseLeave={() => setShowInformation(false)}
        />
      </h2>
      {showInformation && (
        <div className="p-2 text-sm font-medium rounded-[6px] border border-solid border-gray-400 w-[254px] bg-white absolute top-[-145px] left-[calc(100%/5)] md:top-[-135px]">
          <h3 className="font-semibold">Forms & Files</h3>
          <p>
            Attach your digital client intake forms and questionnaires. These
            will be included as links in enrollment email to client as well as
            accessible in their client portal. Forms can be added in the from
            the main “Services” page.
          </p>
        </div>
      )}

      <div>
        {linkedForms?.length > 0 ? (
          linkedForms.map((form) => (
            <div
              key={form.form_link_id}
              className="flex justify-between text-lg font-bold py-2 items-center"
            >
              <p>{form.title}</p>
              <Button
                size="icon"
                variant="ghost"
                onClick={() => deleteAttachedForm(form.form_link_id)}
              >
                <SvgTrash />
              </Button>
            </div>
          ))
        ) : (
          <span>No Forms Attached</span>
        )}
      </div>

      <Button
        variant="secondary"
        className="mx-auto"
        onClick={() => setChooseFormsToAttachModal(true)}
      >
        <SvgPlus />
        Attach Form
      </Button>
      {showChooseFormsToAttachModal && (
        <ChooseFormsToAttachModal
          cancelAttach={setChooseFormsToAttachModal}
          setLinkedForms={handleAttachForms}
          setServiceValues={setServiceValues}
          serviceValues={serviceValues}
          setCreateFormModal={setCreateFormModal}
          createFormModal={createFormModal}
        />
      )}
    </div>
  )
}

export default ServiceLinkedForms
