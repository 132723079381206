import React, { useState, useEffect } from "react"
import AvailabilityRulesSelect from "../AvailabilityRulesSelect"
import currencyCodesData from "./currencyCodesData.json"
import Select from "react-select"
import SyncPrompt from "../SyncPrompt"
import useManageServices, { ServiceType } from "../hooks/useManageServices"
import { Button } from "../../ui/button"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu"
import { Label } from "../../ui/label"
import {
  programFrequency,
  serviceLengthOptions,
  timeTypes,
} from "../../../objects/createServiceObjects"
import { SvgPiechartOneQuarter, SvgWarning } from "../../icons"
import { Switch } from "../../ui/switch"

interface Props {
  formErrors: any
  serviceValues: any
  setServiceValues: any
  serviceType: ServiceType
  isGroupService: boolean
  availabilities: Array<any>
  handleAvailabilityRulesChange: any
  service: any
  paypalStatus: any
  connectAccountData: any
}

const RecurringForm: React.FC<Props> = ({
  formErrors,
  serviceValues,
  setServiceValues,
  serviceType,
  isGroupService,
  availabilities,
  handleAvailabilityRulesChange,
  service,
  paypalStatus,
  connectAccountData,
}) => {
  const { getAvailableTimeTypes } = useManageServices()
  const [showSyncPrompt, setShowSyncPrompt] = useState(false)
  const [currencyCodes, setCurrencyCodes] = useState<CurrencyCodes[]>([])

  const handleRecurringFrequencyChange = (e: any) => {
    let expireType = serviceValues?.expiration?.expireType
    if (e.value === "weekly") {
      expireType = "weeks"
    } else if (e.value === "monthly") {
      expireType = "months"
    }
    setServiceValues({
      ...serviceValues,
      recurring: {
        ...serviceValues?.recurring,
        recurringFrequency: e.value,
      },
      expiration: {
        ...serviceValues?.expiration,
        expireType: expireType,
      },
    })
  }

  useEffect(() => {
    const periodCostInput = document.getElementById("periodCostInput") || null
    if (periodCostInput) {
      periodCostInput.addEventListener("keydown", function (e) {
        if (e.key === "ArrowUp" || e.key === "ArrowDown") {
          e.preventDefault()
        }
      })
    }
  }, [])

  interface CurrencyCodes {
    code: string
    symbol: string
  }

  useEffect(() => {
    const data: CurrencyCodes[] = currencyCodesData.codes
    setCurrencyCodes(data)
  }, [])

  const handleCurrencySelect = (code: CurrencyCodes) => {
    setServiceValues({
      ...serviceValues,
      currency: code.code,
      symbol: code.symbol,
    })
  }

  useEffect(() => {
    if (serviceValues.currency === "") {
      const defaultCurrency = {
        code: "USD",
        symbol: "\\u0024",
      }
      setServiceValues((prevValues: typeof serviceValues) => ({
        ...prevValues,
        currency: defaultCurrency.code,
        symbol: defaultCurrency.symbol,
      }))
      handleCurrencySelect(defaultCurrency)
    }
  }, [])

  const checkStatus = () => {
    if (!paypalStatus?.data.onboarding_completed) {
      setShowSyncPrompt(true)
      return false
    }
    return true
  }

  const showSessionCost = () => {
    let showCost = false
    if (!serviceValues.isFree) {
      showCost = true
    }
    return showCost
  }

  useEffect(() => {
    if (serviceValues.isFree || serviceValues.recurring.isFree) {
      setServiceValues({
        ...serviceValues,
        recurring: {
          ...serviceValues.recurring,
          periodCost: 0.0,
        },
        allowPromo: false,
      })
    }
  }, [serviceValues.recurring.isFree])

  return (
    <>
      {serviceType === "recurring" && (
        <>
          <div
            className={`flex flex-col mb-[32px] ${
              formErrors?.recurringFrequency && "input-error"
            }`}
          >
            <h4 className="font-bold text-base mb-[8px]">
              What is the frequency of the program?
            </h4>
            <Select
              className="basic-single w-full"
              classNamePrefix="select"
              value={programFrequency.filter(
                (option) =>
                  option.value === serviceValues?.recurring.recurringFrequency
              )}
              options={programFrequency}
              menuPlacement="bottom"
              name="recurringFrequency"
              onChange={(e: any) => handleRecurringFrequencyChange(e)}
              isDisabled={service.enrolled_count >= 1}
            />
            {formErrors.recurringFrequency && (
              <div className="field-error w-full">
                <SvgWarning />
                {formErrors.recurringFrequency}
              </div>
            )}
          </div>

          {/* cost per recurring period */}

          <div
            className={`flex flex-col mb-[32px] ${
              formErrors?.periodCost && "input-error"
            }`}
          >
            {" "}
            <div className="flex flex-col mb-[20px]">
              <h4
                className={`font-bold text-base mb-[8px] ${
                  service.enrolled_count >= 1 ? "text-graySlate" : ""
                }`}
              >
                Is this a free service?
              </h4>
              <div className="flex items-center gap-2">
                <Switch
                  onCheckedChange={() => {
                    if (!checkStatus()) return
                    setServiceValues({
                      ...serviceValues,
                      isFree: !serviceValues.isFree,
                      recurring: {
                        ...serviceValues.recurring,
                        isFree: !serviceValues.recurring.isFree,
                      },
                      group: {
                        ...serviceValues.group,
                        isFree: !serviceValues.group.isFree,
                      },
                    })
                  }}
                  checked={
                    serviceValues.group.isFree &&
                    serviceValues.isFree &&
                    serviceValues.recurring.isFree &&
                    serviceValues.sessionCost === 0 &&
                    serviceValues.recurring.periodCost === 0
                  }
                  id="free"
                  disabled={service.enrolled_count >= 1}
                />
                <Label htmlFor="free">Free</Label>
              </div>
            </div>
            <div>
              {showSessionCost() && (
                <>
                  {" "}
                  <h4 className="font-bold text-base mb-[8px]">
                    {`What is the total cost per ${
                      serviceValues?.recurring?.recurringFrequency === "monthly"
                        ? "month"
                        : "week"
                    }?`}
                  </h4>
                  <div
                    className={`relative ${
                      formErrors?.sessionCost && "input-error"
                    } default-input w-full`}
                  >
                    <div className="flex">
                      <DropdownMenu>
                        <DropdownMenuTrigger asChild>
                          <Button size="icon" variant="utility">
                            {serviceValues.currency}
                          </Button>
                        </DropdownMenuTrigger>
                        <DropdownMenuContent align="end">
                          <DropdownMenuLabel className="text-graySlate text-xs p-2">
                            Currency
                          </DropdownMenuLabel>
                          <DropdownMenuGroup>
                            {currencyCodes.map((code) => (
                              <>
                                <DropdownMenuItem
                                  onClick={() => handleCurrencySelect(code)}
                                >
                                  {code.code}
                                </DropdownMenuItem>
                              </>
                            ))}
                          </DropdownMenuGroup>
                        </DropdownMenuContent>
                      </DropdownMenu>
                      <input
                        id="periodCostInput"
                        type="number"
                        onWheel={() =>
                          (document.activeElement as HTMLElement)?.blur()
                        }
                        step="0.01"
                        min="0"
                        autoComplete="off"
                        name="periodCost"
                        placeholder="Cost"
                        value={serviceValues?.recurring.periodCost}
                        onChange={(e: any) =>
                          setServiceValues({
                            ...serviceValues,
                            recurring: {
                              ...serviceValues?.recurring,
                              periodCost: parseInt(e.target.value),
                            },
                          })
                        }
                        className="ml-2 flex-1 peer border border-gray-300 py-2 focus:outline-none focus:border-blue-500"
                        disabled={service.enrolled_count >= 1}
                      />
                    </div>
                  </div>
                  {formErrors.periodCost && (
                    <div className="field-error w-full">
                      <SvgWarning />
                      {formErrors.periodCost}
                    </div>
                  )}
                </>
              )}
            </div>
          </div>
          {showSessionCost() && (
            <div className="flex flex-col mb-10">
              <h4 className="font-bold text-base mb-[8px]">
                Enable clients to use your promo codes?
              </h4>
              <div className="flex items-center gap-2">
                <Switch
                  onCheckedChange={() =>
                    setServiceValues({
                      ...serviceValues,
                      allowPromo: !serviceValues.allowPromo,
                    })
                  }
                  checked={serviceValues.allowPromo}
                  id="promo"
                />
                <Label htmlFor="promo">Enabled</Label>
              </div>
            </div>
          )}

          {/* sessions per recurring period */}

          <div className="flex flex-col mb-[32px]">
            {!(
              isGroupService &&
              serviceValues?.recurring.recurringFrequency === "monthly"
            ) && (
              <>
                <div className="flex items-center justify-between w-full mb-[8px]">
                  <h4 className="font-bold text-base">
                    {`How many sessions per 
                                            ${
                                              serviceValues?.recurring
                                                .recurringFrequency ===
                                              "monthly"
                                                ? "month?"
                                                : ""
                                            }
                                            ${
                                              serviceValues?.recurring
                                                .recurringFrequency === "weekly"
                                                ? "week?"
                                                : ""
                                            }
                                        `}
                  </h4>
                </div>
                {serviceValues?.recurring?.unlimited ? (
                  <div className={`default-input w-full`}>
                    <input disabled type="text" value="Unlimited" />
                  </div>
                ) : (
                  <>
                    <div
                      className={`${
                        formErrors?.sessionsPerPeriod && "input-error"
                      } default-input w-full`}
                    >
                      <input
                        type="number"
                        onWheel={() =>
                          (document.activeElement as HTMLElement)?.blur()
                        }
                        min="1"
                        value={serviceValues?.recurring?.sessionsPerPeriod}
                        onChange={(e: any) =>
                          setServiceValues({
                            ...serviceValues,
                            recurring: {
                              ...serviceValues.recurring,
                              sessionsPerPeriod: parseInt(e.target.value),
                            },
                          })
                        }
                        name="sessionsPerPeriod"
                        disabled={service.enrolled_count >= 1}
                      />
                      <div className="overlay">Sessions</div>
                    </div>
                    {formErrors.sessionsPerPeriod && (
                      <div className="field-error w-full">
                        <SvgWarning />
                        {formErrors.sessionsPerPeriod}
                      </div>
                    )}
                  </>
                )}
              </>
            )}
          </div>
        </>
      )}
      <h4 className="font-bold text-base mb-[16px]">
        What is the length of each session?
      </h4>
      <div
        className={`${
          formErrors?.sessionLength && "input-error"
        } flex items-center gap-[12px] mb-[32px]`}
      >
        <input
          type="number"
          onWheel={() => (document.activeElement as HTMLElement)?.blur()}
          min="0"
          max="60"
          className="w-[54px] h-[54px] bg-white focus:outline-0 border
                    border-1 border-grayCloud rounded-[10px] text-graySlate text-center"
          value={serviceValues.sessionLength.minutes}
          name="sessionMinutes"
          onChange={(e: any) =>
            setServiceValues({
              ...serviceValues,
              sessionLength: { minutes: e.target.value },
            })
          }
        />
        <span className="font-bold text-base">minutes</span>
      </div>
      {formErrors.sessionLength && (
        <div className="field-error w-[100%]">
          <SvgWarning />
          {formErrors.sessionLength}
        </div>
      )}

      {/* SELECT WITH EXISTING AVAILABILITY RULES */}
      {availabilities?.length > 0 && (
        <AvailabilityRulesSelect
          availabilities={availabilities}
          serviceType={serviceType}
          service={serviceValues}
          handleAvailabilityRulesChange={handleAvailabilityRulesChange}
        />
      )}

      <div className="w-full h-[1px] bg-grayMist my-[32px]"></div>

      {/* Expiration toggle and selector */}
      <div className="flex flex-col">
        <h4 className="font-bold text-base mb-[8px]">
          {`How long does this program last?`}
        </h4>
        <div className="flex items-center justify-between">
          <Select
            className="basic-single w-[49%]"
            classNamePrefix="select"
            options={serviceLengthOptions}
            menuPlacement="bottom"
            value={serviceLengthOptions.filter(
              (number) => number.value === serviceValues?.expiration?.expireTime
            )}
            onChange={(e: any) =>
              setServiceValues({
                ...serviceValues,
                expiration: {
                  ...serviceValues.expiration,
                  expireTime: e.value,
                },
              })
            }
          />
          <Select
            className="basic-single w-[49%]"
            classNamePrefix="select"
            options={getAvailableTimeTypes(
              serviceType,
              timeTypes,
              serviceValues
            )}
            menuPlacement="bottom"
            value={getAvailableTimeTypes(
              serviceType,
              timeTypes,
              serviceValues
            ).filter(
              (type: any) =>
                type.value === serviceValues?.expiration?.expireType
            )}
            onChange={(e: any) =>
              setServiceValues({
                ...serviceValues,
                expiration: {
                  ...serviceValues.expiration,
                  expireType: e.value,
                },
              })
            }
          />
        </div>
        {formErrors.expires && (
          <div className="field-error w-full">
            <SvgWarning />
            {formErrors.expires}
          </div>
        )}
      </div>

      <div className="w-full h-px bg-grayMist my-[32px]"></div>
      {showSessionCost() && (
        <>
          <h4 className="font-bold text-base mb-[8px]">Preview</h4>
          <div className="w-full border rounded-xl p-[20px] flex flex-col">
            <h5 className="text-graySlate text-base mb-[32px]">
              Payment Details
            </h5>
            <div className="flex w-full items-center justify-between mb-[16px]">
              <div className="flex items-center gap-[16px] min-w-fit">
                <SvgPiechartOneQuarter />
                <p className="text-[16px] whitespace-nowrap">
                  Payment at booking
                </p>
              </div>
              <div className="mx-[24px] w-full border-b border-grayCloud transform -translate-y-1 border-dashed h-2"></div>
              <span className="text-base">
                {serviceValues.symbol &&
                  String.fromCharCode(
                    parseInt(serviceValues.symbol.slice(2), 16)
                  )}
                {parseInt(serviceValues?.recurring?.periodCost)?.toFixed(2)}
              </span>
            </div>
            <div className="flex w-full items-center justify-between mb-[16px]">
              <div className="flex items-center gap-[16px] min-w-fit">
                <SvgPiechartOneQuarter />
                <p className="text-[16px] whitespace-nowrap">
                  {serviceValues?.recurring.recurringFrequency === "weekly"
                    ? "Weekly"
                    : "Monthly"}{" "}
                  recurring charge
                </p>
              </div>
              <div className="mx-[24px] w-full border-b border-grayCloud transform -translate-y-1 border-dashed h-2"></div>
              <span className="text-base">
                {serviceValues.symbol &&
                  String.fromCharCode(
                    parseInt(serviceValues.symbol.slice(2), 16)
                  )}
                {parseInt(serviceValues?.recurring?.periodCost)?.toFixed(2)}
              </span>
            </div>
            <div className="text-graySlate w-full bg-grayMist rounded-lg p-[26px] flex items-center justify-center text-base">
              <p className="text-center">
                Payments will be charged{" "}
                <strong className="text-black">
                  {serviceValues?.recurring?.recurringFrequency}
                </strong>{" "}
                on the anniversary of the first payment.
              </p>
            </div>
          </div>
        </>
      )}
      {showSyncPrompt && (
        <SyncPrompt
          setSyncPrompt={setShowSyncPrompt}
          connectAccountData={connectAccountData}
          service={service}
        />
      )}
    </>
  )
}

export default RecurringForm
