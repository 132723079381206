import React, { FC, useContext, useState, useEffect } from "react"
import { NotificationRequests } from "../../api/app.service"
import { UserCtx } from "../../context/userContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import NotificationsPageItem from "../../components/notifications/NotificationsPageItem"
import moment from "moment"

interface Props {}

const Notifications: FC<Props> = () => {
  const { user } = useContext(UserCtx)
  const { renderError } = useContext(CommonFunctionCtx)
  const [notifications, setNotifications] = useState<any[]>([])

  const markRead = (notification_id: number) => {
    NotificationRequests.markRead({
      profile_id: user.activeProfileId,
      notification_ids: [notification_id],
    })
      .then((data) => {
        // Set as viewed locally so that user sees notification as viewed without refetching data
        const updatedNotifications = notifications.map((n) => {
          if (n.id === notification_id) {
            n.viewed = true
          }
          return n
        })
        setNotifications(updatedNotifications)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  const markUnread = (notification_id: number) => {
    NotificationRequests.markUnread({
      profile_id: user.activeProfileId,
      notification_ids: [notification_id],
    })
      .then((data) => {
        // Set as viewed locally so that user sees notification as viewed without refetching data
        const updatedNotifications = notifications.map((n) => {
          if (n.id === notification_id) {
            n.viewed = 0
          }
          return n
        })
        setNotifications(updatedNotifications)
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
  }

  const getNotifications = () => {
    if (user) {
      NotificationRequests.getNotifications({
        profile_id: user.activeProfileId,
      })
        .then((data) => {
          setNotifications(data.notifications)
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
    }
  }

  useEffect(() => {
    getNotifications()
  }, [])

  const sortedNotifications = notifications.sort(
    (a, b) =>
      moment.utc(b.created_at).toDate().getTime() -
      moment.utc(a.created_at).toDate().getTime()
  )

  return (
    <div className="w-full max-w-[1300px] px-[10px] mx-auto pb-[80px]">
      <h1 className="hidden md:block font-bold text-[36px] mb-[24px]">
        Notifications
      </h1>
      <div className="hidden md:block w-[100%] h-[1px] bg-gray mb-[24px]"></div>
      <div className="mt-[40px] max-w-[744px] mx-auto">
        {notifications.length > 0 ? (
          <>
            {sortedNotifications.map((n) => {
              return (
                <NotificationsPageItem
                  key={n.id}
                  notification={n}
                  markRead={markRead}
                  markUnread={markUnread}
                />
              )
            })}
          </>
        ) : (
          <h3 className="font-bold">
            You do not currently have any notifications. Check back later!
          </h3>
        )}
      </div>
    </div>
  )
}

export default Notifications
