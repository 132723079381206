import * as React from "react"
import CustomDayPicker from "../date-time/CustomDayPicker"
import useLoading from "../../hooks/useLoading"
import UserEmailCheckModal from "./UserEmailCheckModal"
import { add } from "date-fns"
import { CoachPrivateProfileContext } from "../../pages/manage-business/profile/context/coachPrivateProfileContext"
import { CoachPublicProfileContext } from "../../pages/manage-business/profile/context/coachPublicProfileContext"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ContactRequests } from "../../api/app.service"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { isCoachInCommissionTracking } from "../../utils/commissionTracking"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import { useNavigate } from "react-router-dom"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { UserCtx } from "../../context/userContext"

interface Props {
  consultation?: any
  disabled?: boolean
}

const PrivateProfileScheduler: React.FC<Props> = ({
  consultation,
  disabled = false,
}) => {
  const { user, setUser } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const [userEmailCheck, setUserEmailCheck] = React.useState<boolean>(false)
  const [activeDate, setActiveDate] = React.useState<Date>(new Date())
  const { isOwner } = React.useContext(CoachPublicProfileContext)
  const { coachPublicProfileData } = React.useContext(
    CoachPrivateProfileContext
  )
  const { renderError } = React.useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [startTime, setStartTime] = React.useState<Date | null>(null)
  const [endTime, setEndTime] = React.useState<Date | null>(null)
  const authenticatedUser = useAuthenticatedUser(user)
  const textColor = getLightTextColor(
    coachPublicProfileData?.profile_information?.profileColor
  )

  const dayColor = coachPublicProfileData?.profile_information?.profileColor

  const durationDefault = 60
  const duration = consultation?.service_details?.session?.duration
    ? parseInt(consultation?.service_details?.session?.duration) / 60
    : 60

  const handleClientSwitch = async () => {
    startLoading()
    const userObject = {
      ...user,
    }
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject))
    }
    setUser(userObject)
  }

  const connectAndRedirect = () => {
    ContactRequests.createContactConnection({
      sender_profile_id: coachPublicProfileData?.coach_profile_id,
      receiver_profile_id: user.activeProfileId,
      user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      status: isCoachInCommissionTracking(coachPublicProfileData?.endpoint_slug)
        ? "zoee lead"
        : "connected",
    })
      .then(() => {
        navigate(
          `/member/contacts/${coachPublicProfileData?.coach_profile_id}/services/${consultation?.service_id}`,
          { state: {} }
        )
      })
      .catch((ex) => {
        console.error(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const goToEnroll = (actionType: string, emailObject: any) => {
    const forceMemberLogin = actionType === "login"
    const forceMemberSignup = actionType === "signup"
    localStorage.setItem(
      "meetingToSchedule",
      JSON.stringify({ startTime: startTime, endTime: endTime })
    )
    if (isOwner) {
      return renderError(
        "Can't take action on profile page when logged into this account"
      )
    }
    if (authenticatedUser) {
      handleClientSwitch().then(() => {
        connectAndRedirect()
      })
    } else {
      navigate(`/direct-routes`, {
        state: {
          storageAvailable: true,
          pathname: `/member/contacts/${coachPublicProfileData?.coach_profile_id}/services/${consultation?.service_id}`,
          searchParams: {},
          actions: {
            createConnection: {
              coachProfileId: coachPublicProfileData?.coach_profile_id,
            },
            scheduleMeeting: {
              startTime: startTime,
              endTime: endTime,
            },
            forceMemberSignup: forceMemberSignup,
            forceMemberLogin: forceMemberLogin,
            emailObject: emailObject,
            memberShortForm: true,
            coachName: `${coachPublicProfileData?.first_name} ${coachPublicProfileData?.last_name}`,
          },
        },
      })
    }
  }

  React.useEffect(() => {
    if (startTime) {
      const end = add(startTime, {
        minutes: duration || durationDefault,
      })
      setEndTime(end)
    }
  }, [startTime])

  return (
    <>
      {userEmailCheck && (
        <UserEmailCheckModal
          setUserEmailCheck={setUserEmailCheck}
          goToEnroll={goToEnroll}
        />
      )}
      <div
        className={`main flex h-full max-h-[601px] min-w-[299px] flex-col rounded-[20px] bg-white shadow md:max-w-[300px]
                    ${disabled ? "pointer-events-none opacity-25" : ""}`}
      >
        <div
          className="flex h-[20px] items-center justify-center rounded-t-[16px] px-[24px]
                    text-[16px] font-bold"
          style={{
            backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
            color: textColor,
          }}
        ></div>
        <div className={`px-[24px] py-[16px] sm:py-[5px]`}>
          <h1 className="-mb-[5px] pt-[7px] text-[16px] font-bold">
            Schedule a Consultation
          </h1>
          <h1
            className={`mb-4 font-bold break-words ${
              consultation?.service_details?.title?.length > 100
                ? "text-[16px]"
                : "text-[22px]"
            }`}
            style={{
              color: `${coachPublicProfileData?.profile_information?.profileColor}`,
            }}
          >
            {consultation?.service_details?.title}
          </h1>
          <div className="mb-[12px] flex w-full items-center justify-between text-[16px] font-bold">
            <CustomDayPicker
              setActiveDate={setActiveDate}
              disablePastDates={true}
              dayColor={dayColor}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default PrivateProfileScheduler
