import * as React from "react"
import * as Dialog from "@radix-ui/react-dialog"
import ConfirmContactInvite from "./ConfirmContactInvite"
import CoachDirectoryInvite from "./CoachDirectoryInvite"
import { AuthContext } from "../auth/context"
import { Avatar, AvatarFallback, AvatarImage } from "../ui/avatar"
import { Button } from "../ui/button"
import { CoachCard } from "../../models/public.interface"
import { getUserColor } from "../../utils/getUserColor"
import { RegisterFormProvider } from "../auth/register-form/context"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

interface CoachDirectoryProps extends CoachCard {
  setInvites: (invites: any) => void
  setShowConfirmInviteModal: (show: boolean) => void
  setSelectedContact: (contact: CoachCard) => void
  setShowContactProfile: (show: boolean) => void
  setContactRequestFull: (full: boolean) => void
  acceptContactRequest: (request: any) => void
  shouldApplyMargin: any
  contacts: any
  coaches: CoachCard[]
  email: string
  contact: any
}

const CoachDirectory = ({
  coach_profile_id,
  last_name,
  first_name,
  description,
  avatar_url,
  shouldApplyMargin,
  email,
  contacts,
  contact,
}: CoachDirectoryProps) => {
  const [coach, setCoach] = React.useState<any>(null)
  const [showConfirmInviteModal, setShowConfirmInviteModal] =
    React.useState(false)
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const [invites, setInvites] = React.useState([{ name: "", email: "" }])
  const [isHovered, setIsHovered] = React.useState(false)

  const { user_color } = contact

  const { firstName, lastName, accountType, password, phone } =
    React.useContext(AuthContext)

  const handleMouseEnter = () => {
    if (!showConfirmInviteModal) {
      setIsHovered(true)
    }
  }

  const handleMouseLeave = () => {
    if (!showConfirmInviteModal) {
      setIsHovered(false)
    }
  }

  const handleInvite = (coach: any) => {
    setInvites([{ name: "", email: email }])
    setShowConfirmInviteModal(true)
  }

  const handleCoachInvite = (coach: any) => {
    handleInvite(coach)
  }

  const shouldHideCoachCard = (): boolean => {
    return user && user.activeProfileId
      ? coach_profile_id === user.activeProfileId
      : false
  }

  const cardClassName = shouldHideCoachCard() ? "hidden" : ""

  // disablement from having coach as a contact already
  const isCoachDisabledByContactID = (coachId: number): boolean => {
    const result = contacts.some((contact: any) => {
      return contact.profile_id === coachId
    })
    return result
  }

  // disablement from user having sent an invite to a coach that has yet to accept
  const isCoachDisabledByEmail = (coachEmail: string): boolean => {
    const result = contacts.some((contact: any) => {
      return contact.contact_name === coachEmail
    })
    return result
  }

  // disablement from user having received an invite from a coach that has yet to be accept
  const isCoachDisabledByName = (coachId: number): boolean => {
    const result = contacts.some((contacts: any) => {
      return contacts.request_by_profile_id === coachId
    })
    return result
  }

  return (
    <div className={`coach-card ${cardClassName}`}>
      <RegisterFormProvider
        defaultEmail={email}
        defaultAccountType={accountType}
        onSuccess={() => true}
        defaultPhone={phone}
        defaultFirstName={firstName}
        defaultLastName={lastName}
        defaultPassword={password}
      >
        <Dialog.Root
          open={showConfirmInviteModal}
          onOpenChange={setShowConfirmInviteModal}
        >
          <div
            className={`md:w-[258px] w-[184px] md:h-[305px] h-[213px] promotion-shadow flex flex-col 
          items-center rounded-[16px] relative cursor-pointer hover:transform hover:scale-105 transition-transform duration-300 shadow-custom ${
            shouldApplyMargin ? "mb-[2px]" : ""
          } ${isHovered ? "bg-royalBlue" : ""}`}
            onMouseEnter={handleMouseEnter}
            onMouseLeave={handleMouseLeave}
          >
            {/* Avatar Container */}
            <div
              className="absolute top-[4px] left-[50%] -translate-x-[50%]
                md:w-[250px] w-[178px] md:h-[78px] h-[69px] bg-grayFlash rounded-[12px]"
            >
              <div className="absolute top-[52px] left-[50%] -translate-x-[50%]">
                <div className="hidden md:block">
                  <Avatar className="h-[80px] w-[80px] rounded-full mx-auto">
                    <AvatarImage
                      alt={`${first_name} ${last_name} profile picture`}
                      src={`${avatar_url}?${new Date().getTime()}`}
                      className="w-20 h-20"
                    />
                    <AvatarFallback
                      className={`${getUserColor(
                        user.userColor
                      )} text-[22px] font-bold w-20 h-20`}
                    >
                      {" "}
                      {first_name.charAt(0).toUpperCase()}
                      {last_name.charAt(0).toUpperCase()}
                    </AvatarFallback>
                  </Avatar>
                  {/* Name Section */}
                  <div className="text-center mt-6">
                    <h2
                      className={`font-bold w-full text-center truncate px-[2px] ${
                        isHovered ? "text-white" : "text-graySlate"
                      }`}
                    >
                      {`${first_name} ${last_name}`}
                    </h2>
                  </div>
                </div>
              </div>
            </div>

            {/* Content Below the Avatar */}
            <div className="flex flex-1 flex-col justify-between gap-3 p-4 w-full">
              <p className="relative mb-2 line-clamp-3 flex-1 leading-relaxed">
                {description}
              </p>

              <div className="sm:grid-cols-2 w-[100%]">
                <div className="sm:grid-cols-2 w-[100%]">
                  <Button
                    variant={
                      isCoachDisabledByEmail(email) ||
                      isCoachDisabledByContactID(coach_profile_id) ||
                      isCoachDisabledByName(coach_profile_id)
                        ? "outline"
                        : isHovered
                        ? "utility"
                        : "default"
                    }
                    className="w-full"
                    onClick={() => handleCoachInvite(coach)}
                    disabled={
                      isCoachDisabledByEmail(email) ||
                      isCoachDisabledByContactID(coach_profile_id) ||
                      isCoachDisabledByName(coach_profile_id)
                    }
                  >
                    {isCoachDisabledByEmail(email) ||
                    isCoachDisabledByName(coach_profile_id)
                      ? "Pending"
                      : isCoachDisabledByContactID(coach_profile_id)
                      ? "Connected"
                      : "Connect"}
                  </Button>
                </div>
                <Dialog.Portal>
                  <Dialog.Overlay
                    className="fixed inset-0 bg-black opacity-50"
                    onClick={() => setShowConfirmInviteModal(false)}
                  />
                  <Dialog.Content className="fixed inset-0 flex items-center justify-center">
                    <div className="bg-white rounded-lg p-6 shadow-lg z-50">
                      <CoachDirectoryInvite
                        setShowConfirmInviteModal={setShowConfirmInviteModal}
                        invites={invites}
                        setInvites={setInvites}
                        // getContacts={{}}
                        contacts={contacts}
                      />
                    </div>
                  </Dialog.Content>
                </Dialog.Portal>
              </div>
            </div>
          </div>
        </Dialog.Root>
      </RegisterFormProvider>
    </div>
  )
}

export default CoachDirectory
