import React, { FC, useState, useEffect, useContext } from "react"
import { User } from "../../../api/auth.service"
import {
  UserAccounts,
  ContactRequests,
  NotificationRequests,
  ReferralRequests,
} from "../../../api/app.service"
import { CommonFunctionCtx } from "../../../context/commonFunctionContext"
import moment from "moment-timezone"
import PhoneInput from "react-phone-input-2"
import "react-phone-input-2/lib/style.css"
import { motion, AnimatePresence } from "framer-motion"
import { useNavigate } from "react-router-dom"
import useScrollToTop from "../../../hooks/useScrollToTop"
import useLoading from "../../../hooks/useLoading"
import { SvgCheck, SvgWarning } from "../../icons"
import { isLocalStorageAvailable } from "../../../utils/isLocalStorageAvailable"

type Props = {
  referrerPromoCodeQueryParam: string | null
  personalizeYourPageValues: any
  variation: string
}

const LandingSignupForm: FC<Props> = ({
  referrerPromoCodeQueryParam,
  personalizeYourPageValues,
  variation,
}) => {
  const { hideAlert, renderError, getUser } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [termsChecked, setTermsChecked] = useState<boolean>(true)
  const [confirmPassword, setConfirmPassword] = useState<string>("")
  const [validPasswordChars, setValidPasswordChars] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<any>({})
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [values, setValues] = useState<any>({
    first_name: "",
    last_name: "",
    email: "",
    phone_number: "",
    password: "",
    user_type: "coach",
    referral_code: "",
    email_newsletter: false,
    terms_and_conditions: termsChecked,
    connection_id: null,
    timezone: null,
  })
  const [referrerPromoCode, setReferrerPromoCode] = React.useState<
    string | null
  >(null)
  const navigate = useNavigate()

  const validateForm = (values: any) => {
    const errors: any = {}
    const mailformat = /^\w+([.-]?\w+)*@\w+([.-]?\w+)*(\.\w{2,10})+$/
    !values.first_name && (errors.first_name = "First name is required")
    !values.last_name && (errors.last_name = "Last name is required")
    ;(!values.email || !values.email.match(mailformat)) &&
      (errors.email = "Valid email is required")
    ;(!values.phone_number ||
      values.phone_number.length < 10 ||
      values.phone_number.includes("_")) &&
      (errors.phone_number = "10 digit phone number is required")
    !values.password && (errors.password = "Password is required")
    !validPasswordChars &&
      (errors.password = "Password does not meet complexity requirements")
    ;(!confirmPassword || confirmPassword !== values.password) &&
      (errors.confirmPassword = "Confirm password must be the same as password")
    termsChecked === false &&
      (errors.termsChecked = "Please agree to terms and conditions")
    return errors
  }

  const handlePasswordTypeToggle = () => {
    const field = document.getElementById("createPassword") as HTMLInputElement
    const showHideButtonText = document.querySelector(
      ".show-hide-button-1"
    ) as HTMLBodyElement

    if (field) {
      if (field.type === "password") {
        field.type = "text"
        showHideButtonText.innerHTML = "HIDE"
      } else {
        field.type = "password"
        showHideButtonText.innerHTML = "SHOW"
      }
    }
  }

  const handleConfirmPasswordTypeToggle = () => {
    const field = document.getElementById("confirmPassword") as HTMLInputElement
    const showHideButtonText = document.querySelector(
      ".show-hide-button-2"
    ) as HTMLBodyElement

    if (field) {
      if (field.type === "password") {
        field.type = "text"
        showHideButtonText.innerHTML = "HIDE"
      } else {
        field.type = "password"
        showHideButtonText.innerHTML = "SHOW"
      }
    }
  }

  const validateCharacters = (target: any) => {
    var passwordString = target.value
    if (
      /[a-z]/.test(passwordString) &&
      /[A-Z]/.test(passwordString) &&
      passwordString.length > 7 &&
      /[0-9]/.test(passwordString) &&
      /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/.test(passwordString)
    ) {
      setValidPasswordChars(true)
    } else {
      setValidPasswordChars(false)
    }
  }

  const handleChange = (e: React.ChangeEvent<EventTarget>) => {
    let target = e.target as HTMLInputElement
    setValues({ ...values, [target.name]: target.value })
    target.name === "password" && validateCharacters(target)
  }

  const handlePhoneChange = (number: string) => {
    setValues({ ...values, phone_number: number })
  }

  const handleTermsClick = () => {
    setTermsChecked(!termsChecked)
    setValues({ ...values, terms_and_conditions: !termsChecked })
  }

  const connectCoachToNichole = (data: any) => {
    startLoading("Setting up your contacts...")

    const userEnvironment = process.env.REACT_APP_USER_ENVIRONMENT || ""
    ContactRequests.coachAutoConnect({
      coach_profile_id: parseInt(data.coach_profile_id),
      user_environment: userEnvironment,
    })
      .then(() => {
        getUser()
      })
      .then(() => {
        navigate("/coach")
      })
      .catch((ex: { response: any }) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const createNotificationSettings = (data: any) => {
    startLoading("Setting up your notifications...")
    const profiles = []
    if (data.coach_profile_id && data.coach_profile_id !== null) {
      profiles.push({ id: data.coach_profile_id, type: "coach" })
    }
    if (data.member_profile_id && data.member_profile_id !== null) {
      profiles.push({ id: data.member_profile_id, type: "member" })
    }

    NotificationRequests.createNotificationSettings({
      profiles: profiles,
    })
      .then(() => {
        if (values?.user_type === "coach") {
          connectCoachToNichole(data)
        } else {
          getUser().then(() => navigate("/coach"))
        }
      })
      .catch((ex: { response: any }) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const timezones = [
    "America/St_Johns",
    "America/Halifax",
    "America/Glace_Bay",
    "America/Moncton",
    "America/Goose_Bay",
    "America/Blanc-Sablon",
    "America/Toronto",
    "America/Nipigon",
    "America/Thunder_Bay",
    "America/Iqaluit",
    "America/Pangnirtung",
    "America/Atikokan",
    "America/Winnipeg",
    "America/Rainy_River",
    "America/Resolute",
    "America/Rankin_Inlet",
    "America/Regina",
    "America/Swift_Current",
    "America/Edmonton",
    "America/Cambridge_Bay",
    "America/Yellowknife",
    "America/Inuvik",
    "America/Creston",
    "America/Dawson_Creek",
    "America/Fort_Nelson",
    "America/Vancouver",
    "America/Whitehorse",
    "America/Mexico_City",
    "America/Cancun",
    "America/Merida",
    "America/Monterrey",
    "America/Matamoros",
    "America/Mazatlan",
    "America/Chihuahua",
    "America/New_York",
    "America/Detroit",
    "America/Kentucky/Louisville",
    "America/Kentucky/Monticello",
    "America/Indiana/Indianapolis",
    "America/Indiana/Vincennes",
    "America/Indiana/Winamac",
    "America/Indiana/Marengo",
    "America/Indiana/Petersburg",
    "America/Indiana/Vevay",
    "America/Chicago",
    "America/Indiana/Tell_City",
    "America/Indiana/Knox",
    "America/Menominee",
    "America/North_Dakota/Center",
    "America/North_Dakota/New_Salem",
    "America/North_Dakota/Beulah",
    "America/Denver",
    "America/Boise",
    "America/Phoenix",
    "America/Los_Angeles",
    "America/Anchorage",
    "America/Juneau",
    "America/Sitka",
    "America/Metlakatla",
    "America/Yakutat",
    "America/Nome",
    "America/Adak",
    "Europe/London",
  ]

  const timezoneMatch = () => {
    // let match = false;
    // if (timezones.includes(moment.tz.guess(true))) {
    //     match = true;
    // }
    return true
  }

  const getButtonBackground = () => {
    switch (variation) {
      case "normal":
        return "#4750F5"
      case "moneyflow":
        return "#FCB900"
      default:
        break
    }
  }

  const createStreamUser = (data: any) => {
    startLoading("Setting up your inbox...")

    UserAccounts.createStreamUser({
      coach_profile_id: data.coach_profile_id,
      member_profile_id: data.member_profile_id,
      is_coach: data.is_coach,
      first_name: values?.first_name,
      last_name: values?.last_name,
    }).catch((ex: { response: any }) => {
      console.log(ex)
      renderError(ex.response.data.message)
      stopLoading()
    })
  }

  const createReferral = (data: any, promoCode: string) => {
    ReferralRequests.createReferralOnCreateAccount({
      user_id: parseInt(data.user_id),
      email: values.email.toLowerCase(),
      promo_code: promoCode,
    })
      .then((data) => {
      })
      .catch((ex: { response: any }) => {
        console.error(ex)
      })
  }

  const createAccount = async () => {
    startLoading("Creating your account...")
    hideAlert()
    User.createUser({
      ...values,
      email: values.email.toLowerCase(),
      phone_number: values.phone_number,
    })
      .then((data: any) => {
        if (data.access_token) {
          setIsSubmit(false)
          if (isLocalStorageAvailable()) {
            localStorage.setItem("user", JSON.stringify(data))
          }
          createStreamUser(data)
          if (
            referrerPromoCode &&
            referrerPromoCode !== "RADIANT20" &&
            referrerPromoCode !== "ICFMEMBERS50"
          ) {
            console.log("reffererPromoCode", referrerPromoCode)
            createReferral(data, referrerPromoCode)
          }
        }
      })
      .catch((ex: { response: any }) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  useEffect(() => {
    if (isSubmit && Object.keys(formErrors).length === 0) {
      if (timezoneMatch()) {
        createAccount()
      } else {
        renderError(
          "Issue initiating sign up. Please contact support for help."
        )
        setIsSubmit(false)
        stopLoading()
      }
    } else {
      setIsSubmit(false)
      stopLoading()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [formErrors])

  useEffect(() => {
    // get user timezone via browser API and set by default on form load
    setValues({
      ...values,
      timezone: { value: moment.tz.guess(true) },
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    setFormErrors(validateForm(values))
    setIsSubmit(true)
  }

  React.useEffect(() => {
    const localStoragePromoCode = localStorage.getItem("referrerPromoCode")
    if (referrerPromoCodeQueryParam) {
      setReferrerPromoCode(referrerPromoCodeQueryParam)
    } else if (localStoragePromoCode) {
      setReferrerPromoCode(localStoragePromoCode)
    }
  }, [referrerPromoCodeQueryParam])

  useScrollToTop()

  return (
    <div className="flex flex-col">
      <div className="-translate-y-[32px] h-fit flex flex-col bg-white px-[16px] md:px-[32px] py-[32px] rounded-[16px]">
        <div className="flex flex-col text-center gap-[0px] mb-[14px]">
          <h3 className="text-[24px] w-full font-bold">
            Get started in 30 seconds.
          </h3>
          {personalizeYourPageValues?.promo_code ? (
            <p className="text-blurple text-base md:text-[16px] font-semibold max-w-[80%] mx-auto text-center">
              When you join Zoee, you'll receive $50 off courtesy of Coach{" "}
              {personalizeYourPageValues?.first_name}
            </p>
          ) : (
            <p className="text-graySlate text-[16px] font-semibold">
              Free for 14 days
            </p>
          )}
        </div>
        {/* FORM */}
        <form
          action="#"
          onSubmit={handleSubmit}
          id="coachCreateForm"
          className="scroll-m-[240px]"
        >
          <div className="flex flex-col gap-[8px] mb-[8px]">
            <div className="flex flex-col gap-[2px]">
              <input
                type="text"
                placeholder="Email"
                name="email"
                onChange={handleChange}
                autoComplete="off"
                id="createEmailInput"
                value={values.email}
                className="w-full h-[56px] border border-grayCloud rounded-[10px] px-[20px] py-[16px]"
              />
              {formErrors.email && (
                <div className="flex items-center text-base gap-[4px] font-semibold">
                  <SvgWarning className="h-[18px] w-[18px]" />
                  {formErrors.email}
                </div>
              )}
            </div>

            <div className="flex items-center gap-[8px]">
              <div className="flex flex-col gap-[2px] w-full">
                <input
                  type="text"
                  placeholder="First Name"
                  name="first_name"
                  onChange={handleChange}
                  autoComplete="off"
                  className="w-full h-[56px] border border-grayCloud rounded-[10px] px-[20px] py-[16px]"
                />
                {formErrors.first_name && (
                  <div className="flex items-center text-base gap-[4px] font-semibold">
                    <SvgWarning className="h-[18px] w-[18px]" />
                    {formErrors.first_name}
                  </div>
                )}
              </div>
              <div className="flex flex-col gap-[2px] w-full">
                <input
                  type="text"
                  placeholder="Last Name"
                  name="last_name"
                  onChange={handleChange}
                  autoComplete="off"
                  className="w-full h-[56px] border border-grayCloud rounded-[10px] px-[20px] py-[16px]"
                />
                {formErrors.last_name && (
                  <div className="flex items-center text-base gap-[4px] font-semibold">
                    <SvgWarning className="h-[18px] w-[18px]" />
                    {formErrors.last_name}
                  </div>
                )}
              </div>
            </div>
            <div className="flex flex-col gap-[2px]">
              <PhoneInput
                placeholder="Enter your mobile number"
                country="us"
                onChange={handlePhoneChange}
                value={values.phone_number}
                containerClass={`default-input w-full gap-[2px]`}
                inputClass={`w-full h-full rounded-lg focus:py-0`}
                inputProps={{ enableSearch: true }}
              ></PhoneInput>
              {formErrors.phone_number && (
                <div className="flex items-center text-base gap-[4px] font-semibold">
                  <SvgWarning className="h-[18px] w-[18px]" />
                  {formErrors.phone_number}
                </div>
              )}
            </div>
            <div className="flex flex-col gap-[2px]">
              <div className="relative">
                <input
                  type="password"
                  placeholder="Enter your password"
                  id="createPassword"
                  name="password"
                  onChange={handleChange}
                  className="w-full h-[56px] border border-grayCloud rounded-[10px] px-[20px] py-[16px]"
                />
                <div
                  className="show-hide-button show-hide-button-1"
                  onClick={handlePasswordTypeToggle}
                >
                  SHOW
                </div>
              </div>
              {formErrors.password && (
                <div className="flex items-center text-base gap-[4px] font-semibold">
                  <SvgWarning className="h-[18px] w-[18px]" />
                  {formErrors.password}
                </div>
              )}
            </div>
            <AnimatePresence>
              {values.password && (
                <motion.div
                  initial={{ opacity: 0, height: 0 }}
                  animate={{ opacity: 1, height: "auto", marginBottom: "12px" }}
                  exit={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
                  className=" flex flex-col gap-[2px]"
                >
                  <div className="relative">
                    <input
                      type="password"
                      placeholder="Confirm your password"
                      id="confirmPassword"
                      name="confirmPassword"
                      onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                        setConfirmPassword(e.target.value)
                      }}
                      className="w-full h-[56px] border border-grayCloud rounded-[10px] px-[20px] py-[16px]"
                    />
                    <div
                      className="show-hide-button show-hide-button-2"
                      onClick={handleConfirmPasswordTypeToggle}
                    >
                      SHOW
                    </div>
                  </div>
                  {formErrors.confirmPassword && (
                    <div className="flex items-center text-base gap-[4px] font-semibold">
                      <SvgWarning className="h-[18px] w-[18px]" />
                      {formErrors.confirmPassword}
                    </div>
                  )}
                </motion.div>
              )}
            </AnimatePresence>
          </div>
          <AnimatePresence>
            {values.password && (
              <motion.div
                initial={{ opacity: 0, height: 0 }}
                animate={{ opacity: 1, height: "auto" }}
                exit={{ opacity: 0, height: 0, margin: 0, padding: 0 }}
                className="flex flex-col gap-[2px]"
              >
                <div
                  className="flex items-center justify-start w-full mb-[12px]"
                  id="lengthVerifiedEl"
                >
                  {values.password.length >= 8 ? (
                    <SvgCheck className={`mr-[15px]`} stroke="#00D084" />
                  ) : (
                    <SvgCheck className={`mr-[15px]`} stroke="#7E8793" />
                  )}
                  <div
                    className={`caption3 ${
                      values.password.length >= 8 && "text-mainBlack"
                    }`}
                  >
                    Use at least 8 characters
                  </div>
                </div>
                <div className="flex items-center justify-start w-full  mb-[12px]">
                  {validPasswordChars ? (
                    <SvgCheck className={`mr-[15px]`} stroke="#00D084" />
                  ) : (
                    <SvgCheck className={`mr-[15px]`} stroke="#7E8793" />
                  )}
                  <div
                    className={`caption3 max-w-[370px] ${
                      validPasswordChars && "text-mainBlack"
                    }`}
                  >
                    Use a mix of 8 uppercase, lowercase, numbers, and special
                    characters (ex: #, $, !)
                  </div>
                </div>
              </motion.div>
            )}
          </AnimatePresence>
          <div className="w-full flex flex-col items-center gap-[2px] mb-[18px]">
            <div className="flex items-center justify-center gap-[0px]">
              <div
                className={`check-box ${termsChecked && "check-box-checked"}`}
                onClick={handleTermsClick}
                data-cy="termsCheckbox"
              >
                <SvgCheck />
              </div>
              <p className="text-base">
                I agree with{" "}
                <a
                  href="/terms-and-conditions"
                  target="_blank"
                  className="text-blurple cursor-pointer hover:underline"
                >
                  Terms & Conditions
                </a>{" "}
                and{" "}
                <a
                  href="/privacy-policy"
                  target="_blank"
                  className="text-blurple cursor-pointer hover:underline"
                >
                  Privacy Policy
                </a>
              </p>
            </div>
            {formErrors.termsChecked && (
              <div className="flex items-center text-base gap-[4px] font-semibold">
                <SvgWarning className="h-[18px] w-[18px]" />
                {formErrors.termsChecked}
              </div>
            )}
          </div>
          <input
            type="submit"
            className="cursor-pointer w-full text-white font-semibold text-base px-[49px] py-[13px] flex items-center justify-center rounded-[100px]"
            value={"Start free trial now"}
            style={{ backgroundColor: getButtonBackground() }}
          />
          <p className="w-full flex justify-center text-graySlate text-[16px] font-semibold mt-[20px]">
            No credit card required
          </p>
        </form>
      </div>
      {personalizeYourPageValues.promo_code && (
        <p className="text-center mx-auto text-graySlate text-base max-w-[356px] md:max-w-[409px]">
          Disclaimer:{" "}
          {personalizeYourPageValues.name ||
            `${personalizeYourPageValues.first_name} ${personalizeYourPageValues.last_name}`}{" "}
          is a participant in the SoleLife, Inc d/b/a Zoee partner program. If
          you choose to purchase after clicking a link,{" "}
          {personalizeYourPageValues.name ||
            `${personalizeYourPageValues.first_name} ${personalizeYourPageValues.last_name}`}{" "}
          may receive a commission at no extra cost to you.
        </p>
      )}
    </div>
  )
}

export default LandingSignupForm
