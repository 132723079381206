import React from "react";
import useDragAndDrop from "../hooks/useDragDrop";
import { SvgImage, SvgCheckCircle } from "./icons";
import { CommonFunctionCtx } from "../context/commonFunctionContext";

interface Props {
  styling: string;
  mediaFile: any;
  setMediaFile: any;
  mediaType: any;
}

const DragDropArea: React.FC<Props> = ({
  styling,
  mediaFile,
  setMediaFile,
  mediaType,
}) => {
  const { renderError } = React.useContext(CommonFunctionCtx);
  const { dragOver, setDragOver, onDragOver, onDragLeave } = useDragAndDrop();

  const onDropImage = (e: React.DragEvent<HTMLLabelElement>) => {
    e.preventDefault();
    setDragOver(false);
    const selectedFile = e?.dataTransfer?.files[0];
    if (mediaType === "image" && selectedFile.type?.split("/")[0] !== "image") {
      return renderError("Please provide an image file to upload!");
    }
    if (mediaType === "video" && selectedFile.type?.split("/")[0] !== "video") {
      return renderError("Please provide a video file to upload!");
    }
    setMediaFile(selectedFile);
  };

  const fileSelectServiceImage = (e: React.ChangeEvent<HTMLInputElement>) => {
    if (e.target.files) {
      let selectedFile = e?.target?.files[0];
      if (
        mediaType === "image" &&
        selectedFile.type.split("/")[0] !== "image"
      ) {
        return renderError("Please provide an image file to upload!");
      }
      if (
        mediaType === "video" &&
        selectedFile.type.split("/")[0] !== "video"
      ) {
        return renderError("Please provide a video file to upload!");
      }
      setMediaFile(selectedFile);
    }
  };

  return (
    <>
      <form className={styling}>
        <label
          htmlFor="newMediaFile"
          onDragOver={onDragOver}
          onDragLeave={onDragLeave}
          onDrop={onDropImage}
          className={`${
            mediaFile
              ? "bg-vividCyanGreen/10 border-vividCyanGreen"
              : "bg-cyanBlue/10 border-cyanBlue"
          } flex items-center justify-center
                    border border-dashed text-base font-bold ${styling}
                    ${
                      dragOver
                        ? "border-vividCyanGreen text-HighlightGreen1 border-2"
                        : "border-1 text-blurple"
                    } relative`}
        >
          {mediaFile && (
            <>
              <div className="flex flex-col items-center gap-[8px]">
                <SvgImage className="h-[24px] w-[24px]" />
                <p className="mb-[4px] text-center">
                  {`${mediaFile?.name.substring(0, 14)}...`}
                </p>
              </div>
              <SvgCheckCircle className="absolute right-[8px] top-[8px] w-[24px]" />
            </>
          )}
          {!mediaFile && (
            <div className="flex flex-col items-center gap-[8px]">
              <SvgImage className="h-[24px] w-[24px]" />
              <p className="text-center ">
                {dragOver ? (
                  <span className="text-center">Drop here!</span>
                ) : (
                  <span className="text-center">
                    Click or drag <br /> to upload
                  </span>
                )}
              </p>
            </div>
          )}
        </label>
        <input
          type="file"
          name="newMediaFile"
          id="newMediaFile"
          className="hidden"
          onChange={fileSelectServiceImage}
        />
      </form>
    </>
  );
};

export default DragDropArea;
