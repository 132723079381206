import React, { useState } from "react"
import moment from "moment"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { SvgWarning } from "../icons/"
import { SubscriptionRequests } from "../../api/app.service"
import { useNavigate } from "react-router-dom"
import { UserCtx } from "../../context/userContext"

const SubscriptionEndingBanner = () => {
  const { user } = React.useContext(UserCtx)
  const navigate = useNavigate()
  const { startLoading, stopLoading } = useLoading()
  const [subscription, setSubscription] = React.useState<any>(null)
  const { renderError } =
    React.useContext(CommonFunctionCtx)
  const [isLoading, setIsLoading] = useState(true)


  const getSubscription = async () => {
    startLoading()
    await SubscriptionRequests.getActiveSubscriptionCoach({
      user_id: parseInt(user.user_id),
    })
      .then((data: any) => {
        if (Object.keys(data.active_subscription).length !== 0) {
          setSubscription(data.active_subscription)
        } else {
          setSubscription(null)
        }
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
      })
      .finally(() => {
        stopLoading()
        setIsLoading(false)
      })
  }

  React.useEffect(() => {
    getSubscription()
  }, [])

  const isExpiringSoon =
    subscription?.subscription_end_date &&
    moment(subscription.subscription_end_date).isBetween(
      moment(),
      moment().add(30, "days"), 
      "day",
      "[]"
    )

  return (
    <>
      {user && user.activeProfile === "coach" && isExpiringSoon && (
        <section className="subscription-cta-gradient relative py-4 pr-4">
          <div className="hidden md:flex flex-col lg:flex-row items-center gap-4 lg:gap-32 justify-center w-full">
            {" "}
            <div className="flex items-center gap-4">
              <h1 className="flex items-center gap-2">
                <SvgWarning />
                If your subscription was purchased before <b>
                  Nov. 26, 2024
                </b>{" "}
                PayPal transition, it will not auto-renew. Please <b>renew</b>{" "}
                to continue enjoying benefits!
              </h1>
              <Button
                variant="black"
                onClick={() => navigate("/coach/subscription/plans")}
              >
                Renew
              </Button>
            </div>
          </div>
          <div className="lg:hidden flex flex-col lg:flex-row items-center gap-4 lg:gap-32 justify-center w-full ">
            {" "}
            <div className="flex items-center gap-2">
              <SvgWarning className="ml-4 h-10 w-10" />
              <span className="hidden md:block">
                If your subscription was purchased before <b>Nov. 26, 2024</b>{" "}
                PayPal transition, it will not auto-renew. Please <b>renew</b>{" "}
                to continue enjoying benefits!
              </span>

              {/* Text for xsm to sm screens */}
              <span className="block md:hidden">
                If your subscription was purchased pre-PayPal [Nov. 26, 2024],
                it will not auto-renew. Please <b>renew</b>.
              </span>
              <Button
                variant="black"
                onClick={() => navigate("/coach/subscription/plans")}
              >
                Renew
              </Button>
            </div>
          </div>
        </section>
      )}
    </>
  )
}

export default SubscriptionEndingBanner
