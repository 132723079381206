import React, { useState, useEffect, useContext } from "react"
import BackToButton from "../BackToButton"
import ConsultationDetailsForm from "./manage/ConsultationDetailsForm"
import GroupServiceDetailsForm from "./manage/GroupServiceDetailsForm"
import ManageServiceOptions from "./manage/ManageServiceOptions"
import PackageForm from "./manage/PackageForm"
import RecurringForm from "./manage/RecurringForm"
import SingleServiceDetailsForm from "./manage/SingleServiceDetailsForm"
import ServiceColorImageForm from "./manage/ServiceColorImageForm"
import ServiceDescriptionForm from "./manage/ServiceDescriptionForm"
import ServiceLinkedForms from "./manage/ServiceLinkedForms"
import ServicePreview from "./ServicePreview"
import useLoading from "../../hooks/useLoading"
import useManageServices from "./hooks/useManageServices"
import useScrollToTop from "../../hooks/useScrollToTop"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ServiceRequests, MeetingRequests } from "../../api/app.service"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import { SvgChevronRight } from "../icons"
import { UserCtx } from "../../context/userContext"
import { UserInfo } from "../../api/app.service"
import { useCurrentWidth } from "../../hooks/useWidth"

interface Props {
  updateService: any
  setUpdateService: any
  setSyncPrompt: any
  connectAccountData: any
  getServices: any
  showComponent: any
  setShowActiveServices: any
  setShowServiceDrafts: any
  isGroupService: boolean
  services: any
  availabilities: any
  setLoadingServices: any
  setCreateFormModal: (statement: boolean) => void
  createFormModal: boolean
  paypalStatus: any
}

const UpdateService: React.FC<Props> = ({
  updateService,
  setUpdateService,
  setSyncPrompt,
  connectAccountData,
  getServices,
  showComponent,
  setShowActiveServices,
  setShowServiceDrafts,
  isGroupService,
  services,
  availabilities,
  setLoadingServices,
  setCreateFormModal,
  createFormModal,
  paypalStatus,
}) => {
  const service = updateService?.service
  const width = useCurrentWidth()
  const serviceType = service?.service_details?.type
  const { user } = useContext(UserCtx)
  const {
    updateNextStepsData,
    setPopupNotification,
    renderError,
    renderSuccess,
  } = useContext(CommonFunctionCtx)
  const { showSubscribeBanner, setShowSubscribeBanner } =
    useContext(SubscriptionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [serviceImgFile, setServiceImgFile] = useState<File>()
  const [previewImgUrl, setPreviewImgUrl] = useState<string>("")
  const [awaitNewImage, setAwaitNewImage] = useState<boolean>(false)
  const [deleteServiceImage, setDeleteServiceImage] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<any>({})
  const [canEnable, setCanEnable] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [serviceValues, setServiceValues] = useState<any>(null)
  const [matchingMeetingIds, setMatchingMeetingIds] = useState<number[]>([])
  const [showManageServiceOptions, setShowManageServiceOptions] =
    useState<boolean>(true)
  const {
    validateConsultationOrIndividual,
    validatePackage,
    validateRecurring,
    setServiceImageDetails,
    getServiceObject,
  } = useManageServices()

  const getSessionCost = () => {
    if (service?.service_details?.type === "bundle") {
      const packageAmount = parseInt(service?.service_details?.billing?.amount)
      const packageSessions = parseInt(service?.service_details?.session?.count)
      return packageAmount / packageSessions
    } else {
      return parseInt(service?.service_details?.billing?.amount)
    }
  }

  const handleAvailabilityRulesChange = (e: any) => {
    let availabilityRuleId = e.id
    setServiceValues({
      ...serviceValues,
      availability_rule_id: availabilityRuleId,
    })
  }

  const getServiceTypeTitle = () => {
    let title = ""
    if (serviceType === "consultation") {
      title = "Consultation"
    } else if (serviceType === "individual") {
      title = "Single Service"
    } else if (serviceType === "bundle") {
      title = "Package Service"
    } else if (serviceType === "recurring") {
      title = "Recurring Sessions"
    }
    return title
  }

  const convertToDateArray = (dateStringsArray: any) => {
    const dates = dateStringsArray?.map((dateString: string) => {
      return new Date(dateString)
    })
    return dates
  }

  // PACKAGES AND RECURRING NEED UPDATING!

  const updateCoachService = () => {
    if (user) {
      startLoading()
      const serviceObject = getServiceObject(serviceType, serviceValues)
      ServiceRequests.updateClientService({
        user_id: parseInt(user.user_id),
        stripe_product_id: service.stripe_product_id,
        stripe_price_id: service.stripe_price_id,
        service: serviceObject,
        group_service_id: updateService?.service?.group_service_id,
      })
        .then(() => {
          renderSuccess("Your service was updated!")
          stopLoading()
          setUpdateService({ show: false, service: {} })
          setLoadingServices(true)
          updateNextStepsData()
          getServices()
          if (serviceValues.enabled) {
            showComponent(setShowActiveServices)
          } else {
            showComponent(setShowServiceDrafts)
          }
          window.scroll(0, 0)
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
          stopLoading()
        })

      if (serviceValues.isGroupService) {
        if (matchingMeetingIds.length > 0) {
          cancelMeeting(matchingMeetingIds)
        } else {
          MeetingRequests.createGroupMeeting({
            coach_user_id: Number(user.user_id),
            coach_profile_id: Number(user.activeProfileId),
            group_service_id: updateService?.service?.group_service_id,
            name: serviceValues.title,
            meeting_type: "Group",
            medium: "video",
            status: "busy",
            reminders: [],
            meeting_details: format_meetingDates(
              serviceValues.group.meetingDates,
              serviceValues.sessionLength.minutes
            ),
            participants: {
              user_id: Number(user.user_id),
              profile_id: Number(user.activeProfileId),
              email: user.email,
              contact_name: user.firstName,
              first_name: user.firstName,
              last_name: user.lastName,
            },
          })
        }
      }
    }
  }

  const cancelMeeting = async (matchingMeetingIds: number[]) => {
    startLoading()

    try {
      const cancelPromises = matchingMeetingIds.map((meetingId) => {
        return MeetingRequests.cancelMeeting({
          coach_user_id: parseInt(user.user_id),
          profile_id: user.activeProfileId,
          first_name: user.firstName,
          last_name: user.lastName,
          meeting_id: meetingId,
        })
      })
      await Promise.all(cancelPromises)
      MeetingRequests.createGroupMeeting({
        coach_user_id: Number(user.user_id),
        coach_profile_id: Number(user.activeProfileId),
        group_service_id: updateService?.service?.group_service_id,
        name: serviceValues.title,
        meeting_type: "Group",
        medium: "video",
        status: "busy",
        reminders: [],
        meeting_details: format_meetingDates(
          serviceValues.group.meetingDates,
          serviceValues.sessionLength.minutes
        ),
        participants: {
          user_id: Number(user.user_id),
          profile_id: Number(user.activeProfileId),
          email: user.email,
          contact_name: user.firstName,
          first_name: user.firstName,
          last_name: user.lastName,
        },
      })
    } catch (ex: any) {
      renderError(ex.response?.data?.message || "An error occurred")
    } finally {
      stopLoading()
    }
  }

  const format_meetingDates = (meetingDates: any[], minutes: number) => {
    let meeting_details: any[] = []
    meetingDates.forEach((meetingDate: string) => {
      const dateObj = new Date(meetingDate)
      const end_date_obj = new Date(dateObj.getTime() + minutes * 60000)
      const start_time = formatDate(dateObj)
      const end_time = formatDate(end_date_obj)
      meeting_details.push({ start_time, end_time })
    })
    return meeting_details
  }

  const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0")
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = "00"

    let date_obj = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    const dateObj = new Date(date_obj)
    return dateObj.toISOString().slice(0, -5) + "Z"
  }

  const deleteImage = () => {
    if (user) {
      startLoading()
      const serviceObject = getServiceObject(serviceType, serviceValues)
      ServiceRequests.deleteServiceImage({
        stripe_product_id: service.stripe_product_id,
        service: serviceObject,
        delete_service_image: deleteServiceImage,
      })
        .then(() => {
          setPreviewImgUrl("")
          setDeleteServiceImage(false)
          renderSuccess("Your service image was deleted")
          stopLoading()
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
          setDeleteServiceImage(false)
          stopLoading()
        })
    }
  }

  const handleExpiresToggle = () => {
    setServiceValues({
      ...serviceValues,
      expiration: {
        ...serviceValues.expiration,
        expires: !serviceValues?.expiration?.expires,
      },
    })
  }

  const handleSessionCostChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(e.target.value)
    setServiceValues({ ...serviceValues, [e.target.name]: value })
  }

  const handleSubmit = (e: React.FormEvent<EventTarget>) => {
    e.preventDefault()
    if (serviceType === "consultation" || serviceType === "individual") {
      setFormErrors(validateConsultationOrIndividual(serviceValues))
    } else if (serviceType === "bundle") {
      setFormErrors(validatePackage(serviceValues))
    } else if (serviceType === "recurring") {
      setFormErrors(validateRecurring(serviceValues))
    }
    setIsSubmit(true)
  }

  const updateShowManageServiceOptions = () => {
    setShowManageServiceOptions(window.innerWidth > 768)
  }

  const addResizeListener = () => {
    window.addEventListener("resize", updateShowManageServiceOptions)
    return () =>
      window.removeEventListener("resize", updateShowManageServiceOptions)
  }

  const handleManageServiceCardClick = () => {
    if (window.innerWidth < 768) {
      setShowManageServiceOptions(() => !showManageServiceOptions)
    }
  }

  const getNextStepsValues = () => {
    if (user) {
      startLoading()
      UserInfo.getNextStepsValues({
        user_id: parseInt(user.user_id),
      })
        .then((data: any) => {
          setCanEnable(data.next_steps_data.is_bank_connected)
        })
        .catch((ex: any) => {
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  useEffect(() => {
    setServiceImageDetails(
      serviceImgFile,
      setServiceValues,
      serviceValues,
      setPopupNotification,
      setServiceImgFile,
      setPreviewImgUrl
    )
    if (serviceImgFile) {
      setAwaitNewImage(false)
    }
  }, [serviceImgFile])

  useEffect(() => {
    if (deleteServiceImage) {
      deleteImage()
    }
  }, [deleteServiceImage])

  useEffect(() => {
    if (isSubmit) {
      if (Object.keys(formErrors).length === 0) {
        setShowPreview(true)
      } else {
        setIsSubmit(false)
        stopLoading()
        renderError("Complete all fields to see the preview")
      }
    }
  }, [formErrors])

  useEffect(() => {
    getNextStepsValues()
    addResizeListener()
    let deviceWidth = window.matchMedia("(max-width: 768px)")
    if (deviceWidth.matches) {
      updateShowManageServiceOptions()
    }
  }, [])

  useScrollToTop()

  useEffect(() => {
    if (service?.service_details?.image?.exists) {
      setPreviewImgUrl(
        `https://clientservice-images-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${service?.stripe_product_id}`
      )
    }
  }, [])

  useEffect(() => {
    if (width < 768) {
      setShowSubscribeBanner(false)
    }
  }, [width])

  useEffect(() => {
    setServiceValues({
      availability_rule_id: service?.service_details?.availability_rule_id,

      isGroupService: isGroupService,
      color: service?.service_details?.image?.color,
      title: service?.service_details?.title,
      description: service?.service_details?.details?.description1,
      feature1: service?.service_details?.details?.features?.feature1,
      feature2: service?.service_details?.details?.features?.feature2,
      feature3: service?.service_details?.details?.features?.feature3,
      sessionLength: {
        minutes: parseInt(service?.service_details?.session?.duration) / 60,
      },
      currency: service?.service_details?.currency,
      symbol: service?.service_details?.symbol,
      sessionCost: getSessionCost(),
      isFree: getSessionCost() === 0 || false,
      allowPromo: service?.service_details?.allow_promo || false,
      package: {
        packageSessions: service?.service_details?.session?.count || 2,
        packageBillingType:
          service?.service_details?.billing?.packageBillingType || "bulk",
        packageCost: service?.service_details?.billing?.amount || 100,
        installmentIncrements: service?.service_details?.billing
          ?.installmentIncrements || [{ time: 1, type: "months" }],
        upFrontPercentage:
          service?.service_details?.billing?.upFrontPercentage || 50,
      },
      recurring: {
        isFree: getSessionCost() === 0 || false,
        periodCost: service?.service_details?.billing?.amount,
        recurringFrequency:
          service?.service_details?.billing?.unit === "week"
            ? "weekly"
            : "monthly",
        sessionsPerPeriod: service?.service_details?.session?.count || 1,
        unlimited: false,
      },
      expiration: {
        expireTime: service?.service_details?.expiration?.time,
        expireType: service?.service_details?.expiration?.type,
        expires: service?.service_details?.expiration?.expires,
      },
      enabled: service?.service_details?.status === "active" ? true : false,
      profileServiceVisible:
        service?.service_details?.visibility?.profile?.service,
      profileCostVisible: service?.service_details?.visibility?.profile?.cost,
      marketplaceServiceVisible:
        service?.service_details?.visibility?.marketplace?.service,
      marketplaceCostVisible:
        service?.service_details?.visibility?.marketplace?.cost,
      image: {
        exists: service?.service_details?.image?.exists || false,
        base64: "",
        base64extension: "",
      },
      group: {
        isFree: service?.service_details?.group?.isFree,
        meetingDates: convertToDateArray(
          service?.service_details?.group?.meetingDates
        ),
        weeklyDayTimes: service?.service_details?.group?.weeklyDayTimes,
        maximumClients: service?.service_details?.group?.maximumClients,
        groupChat: service?.service_details?.group?.groupChat,
        enrollByDate: "",
      },
      serviceLinkedForms: service?.service_details?.serviceLinkedForms,
    })
  }, [updateService?.service])

  //Go back to my services page
  const isComingFromMyServices = updateService?.source === "MyServices"

  const handleMatchingMeetingIds = (ids: number[]) => {
    setMatchingMeetingIds(ids)
  }

  return (
    <>
      {serviceValues && (
        <>
          {showPreview && (
            <ServicePreview
              setShowPreview={setShowPreview}
              serviceValues={serviceValues}
              setServiceValues={setServiceValues}
              saveType="edit"
              callback={updateCoachService}
              previewImgUrl={previewImgUrl}
              serviceType={serviceType}
              isGroupService={isGroupService}
              groupCallback={updateCoachService}
              matchingMeetingIds={matchingMeetingIds}
            />
          )}
          <div
            className={`fixed top-0 w-screen h-[88px] create-service-shadow bg-white flex items-center justify-center z-[401] overflow-x-hidden
        ${
          !showSubscribeBanner && !user?.isSubscribed
            ? "md:top-[160px]"
            : "md:top-[80px]"
        }`}
          >
            <div className="flex items-center md:w-[1080px] w-full justify-between px-[20px]">
              {isComingFromMyServices && (
                <BackToButton
                  onClick={() => setUpdateService({ show: false, service: {} })}
                  current="Update Service"
                  prev="My Services"
                />
              )}
              <div className="flex items-center gap-[32px]">
                <Button
                  className="hidden md:block"
                  onClick={handleSubmit}
                  size={"lg"}
                >
                  {serviceValues.enabled ? "Save Service" : "Save Draft"}
                </Button>
              </div>
            </div>
          </div>

          <div className="md:hidden main-shadow fixed z-[1007] pt-[18px] px-[20px] bottom-0 left-0 w-full h-[114px] bg-white flex justify-center">
            <Button onClick={handleSubmit} size="lg">
              {serviceValues.enabled ? "Save Service" : "Save Draft"}
            </Button>
          </div>

          <div className="px-[20px] pt-[5px] md:pt-[60px] md:w-[1080px] flex flex-col-reverse md:flex-row justify-between items-start mx-auto relative">
            <div className={`flex flex-col gap-[32px] relative`}>
              <div className="md:w-[620px] main-shadow flex flex-col rounded-[16px] bg-white p-[32px] md:p-[40px]">
                <h2 className="font-bold text-[22px] mb-[24px]">
                  {getServiceTypeTitle()} Billing Details
                </h2>

                {serviceType === "consultation" && (
                  <ConsultationDetailsForm
                    formErrors={formErrors}
                    serviceValues={serviceValues}
                    setServiceValues={setServiceValues}
                    handleSessionCostChange={handleSessionCostChange}
                    handleExpiresToggle={handleExpiresToggle}
                    serviceType={serviceType}
                    isGroupService={isGroupService}
                    availabilities={availabilities}
                    services={services}
                    handleAvailabilityRulesChange={
                      handleAvailabilityRulesChange
                    }
                    service={service}
                  />
                )}

                {serviceType === "individual" && (
                  <SingleServiceDetailsForm
                    formErrors={formErrors}
                    serviceValues={serviceValues}
                    setServiceValues={setServiceValues}
                    handleSessionCostChange={handleSessionCostChange}
                    handleExpiresToggle={handleExpiresToggle}
                    serviceType={serviceType}
                    isGroupService={isGroupService}
                    availabilities={availabilities}
                    handleAvailabilityRulesChange={
                      handleAvailabilityRulesChange
                    }
                    service={service}
                  />
                )}

                {/* package service type */}
                {serviceType === "bundle" && (
                  <PackageForm
                    formErrors={formErrors}
                    serviceValues={serviceValues}
                    setServiceValues={setServiceValues}
                    handleExpiresToggle={handleExpiresToggle}
                    serviceType={serviceType}
                    isGroupService={isGroupService}
                    availabilities={availabilities}
                    handleAvailabilityRulesChange={
                      handleAvailabilityRulesChange
                    }
                    service={service}
                    paypalStatus={paypalStatus}
                  />
                )}

                {/* recurring service type */}
                {serviceType === "recurring" && (
                  <RecurringForm
                    formErrors={formErrors}
                    serviceValues={serviceValues}
                    setServiceValues={setServiceValues}
                    serviceType={serviceType}
                    isGroupService={isGroupService}
                    availabilities={availabilities}
                    handleAvailabilityRulesChange={
                      handleAvailabilityRulesChange
                    }
                    service={service}
                    paypalStatus={paypalStatus}
                    connectAccountData={connectAccountData}
                  />
                )}
              </div>

              {/* GROUP SERVICE DETAILS FORM */}
              {service?.is_group_service && (
                <GroupServiceDetailsForm
                  formErrors={formErrors}
                  serviceValues={serviceValues}
                  setServiceValues={setServiceValues}
                  serviceType={serviceType}
                  isGroupService={isGroupService}
                  service={service}
                  handleMatchingMeetingIds={handleMatchingMeetingIds}
                />
              )}

              {/* service description form */}
              <ServiceDescriptionForm
                formErrors={formErrors}
                setServiceValues={setServiceValues}
                serviceValues={serviceValues}
                isGroupService={isGroupService}
              />

              {/* service linked forms */}

              <ServiceLinkedForms
                setServiceValues={setServiceValues}
                serviceValues={serviceValues}
                setCreateFormModal={setCreateFormModal}
                createFormModal={createFormModal}
              />

              {/* service color / service image form */}
              <div className="mb-[120px] md:mb-[30px]">
                <ServiceColorImageForm
                  serviceValues={serviceValues}
                  setServiceValues={setServiceValues}
                  previewImgUrl={previewImgUrl}
                  setPreviewImgUrl={setPreviewImgUrl}
                  awaitNewImage={awaitNewImage}
                  setAwaitNewImage={setAwaitNewImage}
                  serviceImgFile={serviceImgFile}
                  setServiceImgFile={setServiceImgFile}
                />
              </div>
            </div>

            <div className="md:w-[362px] w-full py-[40px] px-[32px] bg-white main-shadow rounded-[16px] mx-auto md:mx-0 mt-[42px] md:mt-0 mb-[12px] md:mb-0">
              <div className="flex items-center w-full justify-between mb-[24px]">
                <div className="w-full flex items-center md:justify-between">
                  <h3 className="font-bold text-[22px] mr-[24px] md:mr-0">
                    Manage Service
                  </h3>
                </div>
                <button
                  onClick={handleManageServiceCardClick}
                  className="md:hidden flex items-center text-base font-bold"
                >
                  <SvgChevronRight />
                </button>
              </div>

              {showManageServiceOptions && (
                <ManageServiceOptions
                  setServiceValues={setServiceValues}
                  serviceValues={serviceValues}
                  canEnable={canEnable}
                  connectAccountData={connectAccountData}
                  setSyncPrompt={setSyncPrompt}
                  enrolledCount={service?.enrolled_count}
                />
              )}
              {service?.enrolled_count === null && (
                <Button className="w-full" onClick={handleSubmit} size="lg">
                  See Preview of Your Service
                </Button>
              )}
            </div>
          </div>
        </>
      )}
    </>
  )
}

export default UpdateService
