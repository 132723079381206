import React from "react"
import CloseModal from "../CloseModal"
import { Link } from "react-router-dom"
import { SvgSyncNeeded } from "../icons"
import { UserCtx } from "../../context/userContext"

interface Props {
  setSyncPrompt: any
  service: any
  connectAccountData: any
}

const SyncPrompt: React.FC<Props> = ({
  setSyncPrompt,
  connectAccountData,
  service,
}) => {
  const { user } = React.useContext(UserCtx)
  const closeAndReset = () => {
    setSyncPrompt(false)
  }

  const syncBankAccountHeaderText = `Before you can create a Paid Service, you must complete your PayPal Onboarding`

  return (
    <div className="fixed top-0 left-0 w-screen h-screen pb-[100px] md:pb-0 zoee-backdrop-filter z-[801] flex items-center justify-center">
      <div
        className="mx-[20px] md:w-[600px] w-full py-[48px] px-[28px] md:px-[72px] bg-white 
                main-shadow rounded-[16px] flex flex-col items-center relative"
      >
        <CloseModal
          callback={closeAndReset}
          styling="absolute top-[24px] right-[24px]"
        />
        <SvgSyncNeeded className="mb-[16px] md:mb-[32px]" />
        <h3 className="font-bold md:font-semibold text-[22px] md:text-[28px] mb-[12px] mx-auto text-center mb-[16px] md:mb-[32px]">
          {syncBankAccountHeaderText}
        </h3>
        <Link
          to={`/${user.activeProfile}/client-payments`}
          className="btn-primary btn-secondary bg-grayMist mb-[32px] min-w-[260px] md:min-w-0"
        >
          Set up PayPal Account
        </Link>
      </div>
    </div>
  )
}

export default SyncPrompt
