import React, { FC, useContext } from "react"
import CloseModal from "../CloseModal"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { SvgCheckbox } from "../icons/"
import { UserCtx } from "../../context/userContext"

type Props = {
  setShowMobileFilters: any
  selectedSort: string
  setSelectedSort: any
  showComponent: any
  setShowReceivedInvites: any
  setShowInvited: any
  showCoachDirectory: any
  setShowCoachDirectory: any
  setShowZoeeLeads: any
  setShowProspective: any
  showProspective: boolean
  setShowAllContacts: any
  showReceivedInvites: boolean
  showInvited: boolean
  showZoeeLeads: boolean
  showAllContacts: boolean
  contacts: any
  setContacts: any
  filterReceivedInvites: any
  filterInvited: any
}

const MobileContactsFilters: FC<Props> = ({
  setShowMobileFilters,
  selectedSort,
  setSelectedSort,
  showComponent,
  setShowReceivedInvites,
  setShowInvited,
  showCoachDirectory,
  setShowCoachDirectory,
  setShowZoeeLeads,
  setShowProspective,
  showProspective,
  setShowAllContacts,
  showReceivedInvites,
  showInvited,
  showZoeeLeads,
  showAllContacts,
  contacts,
  setContacts,
  filterReceivedInvites,
  filterInvited,
}) => {
  const { user } = useContext(UserCtx)

  const sortContactsBy = (sortValue: string) => {
    const sorted = [...contacts]

    if (sortValue === "first") {
      sorted.sort((a: any, b: any) =>
        a.contact_name
          .split(" ")[0]
          .localeCompare(b.contact_name.split(" ")[0], undefined, {
            sensitivity: "base",
          })
      )
    } else if (sortValue === "last") {
      sorted.sort((a: any, b: any) =>
        a.contact_name
          .split(" ")[1]
          .localeCompare(b.contact_name.split(" ")[1], undefined, {
            sensitivity: "base",
          })
      )
    }

    setContacts(sorted)
    setSelectedSort(sortValue)
  }

  return (
    <div className="md:hidden fixed top-0 left-0 w-screen h-screen bg-mainBlack bg-opacity-70 px-[16px] z-[1006]">
      <div
        className={`${
          user.activeProfile === "coach"
            ? "mt-[5px] pt-[20px]"
            : "mt-[14px] pt-[40px]"
        } max-w-[414px] relative sm:mt-[14px] mx-auto px-[42px] sm:pt-[40px] pb-[32px] bg-white rounded-[16px] min-h-[70%]`}
      >
        <CloseModal
          callback={() => setShowMobileFilters(false)}
          styling="absolute top-[20px] right-[20px]"
        />

        {/* FILTER BY */}
        <h3 className="text-base font-bold mb-[30px]">Filter by</h3>
        <div className="flex flex-col gap-[12px] items-start mb-[32px]">
          <button
            onClick={() => showComponent(setShowAllContacts)}
            className={`${
              showAllContacts
                ? "btn-primary btn-secondary-nav"
                : "py-[12px] px-[16px] text-base text-graySlate font-bold"
            }`}
          >
            {user.activeProfile === 'coach' ? "My Clients" : "My Coaches"}
          </button>
          {user.activeProfile === "coach" && (
            <>
              <button
                onClick={() => showComponent(setShowProspective)}
                className={`${
                  showProspective
                    ? "btn-primary btn-secondary-nav"
                    : "py-[12px] px-[16px] text-base text-graySlate font-bold"
                }`}
              >
                My Coaches
              </button>
              <button
                onClick={() => showComponent(setShowZoeeLeads)}
                className={`${
                  showZoeeLeads
                    ? "btn-primary btn-secondary-nav"
                    : "py-[12px] px-[16px] text-base text-graySlate font-bold"
                }`}
              >
                Zoee Leads
              </button>
            </>
          )}
          <button
            onClick={() => showComponent(setShowReceivedInvites)}
            className={`${
              showReceivedInvites
                ? "btn-primary btn-secondary-nav"
                : "py-[12px] px-[16px] text-base text-graySlate font-bold"
            } flex items-center`}
          >
            Received Invites
            {filterReceivedInvites.length > 0 && (
              <Badge variant="alert">{filterReceivedInvites.length}</Badge>
            )}
          </button>
          <button
            onClick={() => showComponent(setShowInvited)}
            className={`${
              showInvited
                ? "btn-primary btn-secondary-nav"
                : "py-[12px] px-[16px] text-base text-graySlate font-bold"
            } flex items-center`}
          >
            Sent Invites
            {filterInvited.length > 0 && (
              <Badge variant="alert">{filterInvited.length}</Badge>
            )}
          </button>
          <button
            onClick={() => showComponent(setShowCoachDirectory)}
            className={`${
              showCoachDirectory
                ? "btn-primary btn-secondary-nav"
                : "py-[12px] px-[16px] text-base text-graySlate font-bold"
            } flex items-center`}
          >
            Coach Directory
          </button>
        </div>
        <div className="h-[1px] w-full bg-gray" />

        {/* SORT BY */}
        <h3 className="text-base font-bold mt-[32px] mb-[30px]">Sort by</h3>
        <div className="pl-[12px] flex flex-col gap-[18px] text-base mb-[42px]">
          <button
            className="flex items-center justify-between w-full"
            onClick={() => sortContactsBy("none")}
          >
            None
            <div
              className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${
                                      selectedSort === "none" && "bg-cyanBlue"
                                    }`}
            >
              {selectedSort === "none" && <SvgCheckbox />}
            </div>
          </button>
          <button
            className="flex items-center justify-between w-full"
            onClick={() => sortContactsBy("first")}
          >
            First Name
            <div
              className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${
                                      selectedSort === "first" && "bg-cyanBlue"
                                    }`}
            >
              {selectedSort === "first" && <SvgCheckbox />}
            </div>
          </button>
          <button
            className="flex items-center justify-between w-full"
            onClick={() => sortContactsBy("last")}
          >
            Last Name
            <div
              className={`w-[24px] h-[24px] border border-1 border-grayCloud rounded-[8px] bg-transparent
                                    flex items-center justify-center
                                    ${
                                      selectedSort === "last" && "bg-cyanBlue"
                                    }`}
            >
              {selectedSort === "last" && <SvgCheckbox />}
            </div>
          </button>
        </div>

        <div className="flex justify-center">
          <Button
            onClick={() => setShowMobileFilters(false)}
            className="h-[44px] w-[215px] text-md"
          >
            Confirm
          </Button>
        </div>
      </div>
    </div>
  )
}

export default MobileContactsFilters
