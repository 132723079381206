import React, { useState, useEffect } from "react"
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
} from "./ui/dialog"

const SessionsNewFeature: React.FC = () => {
  const [showModal, setShowModal] = useState<boolean>(false)

  useEffect(() => {
    const showSessionNewFeature =
      localStorage.getItem("showSessionsNewFeature") === "false"
    if (!showSessionNewFeature) {
      setShowModal(true)
      localStorage.setItem("showSessionsNewFeature", "true")
    }
  }, [showModal])

  const handleOpenChange = (open: boolean) => {
    setShowModal(false)
    localStorage.setItem("showSessionsNewFeature", "false")
  }

  return (
    <Dialog open={showModal} onOpenChange={handleOpenChange}>
      <DialogContent className="max-w-lg max-h-screen overflow-y-auto">
        <DialogHeader>
          <DialogTitle className="text-center mb-4 text-2xl">
            {"\u2728"} Welcome to our new and improved Video Hub!{" "}
          </DialogTitle>
          <DialogDescription className="text-center text-lg">
            We've refreshed the experience with better organization and a sleek
            new interface. Dive in and enjoy your sessions in a whole new way.
            Questions or feedback? We'd love to hear from you!{" "}
            <a href="mailto:support@zoee.com" className="underline">
              support@zoee.com
            </a>
          </DialogDescription>
        </DialogHeader>
      </DialogContent>
    </Dialog>
  )
}

export default SessionsNewFeature
