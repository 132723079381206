import { useContext, useEffect, useMemo } from "react"
import { SvgWarningSign } from "../icons"
import { UserCtx } from "../../context/userContext"
import { useSearchParams } from "react-router-dom"
import { UserAccounts } from "../../api/app.service"
import BBHCProductDemo from "./BBHCProductDemo"

const BBHCResourceHub = () => {
  const { user } = useContext(UserCtx)
  const [searchParams, setSearchParams] = useSearchParams()

  const getDiscourseSSO = async () => {
    const sso = searchParams.get("sso")
    const sig = searchParams.get("sig")

    if (sso && sig) {
      await UserAccounts.createDiscourseSso({
        sso: sso,
        sig: sig,
        external_id: user.user_id,
        email: user.email,
      })
        .then((response: any) => {
          window.open(response?.location, "_blank")
        })
        .catch((error: any) => console.error("Error in Discourse SSO: ", error))
    } else {
      console.error("Missing sso or sig params")
    }
  }

  useEffect(() => {
    getDiscourseSSO()
  }, [])

  const dashboardInformation = useMemo(
    () => [
      {
        id: 1,
        header: "Program Resources",
        text: "Access important program documents",
        link: "https://iin.widencollective.com/portals/zxf8ktj4/BusinessBlueprintforHealthCoachesProgramResources",
      },
      {
        id: 2,
        header: "BBHC Community",
        text: "Your place to connect and get support",
        link: "https://community.zoee.com/session/sso",
      },
      {
        id: 3,
        header: "Program Schedule",
        text: "Review key program dates here",
        link: "https://iin.widen.net/s/xrtxgfxltk/jan25-program-schedule_bbhc",
      },
      {
        id: 4,
        header: "Weekly Workbook",
        text: "Access a new chapter every Monday",
        link: "https://iin.widencollective.com/portals/rs8jydix/BusinessBlueprintforHealthCoachesWeeklyWorkbooks",
      },
    ],
    []
  )

  return (
    <div
      className={`
        mx-auto w-full 
        bg-white border rounded-xl shadow-[0px_1px_2px_0px_rgba(16,24,40,0.06)]
        flex flex-col gap-3
        /* Base (mobile) spacing */
        px-4 py-6
        /* Tablet (≥768px) spacing */
        md:px-8 md:py-8
        /* Desktop (≥1024px) spacing */
        lg:px-12 lg:py-10
        /* Larger desktop (≥1280px) spacing */
        xl:px-16 xl:py-12
        /* Or, optionally, cap overall width */
        max-w-screen-xl
      `}
    >
      <div className="flex flex-col mb-3">
        <h2
          className={`
            font-bold text-[18px] leading-[28px] tracking-[-0.06em]
            whitespace-nowrap
            /* Or text-ellipsis if you need truncation */
          `}
        >
          Business Blueprint <span className="font-normal italic">for </span>
          Health Coaches
        </h2>
        <p className="text-[#3a434a] text-base mt-[2px]">
          <span className="font-semibold">Resource Hub</span> <br />
          Please ensure popups are enabled for this page
        </p>
        {/* Uncomment below for when resource hub is down. */}
        {/* <h2 className="flex items-center gap-2 mt-4">
          <span className="w-8 h-8 flex-shrink-0">
            <SvgWarningSign className="w-full h-full" />
          </span>{" "}
          We're currently experiencing a service interruption with our Resource
          Hub. Our engineering team is actively working to restore full
          functionality. We appreciate your patience and will update this status
          when service resumes.
        </h2> */}
      </div>

      <div className="grid gap-6 items-start md:grid-cols-[1fr_auto]">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
          {dashboardInformation.map((item) => (
            <div
              key={item.id}
              className="
        px-3 py-1.5
        bg-[#4750f5]/10 rounded-[4px] border border-[#d1d9e0]
        flex flex-col justify-between
        min-w-[215px] max-w-full
        cursor-pointer
        transition-transform transform hover:scale-105 hover:bg-[#4750f5]/20
      "
              onClick={() => window.open(item.link, "_blank")}
            >
              <div className="text-[#0d0d0d] text-sm font-semibold mb-1">
                {item.header}
              </div>
              <p className="text-[#3a434a] text-xs">{item.text}</p>
            </div>
          ))}
        </div>

        <div
          className="
    md:w-[260px] sm:w-[310px] w-full rounded-[4px] flex justify-center items-center
    mx-auto md:mx-0
  "
        >
          <BBHCProductDemo />
        </div>
      </div>
    </div>
  )
}

export default BBHCResourceHub
