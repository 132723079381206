import { FC, useContext, useEffect, useState } from "react"
import moment from "moment"
import useLoading from "../../hooks/useLoading"
import UserEmailCheckModal from "./UserEmailCheckModal"
import { Badge } from "../ui/badge"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ContactRequests } from "../../api/app.service"
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../ui/dropdown-menu"
import {
  DropdownMenuGroup,
  DropdownMenuLabel,
} from "@radix-ui/react-dropdown-menu"
import { getLightTextColor } from "../../utils/getLightTextColor"
import { getOrdinal } from "../../utils/get-ordinal"
import { getPrice } from "../../utils/services/get-price"
import { getTextColor } from "../../utils/getTextColor"
import { getUnits } from "../../utils/services/get-units/get-units"
import { isCoachInCommissionTracking } from "../../utils/commissionTracking"
import { isLocalStorageAvailable } from "../../utils/isLocalStorageAvailable"
import { Link, useNavigate, useParams } from "react-router-dom"
import { PublicProfileRequests } from "../../api/public.service"
import { pluralize } from "../../utils/pluralize"
import { ServiceRequests } from "../../api/app.service"
import {
  SvgBundle,
  SvgCheckCircle,
  SvgConsultation,
  SvgRecurring,
  SvgShare,
  SvgUserCheck,
} from "../icons"
import { useAuthenticatedUser } from "../../hooks/useAuthenticatedUser"
import { useAuthModal } from "../auth/use-auth-modal"
import { useCopyToClipboard } from "../../hooks/useCopyToClipboard"
import { UserCtx } from "../../context/userContext"

const PublicServiceDetails: FC = () => {
  const { renderError, renderSuccess } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const [isOwner, setIsOwner] = useState<boolean>(false)
  const { service_id, endpoint_slug } = useParams()
  const [service, setService] = useState<any>(null)
  const { user, setUser } = useContext(UserCtx)
  const [, copy] = useCopyToClipboard()
  const navigate = useNavigate()
  const [userEmailCheck, setUserEmailCheck] = useState<boolean>(false)
  const [coachPublicProfileData, setCoachPublicProfileData] =
    useState<any>(null)
  const authenticatedUser = useAuthenticatedUser(user)
  const { showRegister } = useAuthModal()
  const coachColor = coachPublicProfileData?.profile_information?.profileColor
  const textColor = getLightTextColor(coachColor)
  const { service_details, is_group_service } = service || {}
  const [spotsLeft, setSpotsLeft] = useState<number | null>(null)
  const [groupServiceCheck, setgroupServiceCheck] = useState<boolean>(false)
  const [servicesLoading, setServicesLoading] = useState<boolean>(false)

  const getServiceType = () => {
    if (service?.service_details?.type === "consultation") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgConsultation
            fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
          />
          Consultation
        </div>
      )
    } else if (service?.service_details?.type === "individual") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgUserCheck
            fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
          />
          Individual Session
        </div>
      )
    } else if (service?.service_details?.type === "bundle") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgBundle
            fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
          />
          Bundle Package
        </div>
      )
    } else if (service?.service_details?.type === "recurring") {
      return (
        <div className="flex items-center gap-[8px] text-base font-bold">
          <SvgRecurring
            fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
          />
          Recurring Program
        </div>
      )
    }
  }

  const getSessionCount = () => {
    if (
      ["consultation", "individual"].includes(service?.service_details?.type)
    ) {
      return `${service?.service_details?.session?.count} session`
    } else if (["bundle"].includes(service?.service_details?.type)) {
      return pluralize(service?.service_details?.session?.count, "session")
    } else if (["recurring"].includes(service?.service_details?.type)) {
      return (
        <div>
          {pluralize(service?.service_details?.session?.count, "session")}
          <span className="text-darkGray">
            {" "}
            / {service?.service_details?.billing?.unit}
          </span>
        </div>
      )
    } else {
      return "Not Found"
    }
  }

  const getLengthOrExpiration = () => {
    let expirationString = "No expiration"
    if (
      ["consultation", "individual", "bundle"].includes(
        service?.service_details?.type
      )
    ) {
      if (service?.service_details?.expiration?.expires) {
        expirationString = `Expires after ${service?.service_details?.expiration?.time} ${service?.service_details?.expiration?.type}`
      } else {
        expirationString = "No expiration"
      }
    } else if (["recurring"].includes(service?.service_details?.type)) {
      const units =
        service?.service_details?.expiration?.type === "weeks"
          ? "week"
          : "month"
      expirationString = `${service?.service_details?.expiration?.time} ${units} program`
    } else {
      expirationString = "Expiration not found"
    }
    return expirationString
  }

  const handleClientSwitch = async (user: any) => {
    const userObject = {
      ...user,
    }
    if (isLocalStorageAvailable()) {
      localStorage.setItem("user", JSON.stringify(userObject))
    }
    setUser(userObject)
  }

  const connectAndRedirect = (user: any) => {
    ContactRequests.createContactConnection({
      sender_profile_id: coachPublicProfileData?.coach_profile_id,
      receiver_profile_id: user.activeProfileId,
      user_environment: process.env.REACT_APP_USER_ENVIRONMENT || "",
      status: user?.isCoach
        ? "c2c"
        : isCoachInCommissionTracking(coachPublicProfileData?.endpoint_slug)
        ? "zoee lead"
        : "connected",
    })
      .then(() => {
        user?.isCoach
          ? navigate(
              `/coach/contacts/${coachPublicProfileData?.coach_profile_id}/services/${service?.service_id}`,
              { state: {} }
            )
          : navigate(
              `/member/contacts/${coachPublicProfileData?.coach_profile_id}/services/${service?.service_id}`,
              { state: {} }
            )
      })
      .catch((ex: any) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  const goToEnroll = (user: any) => {
    if (localStorage.getItem("meetingToSchedule")) {
      localStorage.removeItem("meetingToSchedule")
    }
    if (isOwner) {
      return renderError(
        "Can't take action on profile page when logged into this account"
      )
    }

    handleClientSwitch(user).then(() => {
      connectAndRedirect(user)
    })
  }

  const checkEmailOrRedirect = () => {
    if (authenticatedUser) {
      goToEnroll(user)
    } else {
      showRegister({
        accountType: "member",
        onSuccess: (user) => {
          goToEnroll(user)
        },
      })
    }
  }

  const getCoachPublicProfile = () => {
    if (endpoint_slug) {
      startLoading()
      PublicProfileRequests.getCoachPublicProfile({
        endpoint_slug: endpoint_slug,
      })
        .then((data) => {
          if (data.coach_public_profile) {
            setCoachPublicProfileData(data.coach_public_profile)
            getServices(data)
            // getProfileServiceList(data.coach_public_profile)
          } else {
            setCoachPublicProfileData(null)
          }
        })
        .catch((ex) => {
          renderError(ex.response.data.message)
          stopLoading()
        })
    }
  }

  const getProfileServiceList = (coachPublicProfileData: any) => {
    if (user && coachPublicProfileData) {
      setServicesLoading(true)
      const coachProfileId = parseInt(coachPublicProfileData.coach_profile_id)
      const memberProfileId = parseInt(user.activeProfileId)
      ServiceRequests.getServiceListProfile({
        coach_profile_id: coachProfileId,
        member_profile_id: memberProfileId,
      })
        .then((data) => {
          if (data && data.service_list) {
            const isEnrolled = data.service_list.some(
              (clientService: any) =>
                clientService.is_group_service &&
                clientService.service_status === "active" &&
                clientService.service_id === service.service_id
            )
            setgroupServiceCheck(isEnrolled)
          }
        })
        .catch((ex) => {
          renderError(ex.response?.data?.message || "An error occurred")
        })
        .finally(() => {
          setServicesLoading(false)
        })
    } else {
      console.warn("User or Coach Public Profile Data is missing.")
    }
  }

  const getGroupServiceEnrollment = async () => {
    try {
      const response = await ServiceRequests.checkGroupServiceEnrollment({
        stripe_product_id: service.stripe_product_id,
      })

      const data = response
      const spotsLeft = data.spots_left

      setSpotsLeft(spotsLeft)
    } catch (error) {
      console.error("Error fetching group service enrollment:", error)
    }
  }

  useEffect(() => {
    if (is_group_service) {
      getGroupServiceEnrollment()
    }
  }, [service])

  const getServices = (data: any) => {
    ServiceRequests.getServiceListClient({
      profile_id: parseInt(data?.coach_public_profile?.coach_profile_id),
      profile_type: "coach",
    })
      .then((data: any) => {
        stopLoading()
        if (service_id) {
          const service = data?.service_list?.find((service: any) => {
            return (
              service.service_id === parseInt(service_id) &&
              service?.service_details?.status === "active"
            )
          })
          setService(service)
        }
      })
      .catch((ex) => {
        console.log(ex)
        renderError(ex.response.data.message)
        stopLoading()
      })
  }

  useEffect(() => {
    setIsOwner(
      authenticatedUser &&
        parseInt(user?.user_id) === coachPublicProfileData?.user_id
    )
  }, [user, coachPublicProfileData])

  useEffect(() => {
    window.scroll(0, 0)
    getCoachPublicProfile()
  }, [])

  useEffect(() => {
    if (service && coachPublicProfileData) {
      getProfileServiceList(coachPublicProfileData)
    }
  }, [service, coachPublicProfileData])

  const serviceURL = document.URL

  const handleCopyToClipboard = () => {
    copy(serviceURL)
    renderSuccess("Copied to clipboard")
  }

  const getStartDatesWithVariants = () => {
    const meetingDates = service_details.group.meetingDates || []
    const sessionCount = meetingDates.length

    if (sessionCount === 0) {
      return []
    } else if (sessionCount === 1) {
      const date = meetingDates[0]
      const isPast = new Date(date) < new Date()
      const variant = isPast ? "destructive" : "success"
      const formattedDate = moment(date).format("MMM DD [at] h:mm A")
      const sessionStatus = isPast ? "was" : "Scheduled for"
      return [
        {
          message: `Session ${sessionStatus} ${formattedDate}`,
          variant,
        },
      ]
    } else {
      return meetingDates.map((date: any, index: any) => {
        const isPast = new Date(date) < new Date()
        const variant = isPast ? "destructive" : "success"
        const sessionNumber = index + 1
        const sessionLabel = getOrdinal(sessionNumber)
        const formattedDate = moment(date).format("MMM DD [at] h:mm A")
        const sessionStatus = isPast ? "was" : "Scheduled for"
        return {
          message: `${sessionLabel} Session ${sessionStatus} ${formattedDate}`,
          variant,
        }
      })
    }
  }

  const BackToServicHandleClick = () => {
    navigate({
      pathname: `/find-a-coach/${endpoint_slug}`,
      search: user.activeProfile !== "coach" ? "?services=true" : "",
    })
  }

  return (
    <>
      {coachPublicProfileData && (
        <>
          {userEmailCheck && (
            <UserEmailCheckModal
              setUserEmailCheck={setUserEmailCheck}
              goToEnroll={goToEnroll}
            />
          )}
          <div className="mx-auto -mt-[43px] flex w-[800px] max-w-[90%] flex-col gap-[24px] pb-[100px] pt-[100px]">
            {service ? (
              <>
                <div className="-mb-[20px] flex w-full items-center justify-between">
                  <div>
                    <Button
                      variant={"context"}
                      className={`flex h-[50px] min-w-[100px] items-center justify-center rounded-md p-4 font-bold shadow-md text-${getTextColor(
                        coachColor
                      )}`}
                      style={{
                        backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
                        color: getTextColor(coachColor),
                      }}
                      onClick={BackToServicHandleClick}
                    >
                      Back
                    </Button>
                  </div>
                  <div className="flex gap-[70px]">
                    <DropdownMenu>
                      <DropdownMenuTrigger asChild>
                        <Button className="bg-grayFlash text-grayCharcoal -mr-14 flex h-[50px] w-[60px] items-center rounded-md shadow-md">
                          <SvgShare />
                        </Button>
                      </DropdownMenuTrigger>
                      <DropdownMenuContent align="end">
                        <DropdownMenuLabel className="text-graySlate mb-2 text-xs">
                          Share via
                        </DropdownMenuLabel>
                        <DropdownMenuGroup>
                          <DropdownMenuItem>
                            <span onClick={handleCopyToClipboard}>
                              Copy Link
                            </span>
                          </DropdownMenuItem>
                        </DropdownMenuGroup>
                      </DropdownMenuContent>
                    </DropdownMenu>
                    <Button
                      onClick={() => {
                        checkEmailOrRedirect()
                      }}
                      variant={"context"}
                      className={`flex h-[50px] min-w-[150px] items-center justify-center rounded-md p-4 font-bold shadow-md text-${getTextColor(
                        coachColor
                      )}`}
                      style={{
                        backgroundColor: `${coachPublicProfileData?.profile_information?.profileColor}`,
                        color: getTextColor(coachColor),
                      }}
                      disabled={
                        endpoint_slug === user?.coachProfile?.endpoint_slug ||
                        (is_group_service &&
                          (spotsLeft === null || spotsLeft <= 0)) ||
                        groupServiceCheck
                      }
                    >
                      {is_group_service &&
                      (spotsLeft === null || spotsLeft <= 0)
                        ? "Group Session Full"
                        : service?.service_details?.type === "consultation"
                        ? "Schedule Consultation"
                        : groupServiceCheck
                        ? "Already Enrolled"

                        : "Enroll in Service"}
                    </Button>
                  </div>
                </div>
                <div className="main-shadow mt-4 w-full rounded-[16px] bg-white px-[18px] pb-[50px] pt-[20px]">
                  {/* SERVICE BANNER IMAGE */}
                  <div
                    className={`aspect-[3/1] w-full  rounded-[16px] ${
                      service?.service_details?.image?.exists
                        ? ""
                        : service?.service_details?.image?.color
                    }`}
                  >
                    {service?.service_details?.image?.exists && (
                      <img
                        src={`https://clientservice-images-${
                          process.env.REACT_APP_USER_ENVIRONMENT
                        }.s3.amazonaws.com/${
                          service.stripe_product_id
                        }?t=${new Date().getTime()}`}
                        alt="service"
                        className="h-full w-full rounded-[16px] object-cover"
                      />
                    )}
                  </div>
                  {/* SERVICE DETAILS */}
                  {/* TITLE AND PRICE */}
                  <div className="-mt-[15px] px-[20px]">
                    <div className="mb-[20px] mt-5 flex w-full flex-col justify-between md:mb-[24px]">
                      <div className="flex gap-[25px]">
                        <h3 className="self-center text-[22px] font-bold">
                          {service?.service_details?.title}
                        </h3>
                      </div>
                      <h3 className="text-[22px] font-bold text-black">
                        {getPrice(
                          parseInt(service?.service_details?.billing?.amount),
                          service?.service_details?.symbol
                        )}
                        <span className="text-graySlate text-base font-normal">
                          {getUnits(
                            service?.service_details?.billing?.unit,
                            parseInt(service?.service_details?.billing?.amount)
                          )}
                        </span>
                      </h3>
                    </div>
                    <div className="bg-grayMist mb-[16px] h-[1px] w-full"></div>
                    <div className="relative mb-[32px] mr-auto flex w-full flex-col gap-3 text-base sm:flex-row">
                      <div className="flex justify-between gap-3">
                        <div>{getServiceType()}</div>
                      </div>

                      <div className="flex justify-between gap-[5px] sm:ml-auto">
                        <div
                          className="ml-auto flex items-center
                        justify-center rounded-[8px] px-[8px] py-[4px] text-base font-bold text-black"
                          style={{ background: textColor }}
                        >
                          {getSessionCount()}
                        </div>

                        <div
                          className="ml-auto flex items-center
                       justify-center rounded-[8px] px-[8px] py-[4px] text-base font-bold text-black"
                          style={{ background: textColor }}
                        >
                          {parseInt(
                            service?.service_details?.session?.duration
                          ) / 60}{" "}
                          minutes
                        </div>
                        <div
                          className="ml-auto flex items-center
                       justify-center rounded-[8px] px-[8px] py-[4px] text-base font-bold text-black"
                          style={{ background: textColor }}
                        >
                          {getLengthOrExpiration()}
                        </div>
                      </div>
                    </div>
                    <div className="bg-grayFlash mb-[18px] flex h-[32px] w-[142px] items-center justify-center rounded-[8px] font-bold">
                      Program Details
                    </div>
                    {service?.service_details?.details?.features?.feature1 && (
                      <div className="mb-[24px] mr-auto flex flex-col gap-[16px] text-base md:mb-[32px]">
                        <div className="flex items-center gap-[12px]">
                          <SvgCheckCircle
                            fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                          />
                          {service?.service_details?.details.features.feature1}
                        </div>
                        {service?.service_details?.details?.features
                          ?.feature2 && (
                          <div className="flex items-center gap-[12px]">
                            <SvgCheckCircle
                              fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                            />
                            {
                              service?.service_details?.details.features
                                .feature2
                            }
                          </div>
                        )}
                        {service?.service_details?.details?.features
                          ?.feature3 && (
                          <div className="flex items-center gap-[12px]">
                            <SvgCheckCircle
                              fill={`${coachPublicProfileData?.profile_information?.profileColor}`}
                            />
                            {
                              service?.service_details?.details.features
                                .feature3
                            }
                          </div>
                        )}
                      </div>
                    )}
                    {service?.service_details?.details?.description1 && (
                      <div className="mb-[40px]">
                        {service?.service_details?.details?.description1}
                      </div>
                    )}
                  </div>
                  <div className="bg-grayMist mb-[16px] h-[1px] w-full"></div>
                  <div className="flex justify-center w-full mb-4">
                    {is_group_service &&
                      getStartDatesWithVariants().length > 0 && (
                        <div className="mr-auto grid grid-cols-1 sm:grid-cols-2 gap-2 w-full">
                          <div
                            className="bg-grayFlash mb-[10px] flex h-[32px]
                                w-[142px] items-center justify-center rounded-[8px] font-bold gap-2"
                          >
                            Scheduled Dates
                          </div>
                          <br />
                          {getStartDatesWithVariants().map(
                            (start: any, index: any) => (
                              <div
                                key={index}
                                className="flex justify-center mb-2"
                              >
                                <Badge variant="neutral" className="w-auto">
                                  {start.message}
                                </Badge>
                              </div>
                            )
                          )}
                        </div>
                      )}
                  </div>

                  <div className="text-center">
                    <Button
                      onClick={checkEmailOrRedirect}
                      className={`text-md w-4/5 text-${getTextColor(
                        coachColor
                      )}`}
                      size={"lg"}
                      variant={"context"}
                      style={{
                        backgroundColor: coachColor,
                        color: getTextColor(coachColor),
                      }}
                      disabled={
                        endpoint_slug === user?.coachProfile?.endpoint_slug ||
                        (is_group_service &&
                          (spotsLeft === null || spotsLeft <= 0)) ||
                        groupServiceCheck
                      }
                    >
                      {is_group_service &&
                      (spotsLeft === null || spotsLeft <= 0)
                        ? "Group Session Full"
                        : service?.service_details?.type === "consultation"
                        ? "Schedule Consultation"
                        : groupServiceCheck
                        ? "Already Enrolled"
                        : "Enroll in Service"}
                    </Button>
                  </div>
                </div>
              </>
            ) : (
              <div className="fixed inset-0 flex min-h-screen flex-col items-center justify-center gap-4">
                <h1 className="text-xl font-bold">Service not found</h1>
                <Button size="lg" asChild>
                  <Link to={`/find-a-coach/${endpoint_slug}`}>
                    Back to coach
                  </Link>
                </Button>
              </div>
            )}
          </div>
        </>
      )}
    </>
  )
}

export default PublicServiceDetails
