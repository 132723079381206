import React, { useEffect, useState } from "react"
import { Button } from "../ui/button"
import { SvgCheckCircle } from "../icons"
import { planFeatures } from "../PricingPage/PricingPlanCard"
import { useNavigate } from "react-router-dom"

type Props = {
  plan: any
  title: string
  handleChoosePlanClick: (plan: any) => void
}

const SubscriptionPlanCard: React.FC<Props> = ({
  plan,
  title,
  handleChoosePlanClick,
}) => {
  const [cardDetailsColor, setCardDetailsColor] = useState<string>("")
  const [cardColor, setCardColor] = useState<string>("")
  const [paymentPeriod, setPaymentPeriod] = useState<string>("")
  const navigate = useNavigate()

  const assignCardColor = () => {
    if (plan?.recurring_interval === "annual" && !plan?.is_promotional) {
      setCardDetailsColor("darkPurple")
    }
    if (plan?.recurring_interval === "month" && !plan?.is_promotional) {
      setCardDetailsColor("blurple")
    }
    if (plan?.amount === 500 && plan?.is_promotional) {
      setCardDetailsColor("mainBlack")
      setCardColor("subscription-cta-gradient")
    }
  }

  const setPeriod = () => {
    if (plan?.recurring_interval === "annual" && !plan?.is_promotional) {
      setPaymentPeriod("/annual")
    }
    if (plan?.recurring_interval === "month" && !plan?.is_promotional) {
      setPaymentPeriod("/mo")
    }
    if (plan?.amount === 500 && plan?.recurring_interval_count === 2) {
      setPaymentPeriod("one-time cost")
    }
  }

  useEffect(() => {
    assignCardColor()
    setPeriod()
  }, [plan])

  const handleNavigateToCheckout = () => {
    navigate("/coach/subscription/checkout", {
      state: { selectedPlan: plan, selectedTitle: title, selectedAmount: plan.amount}, 
    })
  }

  return (
    <div
      className={`${cardColor} md:w-[406px] h-full flex flex-col rounded-[32px] main-shadow bg-white relative`}
    >
      <div
        className={`absolute top-0 left-0 w-full h-[30px] bg-black rounded-tl-[32px] rounded-tr-[32px] ${
          plan?.recurring_interval === "annual"
            ? "bg-blurple"
            : plan?.recurring_interval === "month"
            ? "bg-black"
            : "bg-gray-400"
        } flex items-center justify-center`}
        style={{ borderTopLeftRadius: "32px", borderTopRightRadius: "32px" }}
      ></div>

      <div className=" px-8 py-10 bg-white rounded-[32px] flex-col justify-start items-start gap-8 inline-flex">
        <div className=" self-stretch h-[28px] flex-col justify-start items-start gap-1 flex">
          <div className="text-black text-2xl font-semibold font-['Beausite Classic']">
            {title}
          </div>
          <div className="self-stretch text-[#7d878f] text-base font-normal font-['Beausite Classic'] leading-snug">
            {plan?.stripe_product_description}
          </div>
        </div>
        <div className="self-stretch h-[78px] flex-col justify-start items-start flex -mt-1.5">
          <div className="self-stretch h-[76px] flex-col justify-start items-start gap-0.5 flex">
            <div className="text-center text-black text-[42px] font-semibold font-['Beausite Classic']">
              {plan ? (
                <>
                  $
                  {plan.recurring_interval === "annual"
                    ? Math.round(plan.amount / 12)
                    : plan?.amount}{" "}
                  /mo
                </>
              ) : (
                <span>Loading...</span>
              )}
            </div>
            <div className="self-stretch text-[#8e8e93] text-xl font-normal font-['Beausite Classic']">
              <p className="text-[18px] text-graySlate">
                {plan?.recurring_interval === "annual"
                  ? `Billed Annually at $${plan?.amount}`
                  : plan?.annual?.recurring_interval === "month"
                  ? `Billed Monthly at $${plan?.amount}`
                  : null}
              </p>
            </div>
          </div>
        </div>

        <div className="flex flex-col gap-[12px] justify-center w-full">
          <div className="flex flex-col gap-[12px]">
            <Button
              variant="default"
              size={"lg"}
              className="mt-[4px] md:mt-[20px] mx-[8px] md:mx-0 text-md"
              onClick={handleNavigateToCheckout}
            >
              Get Started
            </Button>
          </div>

          <div className="flex flex-col gap-[16px]">
            {planFeatures.map((feature, idx) => (
              <div className="flex items-center gap-4" key={`${idx}${feature}`}>
                <div className="flex-shrink-0">
                  <SvgCheckCircle stroke="#4750f5" />
                </div>
                <p>{feature}</p>
              </div>
            ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default SubscriptionPlanCard
