import moment from "moment"
import React, {
  ChangeEvent,
  FC,
  FormEvent,
  useContext,
  useEffect,
  useState,
} from "react"
import BackToButton from "../BackToButton"
import ConsultationDetailsForm from "./manage/ConsultationDetailsForm"
import currencyCodesData from "./manage/currencyCodesData.json"
import GroupServiceDetailsForm from "./manage/GroupServiceDetailsForm"
import ManageServiceOptions from "./manage/ManageServiceOptions"
import PackageForm from "./manage/PackageForm"
import RecurringForm from "./manage/RecurringForm"
import ServiceColorImageForm from "./manage/ServiceColorImageForm"
import ServiceDescriptionForm from "./manage/ServiceDescriptionForm"
import ServiceLinkedForms from "./manage/ServiceLinkedForms"
import ServicePreview from "./ServicePreview"
import ServiceTypeSelector from "./manage/ServiceTypeSelector"
import SingleServiceDetailsForm from "./manage/SingleServiceDetailsForm"
import useLoading from "../../hooks/useLoading"
import useManageServices, { ServiceType } from "./hooks/useManageServices"
import useScrollToTop from "../../hooks/useScrollToTop"
import { Button } from "../ui/button"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { ClientPaymentRequests } from "../../api/app.service"
import { cn } from "../../utils/cn"
import { MeetingRequests } from "../../api/app.service"
import { ServiceRequests } from "../../api/app.service"
import { SubscriptionCtx } from "../../context/subscriptionContext"
import { SvgChevronRight } from "../icons"
import { useCurrentWidth } from "../../hooks/useWidth"
import { UserCtx } from "../../context/userContext"

interface Props {
  setCreateService: any
  setSyncPrompt: any
  connectAccountData: any
  getServices: any
  showComponent: any
  setShowActiveServices: any
  setShowServiceDrafts: any
  isGroupService: boolean
  services: any
  availabilities: any
  setLoadingServices: any
  updateService: any
  setShowNewMeeting: any
  setShowCalendar: any
  setCreateFormModal: (statement: boolean) => void
  createFormModal: boolean
}
interface CurrencyCodes {
  code: string
  symbol: string
}

const CreateService: FC<Props> = ({
  setCreateService,
  setSyncPrompt,
  connectAccountData,
  getServices,
  showComponent,
  setShowActiveServices,
  setShowServiceDrafts,
  isGroupService,
  services,
  availabilities,
  setLoadingServices,
  updateService,
  setShowNewMeeting,
  setShowCalendar,
  setCreateFormModal,
  createFormModal,
}) => {
  const { user } = useContext(UserCtx)
  const service = updateService?.service
  const width = useCurrentWidth()
  const disableConsultation =
    services.filter(
      (service: any) =>
        service.service_details.status === "active" &&
        service.service_details.type === "consultation"
    ).length >= 1
  const [serviceType, setServiceType] = useState<ServiceType>(
    isGroupService || disableConsultation ? "individual" : "consultation"
  )
  const [serviceImgFile, setServiceImgFile] = useState<File>()
  const [previewImgUrl, setPreviewImgUrl] = useState<string>("")
  const [awaitNewImage, setAwaitNewImage] = useState<boolean>(false)
  const [showPreview, setShowPreview] = useState<boolean>(false)
  const [formErrors, setFormErrors] = useState<any>({})
  const [canEnable, setCanEnable] = useState<boolean>(false)
  const [isSubmit, setIsSubmit] = useState<boolean>(false)
  const [paypalStatus, setPaypalStatus] = useState<any>(null)

  const [showManageServiceOptions, setShowManageServiceOptions] =
    useState<boolean>(false)
  const {
    setPopupNotification,
    renderError,
    renderSuccess,
    updateNextStepsData,
  } = useContext(CommonFunctionCtx)
  const { showSubscribeBanner, setShowSubscribeBanner } =
    useContext(SubscriptionCtx)
  const { startLoading, stopLoading } = useLoading()
  const {
    validateConsultationOrIndividual,
    validatePackage,
    validateRecurring,
    setServiceImageDetails,
    getServiceObject,
  } = useManageServices()

  const [serviceValues, setServiceValues] = useState<any>({
    availability_rule_id: availabilities.length
      ? availabilities?.find((rule: any) => rule?.is_default).id
      : "",
    isGroupService: isGroupService,
    color: "bg-blurple",
    title: "",
    description: "",
    feature1: "",
    feature2: "",
    feature3: "",
    sessionLength: { minutes: 30 },
    sessionCost: 0.0,
    currency: "USD",
    symbol: "\\u0024",
    isFree: true,
    allowPromo: true,
    package: {
      packageSessions: 2,
      packageBillingType: "bulk",
      packageCost: 100,
      installmentIncrements: [{ time: 1, type: "months" }],
      upFrontPercentage: 50,
    },
    recurring: {
      isFree: true,
      periodCost: 100,
      recurringFrequency: "monthly",
      sessionsPerPeriod: 1,
      unlimited: false,
    },
    expiration: {
      expireTime: 1,
      expireType: "months",
      expires: serviceType === "recurring" ? true : false,
    },
    enabled: true,
    profileServiceVisible: true,
    profileCostVisible: false,
    marketplaceServiceVisible: false,
    marketplaceCostVisible: false,
    image: { exists: false, base64: "", base64extension: "" },
    group: {
      isFree: true,
      meetingDates: [moment().hour(16).minute(0).add(14, "days").toDate()],
      weeklyDayTimes: [
        {
          day: { value: "monday", label: "Monday" },
          time: { value: "07:00", label: "7:00 AM" },
        },
      ],
      // enrollByDate: moment().hour(16).minute(0).add(7, "days").toDate(),
      maximumClients: 2,
      groupChat: true,
    },
    serviceLinkedForms: [],
  })
  const getPaypalOnboardingStatus = async () => {
    if (user) {
      startLoading()
      try {
        const onboardingStatus =
          await ClientPaymentRequests.getPaypalOnboardingStatus({
            seller_payer_id: "",
            seller_user_id: parseInt(user?.user_id),
          })

        setPaypalStatus(onboardingStatus)
      } catch (error) {
        renderError(
          "An error occurred trying to fetch your PayPal onboarding status"
        )
      } finally {
        stopLoading()
      }
    }
  }

  useEffect(() => {
    getPaypalOnboardingStatus()
  }, [])

  const [currencyCodes, setCurrencyCodes] = useState<CurrencyCodes[]>([])

  useEffect(() => {
    const data: CurrencyCodes[] = currencyCodesData.codes
    setCurrencyCodes(data)
  }, [])

  const handleCurrencySelect = (code: CurrencyCodes) => {
    setServiceValues({
      ...serviceValues,
      currency: code.code,
      symbol: code.symbol,
    })
  }

  const formatDate = (date: Date): string => {
    const year = date.getFullYear()
    const month = String(date.getMonth() + 1).padStart(2, "0") // Months are zero-indexed
    const day = String(date.getDate()).padStart(2, "0")
    const hours = String(date.getHours()).padStart(2, "0")
    const minutes = String(date.getMinutes()).padStart(2, "0")
    const seconds = "00"

    let date_obj = `${year}-${month}-${day} ${hours}:${minutes}:${seconds}`
    const dateObj = new Date(date_obj)
    return dateObj.toISOString().slice(0, -5) + "Z"
  }

  const format_meetingDates = (meetingDates: any[], minutes: number) => {
    let meeting_details: any[] = []
    meetingDates.forEach((meetingDate: string) => {
      const dateObj = new Date(meetingDate) // Convert the string to a Date object
      const end_date_obj = new Date(dateObj.getTime() + minutes * 60000) // Add minutes (in milliseconds)

      const start_time = formatDate(dateObj)
      const end_time = formatDate(end_date_obj)

      meeting_details.push({ start_time, end_time })
    })
    return meeting_details
  }

  const createService = async (): Promise<any> => {
    startLoading()

    const serviceObject = getServiceObject(serviceType, serviceValues)

    try {
      const response = await ServiceRequests.createService({
        user_id: parseInt(user.user_id),
        coach_profile_id: parseInt(user.coachProfile.profile_id),
        service: serviceObject,
      })

      renderSuccess(
        `Your service ${serviceValues.enabled ? "" : "draft"} was ${
          serviceValues.enabled ? "published" : "created"
        }!`
      )
      setCreateService(false)
      window.scrollTo(0, 0)
      setLoadingServices(true)
      await updateNextStepsData()
      await getServices()

      if (serviceValues.enabled) {
        showComponent(setShowActiveServices)
      } else {
        showComponent(setShowServiceDrafts)
      }

      if (isGroupService) {
        const groupServiceId = response?.groupServiceID

        if (groupServiceId !== undefined) {
          await createGroupMeeting(groupServiceId)
        } else {
          console.error("Group service ID is undefined.")
          renderError(
            "Failed to schedule group meeting: Missing group service ID."
          )
        }

        setShowNewMeeting(true)
        setShowCalendar(true)
        renderSuccess(`Group Session has been scheduled!`)
      }

      return response
    } catch (ex: any) {
      console.log(ex)
      renderError(ex?.response?.data?.message || "An error occurred")
      throw ex
    } finally {
      stopLoading()
    }
  }

  const createGroupMeeting = async (groupServiceId: number): Promise<void> => {
    const serviceObject = getServiceObject(serviceType, serviceValues)
    try {
      const response = await MeetingRequests.createGroupMeeting({
        coach_user_id: Number(user.user_id),
        coach_profile_id: Number(user.activeProfileId),
        group_service_id: groupServiceId,
        name: serviceValues.title,
        meeting_type: "Group",
        medium: "video",
        status: "busy",
        reminders: [],
        meeting_details: format_meetingDates(
          serviceValues.group.meetingDates,
          serviceValues.sessionLength.minutes
        ),
        participants: {
          user_id: Number(user.user_id),
          profile_id: Number(user.activeProfileId),
          email: user.email,
          contact_name: user.firstName,
          first_name: user.firstName,
          last_name: user.lastName,
        },
      })

      stopLoading()
      setShowNewMeeting(true)
      setShowCalendar(true)
      renderSuccess(`Group Session has been scheduled!`)
    } catch (ex: any) {
      console.log(ex)
      renderError(ex.response?.data?.message || "An error occurred")
      stopLoading()
    }
  }

  const handleSessionCostChange = (e: ChangeEvent<HTMLInputElement>) => {
    let value = parseFloat(e.target.value)
    setServiceValues({ ...serviceValues, [e.target.name]: value })
  }

  const handleAvailabilityRulesChange = (rule: any) => {
    let availabilityRuleId = rule.id
    setServiceValues({
      ...serviceValues,
      availability_rule_id: availabilityRuleId,
    })
  }

  const handleExpiresToggle = () => {
    setServiceValues({
      ...serviceValues,
      expiration: {
        ...serviceValues.expiration,
        expires: !serviceValues?.expiration?.expires,
      },
    })
  }

  const handleSubmit = (e: FormEvent<EventTarget>) => {
    e.preventDefault()
    if (serviceType === "consultation" || serviceType === "individual") {
      setFormErrors(validateConsultationOrIndividual(serviceValues))
    } else if (serviceType === "bundle") {
      setFormErrors(validatePackage(serviceValues))
    } else if (serviceType === "recurring") {
      setFormErrors(validateRecurring(serviceValues))
    }
    setIsSubmit(true)
  }

  const updateShowManageServiceOptions = () => {
    if (!showManageServiceOptions) {
      setShowManageServiceOptions(window.innerWidth > 768)
    }
  }

  const addResizeListener = () => {
    window.addEventListener("resize", updateShowManageServiceOptions)
    return () =>
      window.removeEventListener("resize", updateShowManageServiceOptions)
  }

  const handleManageServiceCardClick = () => {
    if (window.innerWidth < 768) {
      setShowManageServiceOptions(() => !showManageServiceOptions)
    }
  }

  useEffect(() => {
    setServiceImageDetails(
      serviceImgFile,
      setServiceValues,
      serviceValues,
      setPopupNotification,
      setServiceImgFile,
      setPreviewImgUrl
    )
    if (serviceImgFile) {
      setAwaitNewImage(false)
    }
  }, [serviceImgFile])

  useEffect(() => {
    if (isSubmit) {
      if (Object.keys(formErrors).length === 0) {
        setShowPreview(true)
      } else {
        setIsSubmit(false)
        stopLoading()
        renderError("Complete all required fields to see the preview")
      }
    }
  }, [formErrors])

  useEffect(() => {
    if (window.innerWidth > 789) {
      setShowManageServiceOptions(true)
    } else {
      setShowManageServiceOptions(false)
    }

    addResizeListener()
    let deviceWidth = window.matchMedia("(max-width: 768px)")
    if (deviceWidth.matches) {
      updateShowManageServiceOptions()
    }
  }, [])

  useScrollToTop()

  useEffect(() => {
    if (width < 768) {
      setShowSubscribeBanner(false)
    }
  }, [width])

  const shouldShowSubscribeBanner = showSubscribeBanner && !user.isSubscribed

  return (
    <>
      {showPreview && (
        <ServicePreview
          setShowPreview={setShowPreview}
          serviceValues={serviceValues}
          setServiceValues={setServiceValues}
          saveType="create"
          callback={createService}
          previewImgUrl={previewImgUrl}
          serviceType={serviceType}
          isGroupService={isGroupService}
          groupCallback={createService}
          matchingMeetingIds=""
        />
      )}
      <div
        className={cn(
          "fixed top-0 w-screen h-[88px] create-service-shadow bg-white flex items-center justify-center z-[401] overflow-x-hidden",
          {
            "md:top-[160px]": shouldShowSubscribeBanner,
            "md:top-[80px]": !shouldShowSubscribeBanner,
          }
        )}
      >
        <div className="flex items-center md:w-[1080px] w-full justify-between px-[20px]">
          <BackToButton
            current="Services"
            prev="Client Services"
            onClick={() => setCreateService(false)}
          />
          <div className="flex items-center gap-[32px]">
            <Button className="hidden md:block text-md" onClick={handleSubmit}>
              {serviceValues.enabled ? "Create Service" : "Save Draft"}
            </Button>
          </div>
        </div>
      </div>

      <div className="md:hidden main-shadow fixed z-[1007] pt-[18px] px-[20px] bottom-0 left-0 w-full h-[114px] bg-white flex justify-center">
        <Button
          className="w-full max-w-[380px] mx-auto text-md"
          onClick={handleSubmit}
        >
          {serviceValues.enabled ? "Create Service" : "Save Draft"}
        </Button>
      </div>

      <div className="px-[20px] md:w-[90%] flex flex-col justify-between items-center mx-auto relative pt-[60px]">
        <div className="flex flex-col gap-[32px] mx-auto">
          <div className="md:w-[620px] main-shadow flex flex-col rounded-[16px] bg-white p-[32px] md:p-[40px]">
            <ServiceTypeSelector
              setServiceType={setServiceType}
              serviceType={serviceType}
              isGroupService={isGroupService}
              disableConsultation={disableConsultation}
            />
            {serviceType === "consultation" && (
              <ConsultationDetailsForm
                formErrors={formErrors}
                serviceValues={serviceValues}
                setServiceValues={setServiceValues}
                handleSessionCostChange={handleSessionCostChange}
                handleExpiresToggle={handleExpiresToggle}
                serviceType={serviceType}
                isGroupService={isGroupService}
                availabilities={availabilities}
                handleAvailabilityRulesChange={handleAvailabilityRulesChange}
                services={services}
                service={service}
              />
            )}

            {serviceType === "individual" && (
              <SingleServiceDetailsForm
                formErrors={formErrors}
                serviceValues={serviceValues}
                setServiceValues={setServiceValues}
                handleSessionCostChange={handleSessionCostChange}
                handleExpiresToggle={handleExpiresToggle}
                serviceType={serviceType}
                isGroupService={isGroupService}
                availabilities={availabilities}
                handleAvailabilityRulesChange={handleAvailabilityRulesChange}
                service={service}
              />
            )}

            {/* package service type */}
            {serviceType === "bundle" && (
              <PackageForm
                formErrors={formErrors}
                serviceValues={serviceValues}
                setServiceValues={setServiceValues}
                handleExpiresToggle={handleExpiresToggle}
                serviceType={serviceType}
                isGroupService={isGroupService}
                availabilities={availabilities}
                handleAvailabilityRulesChange={handleAvailabilityRulesChange}
                service={service}
                paypalStatus={paypalStatus}
              />
            )}

            {serviceType === "recurring" && (
              <RecurringForm
                formErrors={formErrors}
                serviceValues={serviceValues}
                setServiceValues={setServiceValues}
                serviceType={serviceType}
                isGroupService={isGroupService}
                availabilities={availabilities}
                handleAvailabilityRulesChange={handleAvailabilityRulesChange}
                service={service}
                paypalStatus={paypalStatus}
                connectAccountData={connectAccountData}
              />
            )}
          </div>

          {isGroupService && (
            <GroupServiceDetailsForm
              formErrors={formErrors}
              serviceValues={serviceValues}
              setServiceValues={setServiceValues}
              serviceType={serviceType}
              isGroupService={isGroupService}
              service={service}
              handleMatchingMeetingIds={() => {}}
            />
          )}
          <ServiceDescriptionForm
            formErrors={formErrors}
            setServiceValues={setServiceValues}
            serviceValues={serviceValues}
            isGroupService={isGroupService}
          />

          <ServiceLinkedForms
            setServiceValues={setServiceValues}
            serviceValues={serviceValues}
            setCreateFormModal={setCreateFormModal}
            createFormModal={createFormModal}
          />

          <ServiceColorImageForm
            serviceValues={serviceValues}
            setServiceValues={setServiceValues}
            previewImgUrl={previewImgUrl}
            setPreviewImgUrl={setPreviewImgUrl}
            awaitNewImage={awaitNewImage}
            setAwaitNewImage={setAwaitNewImage}
            serviceImgFile={serviceImgFile}
            setServiceImgFile={setServiceImgFile}
          />
        </div>

        <div className="md:w-[620px] w-full py-[40px] px-[32px] bg-white main-shadow rounded-[16px] mx-auto mt-[42px] md:my-[32px] mb-[130px] md:mb-0">
          <div className="flex items-center w-full justify-between mb-[24px]">
            <div className="w-full flex items-center md:justify-between">
              <h3 className="font-bold text-[22px] mr-[24px] md:mr-0">
                Manage Service
              </h3>
            </div>
            <button
              onClick={handleManageServiceCardClick}
              className="cursor-pointer md:cursor-none flex items-center text-base font-bold"
            >
              <SvgChevronRight className="md:hidden" />
            </button>
          </div>

          {showManageServiceOptions && (
            <ManageServiceOptions
              setServiceValues={setServiceValues}
              serviceValues={serviceValues}
              canEnable={canEnable}
              connectAccountData={connectAccountData}
              setSyncPrompt={setSyncPrompt}
            />
          )}

          <Button className="w-full text-md" onClick={handleSubmit}>
            See Preview of Your Service
          </Button>
        </div>
      </div>
    </>
  )
}

export default CreateService
