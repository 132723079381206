import { useState, FC } from "react"
import { Button } from "../ui/button"
interface JoinProps {
  isQuick: boolean
  guestRoomCode: string
  hostRoomCode: string
  isHost: boolean
  isDisabled: boolean
  joinSession: () => void
}
const JoinButton: FC<JoinProps> = ({
  guestRoomCode,
  hostRoomCode,
  isQuick,
  isDisabled,
  isHost,
  joinSession,
}) => {
  const [dynamicURL, setDynamicURL] = useState<string | null>(null)

  const prefix = process.env.REACT_APP_VIDEO_PREFIX
  const quickPrefix = process.env.REACT_APP_VIDEO_PREFIX_QUICK

  const hostUrl = `https://${prefix}.app.100ms.live/meeting/${hostRoomCode}`
  const quickHostUrl = `https://${quickPrefix}.app.100ms.live/meeting/${hostRoomCode}`

  const guestUrl = `https://${prefix}.app.100ms.live/meeting/${guestRoomCode}`
  const quickGuestUrl = `https://${quickPrefix}.app.100ms.live/meeting/${guestRoomCode}`

  const handleClick = async () => {
    let url: string
    try {
      if (isQuick) {
        if (isHost) {
          url = quickHostUrl
        } else {
          url = quickGuestUrl
        }
      } else {
        if (isHost) {
          url = hostUrl
        } else {
          url = guestUrl
        }
      }
      setDynamicURL(url)
      window.open(url, "_blank", "noopener noreferrer")
      joinSession()
    } catch (error) {
      console.error("Error fetching host or guest room code", error)
    }
  }
  
  return (
    <Button onClick={handleClick} disabled={isDisabled}>
      {dynamicURL && isDisabled ? "Waiting for Coach" : "Join Room"}
    </Button>
  )
}
export default JoinButton