import { useState, useContext, FC, useEffect } from "react"
import CloseModal from "../CloseModal"
import moment from "moment"
import TimeTicker from "./TimeTicker"
import useLoading from "../../hooks/useLoading"
import { Button } from "../ui/button"
import { Badge } from "../ui/badge"
import { CommonFunctionCtx } from "../../context/commonFunctionContext"
import { getUserColor } from "../../utils/getUserColor"
import { SessionContext } from "../../pages/sessions/context/sessionContext"
import { SessionsRequests, MeetingRequests } from "../../api/app.service"
import { SvgVideoSolid } from "../icons"
import { UserCtx } from "../../context/userContext"
import JoinButton from "./JoinButton"

interface Props {
  sessionList: any[]
  getSessionList: any
  hostJoined: boolean
  isTodayPage?: boolean
  hostUserId: string
  isQuick: boolean
}

const ActiveSessionPreview: FC<Props> = ({
  sessionList,
  getSessionList,
  hostJoined,
  isTodayPage,
  hostUserId,
  isQuick,
}) => {
  const { user } = useContext(UserCtx)
  const { renderError } = useContext(CommonFunctionCtx)
  const { startLoading, stopLoading } = useLoading()
  const { setRoomId, setMsUserToken } = useContext(SessionContext)
  const [coachID, setCoachID] = useState<string>("")
  const [isHost, setIsHost] = useState<boolean>(false)

  const index = sessionList.length - 1
  // useState for video call instructions for user
  const [instructionModal, setInstructionModal] = useState(false)
  const isCoach = user.activeProfile === "coach"
  const session = sessionList[index]
  const hostRoomCode = session.host_room_code
  const guestRoomCode = session.guest_room_code

  const endQuickSession = () => {
    if (user) {
      startLoading()
      SessionsRequests.endSession({
        room_id: sessionList[index]?.room_id,
        meeting_id: sessionList[index]?.meeting_id,
        session_type: sessionList[index]?.session_type,
        service_usage_id: sessionList[index]?.service_usage_id,
      })
        .then((data) => {
          stopLoading()
          getSessionList()
        })
        .catch((ex) => {
          console.log(ex)
          renderError(ex.response.data.message)
        })
        .finally(() => {
          stopLoading()
        })
    }
  }

  // Modal with video best practice instructions
  const handleClick = () => {
    setInstructionModal(true)
  }

  // Check if user browser is safari
  const isSafari = () => {
    return (
      navigator.userAgent.indexOf("Safari") !== -1 &&
      navigator.userAgent.indexOf("Chrome") === -1
    )
  }

  const handleClose = () => {
    setInstructionModal(!instructionModal)
  }

  const getHost = async () => {
    if (user) {
      try {
        const data = await MeetingRequests.getMeetings({
          user_id: parseInt(user.user_id),
          profile_id: user.activeProfileId,
          from_date: moment().subtract(30, "days").format("YYYY-MM-DD"),
          to_date: moment().add(201, "days").format("YYYY-MM-DD"),
        })

        const meetingData = data.meetings.find(
          (value) => value.meeting_id === session.meeting_id
        )
        if (meetingData.is_creator === 1) {
          setIsHost(true)
        } else {
          setIsHost(false)
        }
      } catch (error) {
        console.log("No coach id found for host", error)
      }
    }
  }

  const joinSession = async () => {
    try {
      const tokenData = await SessionsRequests.create100msSessionToken({
        profile_type: user.activeProfile,
        profile_id: user.activeProfileId,
        room_id: session.room_id,
      })

      if (!isTodayPage) {
        setMsUserToken(tokenData.user_token)
        setRoomId(tokenData.room_id)
      }
    } catch (ex) {
      if (ex instanceof Error) {
        renderError(ex.message)
      } else {
        renderError("An unknown error occurred")
      }
    }
  }

  useEffect(() => {
    getHost()
  }, [])

  useEffect(() => {
    getHost()
  }, [])

  return (
    <>
      <div
        className={`flex flex-col w-full main-shadow rounded-[16px] bg-white ${
          isTodayPage ? "h-[538px] min-h-[538px]" : ""
        }`}
      >
        <TimeTicker />
        <div className="flex flex-col w-full pt-[24px] pb-[16px] px-[32px]">
          <h3 className="text-[18px] font-bold mb-[8px]">
            {sessionList[index]?.meeting_name}
          </h3>
          <Badge variant="secondary" className="self-start mb-6">
            <SvgVideoSolid className="mr-2" />
            {sessionList[index]?.session_type === 1
              ? "Quick Session"
              : "Scheduled Session"}
          </Badge>
          <p className="mb-[10px] text-graySlate text-base">PARTICIPANTS</p>
          <div className="flex items-center gap-[10px] mb-[62px]">
            {sessionList[index]?.participant_list
              ?.slice(0, 5)
              .map((participant: any) => (
                <div
                  className="relative w-[40px] h-[40px]"
                  key={participant.profile_id}
                >
                  {participant.has_avatar ? (
                    <img
                      src={`https://profile-avatars-${process.env.REACT_APP_USER_ENVIRONMENT}.s3.amazonaws.com/${participant?.user_id}`}
                      alt="avatar"
                      className="w-full h-full object-cover rounded-full"
                    />
                  ) : (
                    <div
                      className={`w-full h-full rounded-full flex items-center justify-center ${getUserColor(
                        participant.user_color
                      )}`}
                    >
                      {participant?.first_name?.charAt(0)?.toUpperCase() ||
                        participant?.email?.charAt(0).toUpperCase()}
                    </div>
                  )}
                  <div className="absolute top-[31px] left-[30px] w-[8px] h-[8px] outline outline-[2px] outline-white rounded-full bg-green"></div>
                </div>
              ))}
            {sessionList[index]?.participant_list?.length > 5 && (
              <div className="w-[39px] h-[32px] bg-grayFlash rounded-[100px] flex items-center justify-center text-charcoalGray font-bold text-base">
                {`+${sessionList[index].participant_list.length - 5}`}
              </div>
            )}
          </div>
          <div className="flex justify-center items-center gap-[10px] mb-[10px]">
            {isSafari() ? (
              <Button onClick={handleClick} size="lg">
                {" "}
                Join Meeting{" "}
              </Button>
            ) : (
              <JoinButton
                isDisabled={!isHost && !hostJoined}
                isQuick={isQuick}
                isHost={isHost}
                guestRoomCode={guestRoomCode}
                hostRoomCode={hostRoomCode}
                joinSession={joinSession}
              />
            )}

            {instructionModal && (
              <div className="fixed top-0 left-0 w-full h-full pb-[120px] zoee-backdrop-filter z-[801] flex items-center justify-center">
                <div className="py-[16px] sm:py-[32px] px-[24px] sm:px-[48px] w-[351px] sm:w-auto bg-white main-shadow rounded-[16px] flex flex-col items-center relative">
                  <CloseModal
                    styling="absolute top-[24px] right-[24px]"
                    callback={handleClose}
                  />
                  <h2 className="text-[17px] md:text-[22px] font-bold mb-[24px]">
                    How to optimize your Zoee video session:
                  </h2>
                  <div className="w-[298px] md:w-[604px] max-h-[450px] pb-[16px]">
                    <div className="w-full p-[16px] rounded-[14px] flex flex-col sm:flex-row mb-[24px] pl-[5px]">
                      <ul className="marker:text-mainBlack p-[8px]">
                        <li>
                          <p className="font-bold">Recommended browser:</p> Use
                          Chrome for best results (
                          <a
                            className="text-blurple"
                            rel="noreferrer"
                            target="_blank"
                            href="https://support.google.com/chrome/answer/95346?hl=en&co=GENIE.Platform%3DDesktop"
                          >
                            Download
                          </a>
                          )
                        </li>
                        <br />
                        <li>
                          <p className="font-bold">
                            Joining from mobile device:
                          </p>{" "}
                          Use headphones for best audio
                        </li>
                        <br />
                        <p className="text-grayCharcoal italic">
                          Having issues with Safari? See our{" "}
                          <a
                            className="text-blurple"
                            href="https://intercom.help/zoee/en/articles/8264369-how-do-i-setup-safari-so-that-audio-and-video-work-in-my-session"
                            target="_blank"
                            rel="noreferrer"
                          >
                            troubleshooting article
                          </a>{" "}
                          or contact our{" "}
                          <a
                            className="text-blurple"
                            href="https://zoee.com/contact"
                            target="_blank"
                            rel="noreferrer"
                          >
                            support team
                          </a>
                        </p>
                      </ul>
                    </div>
                    <div className="flex items-center justify-center">
                      <JoinButton
                        isQuick={isQuick}
                        isHost={isHost}
                        guestRoomCode={guestRoomCode}
                        hostRoomCode={hostRoomCode}
                        isDisabled={!isHost && !hostJoined}
                        joinSession={joinSession}
                      />
                    </div>
                  </div>
                </div>
              </div>
            )}
            {sessionList[index]?.session_type === 1 &&
            user.activeProfile === "coach" ? (
              <>
                {" "}
                <Button
                  onClick={endQuickSession}
                  variant="destructive"
                  size="lg"
                >
                  End Quick Session
                </Button>
              </>
            ) : sessionList[index]?.session_type === 0 &&
              user.activeProfile === "coach" ? (
              <>
                <Button
                  onClick={endQuickSession}
                  variant="destructive"
                  size="lg"
                >
                  End Session
                </Button>
              </>
            ) : null}
          </div>
          <p className="text-graySlate text-base mx-auto">{`Meeting ID: ${sessionList[index]?.meeting_id}`}</p>
        </div>
      </div>
    </>
  )
}

export default ActiveSessionPreview
